import HttpService from '@/common/services/HttpService'
import type { BreakDTOV1, PreBreakTimerSettingsDTOV1 } from '@/agent/types/break'

export const getBreaks = () => {
  return HttpService.getData<BreakDTOV1[]>('/api/v1/breaks')
}

export const stopPrebreak = (pauseId: number) => {
  return HttpService.post(`/rest/pause/prePause`, {
    prePause: false,
    pauseStateId: pauseId,
  })
}

export const startBreak = (pauseId: number) => {
  return HttpService.post(`/rest/pause/start`, {
    pauseStateId: pauseId,
  })
}

export const getPreBreakTimerSettings = () => {
  return HttpService.getData<PreBreakTimerSettingsDTOV1>('/configuration/api/v1/instance/pre-break')
}
