import { type AxiosRequestConfig } from 'axios'
import HttpService from '@/common/services/HttpService'
import { getScreenById } from '@/common/services/ScreenService'
import { type ScreenDTOV1, type FieldScreenRelation } from '@/common/types/screen'
import { getParameterByCode } from '@/common/services/InstanceParameterService'
import { UnprotectedParameterEnum, type InstanceParameterDTOV1 } from '@/common/types/instanceParameter'
import { listAllFields, getName, getShortName } from '@/common/services/FieldService'
import { getSearchConfig } from '@/admin/services/SearchService'
import { EntityEnum, NULLUUID } from '@/common/types/general'
import { type FieldDTOV1, FieldTypeEnum, EntityFieldCodeEnum, FieldContextEnum } from '@/common/types/fields'
import { type ColumnDefinition, type ResultViewConfig } from '@/search/types/resultViewConfig'
import {
  OperationEnum,
  type SearchFieldWrapper,
  type Criteria,
  type SearchRequest,
  FieldsPlacementEnum,
  fieldsPlacementObject,
  EntityFieldsDefaultOperationMap,
  FieldTypesDefaultOperationMap,
  SpecialFieldCriteria,
  FieldsUsedByGroupedCriteriaMap,
  GroupedCriteriaParentEnum,
  GroupedCriteriaParentEntityEnumMap,
  SearchStoreIdEnum,
  type ExportEndpointEnum,
  type SearchFilterDTOV1,
  type EntitySearchable,
  rowsPerPageByDefault,
} from '@/search/types/search'
import { getComponent } from '@/search/components/fields/search/componentMap'
import { AuthoritiesEnum } from '@/common/types/session'
import { useSessionStore } from '@/common/stores/sessionStore'
import { notify } from '@/common/services/NotificationDisplayService'
import i18n from '@/plugins/i18n'
import { fetchEntities } from '@/search/services/SearchFetchService'

const { t } = i18n.global

/**
 * Get search fields from configuration did in administration, we get only codes
 * Get all fields from context search with details
 * Filter fields from configuration to get fields to display with all details we need
 * @returns fields to display
 */
const getFields = async (): Promise<FieldDTOV1[]> => {
  const sessionStore = useSessionStore()
  if (!sessionStore.searchConfigs.length) return []

  const userSearchConfigs = sessionStore.searchConfigs[0]

  try {
    const [searchConfig, allFields] = await Promise.all([getSearchConfig(userSearchConfigs), listAllFields(FieldContextEnum.SEARCH)])

    const fields = new Set(searchConfig.fields.map((obj) => obj.code))
    return allFields.filter((field: FieldDTOV1) => fields.has(field.code))
  } catch (error) {
    console.log(error)
  }

  return []
}

/**
 * For each field retrieve from server, we create a SearchFieldWrapper
 * and store that in maps "criteria" or "groupedCriteria" with the code as key
 * @param fieldCodes optional param to provide list of field codes to prepare. If undefined, criteria are created according to user search con
 * @returns map of SearchFieldWrapper with the code as key
 */
export const createCriteria = async (fieldCodes?: string[]) => {
  const criteria: Criteria = {}
  const groupedCriteria: Criteria = {}
  const fields: FieldDTOV1[] = await (fieldCodes ? listAllFields(FieldContextEnum.SEARCH) : getFields())
  fields.forEach((field: FieldDTOV1) => {
    if (!FieldsUsedByGroupedCriteriaMap.has(field.code) && (!fieldCodes || fieldCodes.includes(field.code))) {
      // Normal criteria
      createNormalCriteria(field, criteria)
    } else if (!fieldCodes || fieldCodes.includes(field.code)) {
      // Grouped criteria
      createGroupedCriteria(field, criteria, groupedCriteria)
    }
  })
  return [criteria, groupedCriteria]
}

const createNormalCriteria = (field: FieldDTOV1, criteria: Criteria) => {
  const component = getComponent(field.code, field.type)
  if (component) {
    const operation = getDefaultOperation(field.code, field.type)
    const fieldWrapper: SearchFieldWrapper = {
      field: { ...field, name: getName(field) },
      component: component,
      defaultValue: [],
      currentValue: [],
      defaultOperation: operation,
      operation: operation,
    }
    criteria[field.code] = fieldWrapper
  }
}

const createGroupedCriteria = (field: FieldDTOV1, criteria: Criteria, groupedCriteria: Criteria) => {
  const groupedCriteriaParent = FieldsUsedByGroupedCriteriaMap.get(field.code)
  if (!criteria[groupedCriteriaParent as string]) {
    // insert grouped criteria parent
    const component = getComponent(groupedCriteriaParent as string, null)
    if (component) {
      const fieldWrapper: SearchFieldWrapper = {
        field: {
          code: groupedCriteriaParent as string,
          name: '',
          entity: GroupedCriteriaParentEntityEnumMap.get(groupedCriteriaParent as GroupedCriteriaParentEnum) as EntityEnum,
          entityProperty: null,
          modelProperty: null,
          type: FieldTypeEnum.TEXT, // Fake type
          custom: false,
          context: [FieldContextEnum.SEARCH],
        },
        component: component,
        defaultValue: [],
        currentValue: [],
        defaultOperation: OperationEnum.custom,
        operation: OperationEnum.custom,
      }
      criteria[groupedCriteriaParent as string] = fieldWrapper
    }
  }
  // insert grouped criteria
  const component = getComponent(field.code, field.type)
  if (component) {
    const fieldWrapper: SearchFieldWrapper = {
      field: { ...field, name: getName(field) },
      component: component,
      defaultValue: [],
      currentValue: [],
      defaultOperation: OperationEnum.custom,
      operation: OperationEnum.custom,
    }
    groupedCriteria[field.code] = fieldWrapper
  }
}

/**
 * get TICKET_SEARCH_MAX_RESULTS parameter
 * @returns InstanceParameterDTOV1
 */
export const getMaxResultParameter = async (): Promise<InstanceParameterDTOV1> => {
  return getParameterByCode(UnprotectedParameterEnum.TICKET_SEARCH_MAX_RESULTS)
}

/**
 * Return operation
 * @param code entity field code
 * @param type field type
 * @returns operation
 */
const getDefaultOperation = (code: string, type: FieldTypeEnum): OperationEnum => {
  let operation: OperationEnum | undefined = EntityFieldsDefaultOperationMap.get(code)

  if (operation) return operation

  operation = FieldTypesDefaultOperationMap.get(type)

  if (!operation) {
    return OperationEnum.eq
  }

  return operation
}

export const getFinalSearchValue = (f: SearchFieldWrapper) =>
  f.replaceCustomTagsByValues ? f.replaceCustomTagsByValues(f.currentValue) : f.currentValue

/**
 * Create object to ask server what we want to search with fields filled
 * @param entitySelected Entities to search
 * @param fieldWrapperList Fields list
 * @returns Search request object
 */
const createSearchRequest = (
  entitySelected: EntityEnum,
  fieldWrapperList: SearchFieldWrapper[],
  sort: string[],
  maxResult: number,
): SearchRequest => {
  return {
    entity: entitySelected,
    max: maxResult,
    fields: fieldWrapperList
      .filter((fieldWrapper) => fieldWrapper.currentValue.length > 0 && !SpecialFieldCriteria.includes(fieldWrapper.field.code))
      .map((fieldWrapper) => {
        return {
          code: fieldWrapper.field.code,
          op: fieldWrapper.operation,
          values: getFinalSearchValue(fieldWrapper),
        }
      }),
    criteria: fieldWrapperList
      .filter((fieldWrapper) => fieldWrapper.currentValue.length > 0 && SpecialFieldCriteria.includes(fieldWrapper.field.code))
      .map((fieldWrapper) => {
        return {
          code: fieldWrapper.field.code,
          op: fieldWrapper.operation,
          values: isGroupedCriteriaParent(fieldWrapper.field.code) ? [JSON.parse(fieldWrapper.currentValue[0])] : getFinalSearchValue(fieldWrapper),
        }
      }),
    sort: sort,
  }
}

/**
 * Call server to get search result ids. We should get a number array
 * @param searchStoreId
 * @param searchRequest
 * @param spaceCode must be present if we search to attach a parent
 * @returns A number array of entity ids
 */
const launchSearchServer = async (searchStoreId: SearchStoreIdEnum, searchRequest: SearchRequest, spaceCode?: string): Promise<number[]> => {
  let response
  if (searchStoreId === SearchStoreIdEnum.ATTACHPARENT) {
    response = await HttpService.post<number[]>('/search/api/v1/cases/parents/' + spaceCode, searchRequest)
  } else {
    response = await HttpService.post<number[]>('/search/api/v1/specs', searchRequest)
  }
  if (response.status === 206) {
    notify({ message: t('assist.search.warning.more.resources.found'), type: 'warning' })
  }
  return response.data
}

/**
 * Call server to get screen corresponding to resultViewConfig for an entity
 * Call server to get all fields
 * Transform all to a ResultViewConfig
 * @param entitySelected The entity table we want to display
 * @returns ResultViewConfig interface
 */

export const getResultViewConfigToDisplay = async (searchStoreId: SearchStoreIdEnum, entitySelected: EntityEnum): Promise<ResultViewConfig> => {
  if (searchStoreId === SearchStoreIdEnum.ATTACHPARENT) {
    const fields = await listAllFields(FieldContextEnum.TICKET_LIST)
    const resultViewConfig: ResultViewConfig = createResultViewConfigAttachParent(fields)
    return resultViewConfig
  } else {
    const sessionStore = useSessionStore()
    let screenId: number = 0
    let contextToGetFields: FieldContextEnum = FieldContextEnum.TICKET_LIST
    if (entitySelected === EntityEnum.TICKET) {
      screenId = sessionStore.screenIdTicketList
    } else if (entitySelected === EntityEnum.CUSTOMER) {
      screenId = sessionStore.screenIdClientList
      contextToGetFields = FieldContextEnum.CUSTOMER_LIST
    } else if (entitySelected === EntityEnum.INTERACTION) {
      screenId = sessionStore.screenIdInteractionList
      contextToGetFields = FieldContextEnum.INTERACTION_LIST
    }
    const [screen, fields] = await Promise.all([getScreenById(screenId), listAllFields(contextToGetFields)])
    const resultViewConfig: ResultViewConfig = createResultViewConfig(screen, fields)
    return resultViewConfig
  }
}

/**
 * - Create the search request
 * - Call server to get search result ids
 * @param entitySelected Entities to search
 * @param fieldWrapperList Fields list
 * @returns Array of entities ids
 */
export const fetchSearchResultIds = async (
  searchStoreId: SearchStoreIdEnum,
  entitySelected: EntityEnum,
  fieldWrapperList: SearchFieldWrapper[],
  sort: string[],
  maxResult: number,
  spaceCode?: string,
): Promise<number[]> => {
  try {
    const searchRequest: SearchRequest = createSearchRequest(entitySelected, fieldWrapperList, sort, maxResult)
    const resultIds: number[] = await launchSearchServer(searchStoreId, searchRequest, spaceCode)
    return resultIds
  } catch (error) {
    notify({ message: t('assist.search.error'), type: 'negative' })
    console.error(error)
    return []
  }
}

/**
 * Fetch entities one by one to get details for each of them
 * @param entitySelected Entity to fetch
 * @param resultIds Array of entities ids
 * @param resultViewConfig listing configuration
 * @returns Array of entities objects
 */
export const fetchResultDetails = async (
  entitySelected: EntityEnum,
  resultIds: number[],
  columnsDisplayed: ColumnDefinition[],
): Promise<Array<EntitySearchable>> => {
  try {
    if (resultIds.length === 0) return []
    const result: Array<EntitySearchable> = await fetchEntities(entitySelected, resultIds, columnsDisplayed)
    return result
  } catch (error) {
    notify({ message: t('assist.search.error.fetchentities'), type: 'negative' })
    console.error(error)
    return []
  }
}

/**
 * Initialise entities options available to search
 * @param entities Array of entities ids to initiate, if undefined entities are initiated according to user search authorities
 * @returns Options array
 */
export const getEntitiesOptions = (entities?: EntityEnum[]): Array<{ label: string; value: EntityEnum }> => {
  const searchAuthorities = new Map([
    [AuthoritiesEnum.TICKETS_SEARCH, { label: 'assist.entity.cases', entity: EntityEnum.TICKET }],
    [AuthoritiesEnum.CUSTOMERS_SEARCH, { label: 'assist.entity.customers', entity: EntityEnum.CUSTOMER }],
    [AuthoritiesEnum.SEARCH_INTERACTION, { label: 'assist.entity.interactions', entity: EntityEnum.INTERACTION }],
  ])
  const entitiesOptions: Array<{ label: string; value: EntityEnum }> = []

  const sessionStore = useSessionStore()
  for (const [authority, options] of searchAuthorities) {
    if (sessionStore.getAuthoritySet.has(authority) && (!entities || entities.includes(options.entity))) {
      entitiesOptions.push({ label: t(options.label), value: options.entity })
    }
  }
  return entitiesOptions
}

/**
 * Filter array of SearchFieldWrapper depends of entity and placement
 * @param entity Entity to filter
 * @param fieldPlacement Placement of the fields
 * @param fieldWrapperList Fields list
 * @returns Fields list for left or right placement
 */
export const getFieldWrapperListForPlacement = (
  searchStoreId: SearchStoreIdEnum,
  entity: EntityEnum,
  fieldPlacement: FieldsPlacementEnum,
  fieldWrapperList: SearchFieldWrapper[],
): SearchFieldWrapper[] => {
  // Native fields
  const nativeFieldsCodesArray: string[] = fieldsPlacementObject[searchStoreId][entity][fieldPlacement]
  const nativeFieldsArrayFiltered = fieldWrapperList
    .filter((fieldWrapper) => nativeFieldsCodesArray.includes(fieldWrapper.field.code))
    .sort((a, b) => nativeFieldsCodesArray.indexOf(a.field.code) - nativeFieldsCodesArray.indexOf(b.field.code))
  // Custom fields
  const customFieldsArray = fieldWrapperList.filter((fieldWrapper) => fieldWrapper.field.entity === entity && fieldWrapper.field.custom)
  const indexToSplit: number = Math.floor(customFieldsArray.length / 2)
  let customFieldsArrayFiltered: SearchFieldWrapper[] = []
  if (fieldPlacement === FieldsPlacementEnum.LEFT) {
    customFieldsArrayFiltered = customFieldsArray.slice(0, indexToSplit + 1)
  } else if (fieldPlacement === FieldsPlacementEnum.RIGHT) {
    customFieldsArrayFiltered = customFieldsArray.slice(indexToSplit + 1)
  }
  return [...nativeFieldsArrayFiltered, ...customFieldsArrayFiltered]
}

/**
 * Sort fields list to have the same order than the map "fieldsPlacementObject" for native fields.
 * Custom fields are added at the end without sorting.
 * @param entity Entity to filter
 * @param fieldWrapperList Fields list
 * @returns Fields list sorted
 */
export const sortFieldsByEntity = (
  searchStoreId: SearchStoreIdEnum,
  entity: EntityEnum,
  fieldWrapperList: SearchFieldWrapper[],
): SearchFieldWrapper[] => {
  const nativeFieldsCodesArray: string[] = fieldsPlacementObject[searchStoreId][entity][FieldsPlacementEnum.LEFT]
    .concat(fieldsPlacementObject[searchStoreId][entity][FieldsPlacementEnum.RIGHT])
    .concat(fieldsPlacementObject[searchStoreId][entity][FieldsPlacementEnum.GROUP])
  return fieldWrapperList
    .filter((fieldWrapper) => nativeFieldsCodesArray.includes(fieldWrapper.field.code))
    .sort((a, b) => nativeFieldsCodesArray.indexOf(a.field.code) - nativeFieldsCodesArray.indexOf(b.field.code))
    .concat(fieldWrapperList.filter((fieldWrapper) => fieldWrapper.field.entity === entity && fieldWrapper.field.custom))
}

export function paginate(array: number[], page: number, rowsPerPage: number) {
  return array.slice((page - 1) * rowsPerPage, page * rowsPerPage)
}

export function isGroupedCriteriaParent(code: string): boolean {
  return (Object.values(GroupedCriteriaParentEnum) as unknown as string).includes(code)
}

/**
 * Call server to perform an async export resources
 * @param resource resource to create an export
 * @param ids list of resource ids to export
 * @param exportDefinitionCode code of export definition
 * @returns export uuid
 */
export const postExportResources = async (
  resource: ExportEndpointEnum,
  ids: number[],
  exportDefinitionCode: UUID,
  config: AxiosRequestConfig = {},
): Promise<UUID> => {
  return await HttpService.postData<UUID>(`/api/v2/${resource}/export`, { ids, exportDefinitionCode }, config)
}

/**
 * post search filter
 * @param searchConfigId search config id
 * @param data filter data
 */
export const createSearchFilter = async (searchConfigId: UUID, data: SearchFilterDTOV1): Promise<SearchFilterDTOV1> => {
  return HttpService.postData('/search/api/v1/config/' + searchConfigId + '/filters', data)
}

/**
 * delete search filter
 * @param searchFilterId search filter id
 */
export const deleteSearchFilter = async (searchFilterId: UUID): Promise<void> => {
  return await HttpService.deleteData('/search/api/v1/config/filters/' + searchFilterId)
}

/**
 * Get object to save a new filter
 * @param entitySelected Entities to search
 * @param fieldWrapperList Fields list
 * @param fieldWrapperListInGroupedCriteria Fields in grouped criteria list
 * @returns Search filter object
 */
export const getSearchFilterToCreate = (
  name: string,
  entitySelected: EntityEnum,
  shared: boolean,
  fieldWrapperList: SearchFieldWrapper[],
  fieldWrapperListInGroupedCriteria: SearchFieldWrapper[],
): SearchFilterDTOV1 => {
  return {
    id: NULLUUID,
    name,
    entity: entitySelected,
    shared,
    creationUser: '',
    fields: [...fieldWrapperList, ...fieldWrapperListInGroupedCriteria]
      .filter((fieldWrapper) => fieldWrapper.currentValue.length > 0 && !isGroupedCriteriaParent(fieldWrapper.field.code))
      .map((fieldWrapper) => {
        return {
          code: fieldWrapper.field.code,
          op: fieldWrapper.operation,
          values: fieldWrapper.currentValue,
        }
      }),
  }
}

/**
 * Call server to get list of saved search filters
 * @returns array of saved search filters
 */

export const getSavedFilters = async (): Promise<SearchFilterDTOV1[]> => {
  const sessionStore = useSessionStore()
  if (!sessionStore.searchConfigs.length) return []

  try {
    const searchConfigId: string = sessionStore.searchConfigs[0]
    const savedFilters = await HttpService.getData<SearchFilterDTOV1[]>(`/search/api/v1/config/${searchConfigId}/filters`)
    return savedFilters
  } catch (error) {
    console.error(error)
    return []
  }
}

/**
 * Create ResultViewConfig interface used to display a table.
 * It merges the screen fields with the native and custom fields
 * @param screen Screen from server
 * @param fields fields from server
 * @returns ResultViewConfig interface
 */
export const createResultViewConfig = (screen: ScreenDTOV1, fields: FieldDTOV1[]): ResultViewConfig => {
  const resultViewConfig: ResultViewConfig = {
    screenId: screen.id,
    sortBy: null,
    sortSens: null,
    columns: [],
    rowsPerPage: rowsPerPageByDefault,
  }
  const columns: ColumnDefinition[] = []
  screen.fieldScreenRelations.forEach(function (relation: FieldScreenRelation) {
    const field: FieldDTOV1 | undefined = fields.find((f) => {
      if (relation.entityField) return f.code === relation.entityField
      if (relation.customField) return f.code === relation.customField
      return null
    })
    if (field?.modelProperty) {
      columns.push({
        code: field.code,
        property: field.modelProperty,
        sortable: fieldSortable(field),
        alwaysVisible: relation.alwaysVisible,
        label: getShortName(field),
        entity: field.entity,
        type: field.type,
        custom: field.custom,
      })
      if (relation.defaultSortDir !== undefined) {
        resultViewConfig.sortBy = field.code
        resultViewConfig.sortSens = relation.defaultSortDir
      }
    }
  })
  resultViewConfig.columns = columns
  return resultViewConfig
}

export const createResultViewConfigAttachParent = (fields: FieldDTOV1[]): ResultViewConfig => {
  const resultViewConfig: ResultViewConfig = {
    screenId: -1,
    sortBy: EntityFieldCodeEnum.TICKET_ID,
    sortSens: 'desc',
    columns: [],
    rowsPerPage: rowsPerPageByDefault,
  }
  const columnsCodeToHave: string[] = [
    EntityFieldCodeEnum.TICKET_ID,
    EntityFieldCodeEnum.EXTERNAL_REF,
    EntityFieldCodeEnum.CURRENT_SKILL,
    EntityFieldCodeEnum.CREATION_DATE,
    EntityFieldCodeEnum.TICKET_STATUS,
  ]
  const columns: ColumnDefinition[] = []
  fields
    .filter((f) => columnsCodeToHave.includes(f.code))
    .forEach((field: FieldDTOV1) => {
      columns.push({
        code: field.code,
        property: field.modelProperty!,
        sortable: fieldSortable(field),
        alwaysVisible: true,
        label: getShortName(field),
        entity: field.entity,
        type: field.type,
        custom: field.custom,
      })
    })
  columns.sort(function (a, b) {
    return columnsCodeToHave.indexOf(a.code) - columnsCodeToHave.indexOf(b.code)
  })
  resultViewConfig.columns = columns
  return resultViewConfig
}

const fieldSortable = (field: FieldDTOV1): boolean => {
  return field.code !== EntityFieldCodeEnum.HAS_ATTACHMENTS && field.code !== EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS
}
