import HttpService from '@/common/services/HttpService'
import { Profiles, type UserDTOV2, type UserLandingPageDTOV1 } from '@/common/types/users'
import { type UserSessionDTOV1 } from '@/common/types/session'
import { type UserForAssignment } from '@/agent/types/users'
import { getCurrentKeycloakToken } from '@/common/services/AuthenticationService'
import { get as getCachedData } from '@/common/services/LocalStorageService'

const serviceRootV1 = '/api/v1/users'
const serviceRootV2 = '/api/v2/users'

interface UsersForAssignmentParams {
  skillCodes?: string[]
}

export const getUserMe = () => {
  return HttpService.getData<UserSessionDTOV1>(`${serviceRootV1}/me`)
}

export const getBotUsers = () => {
  return HttpService.getData<UserSessionDTOV1[]>(`${serviceRootV1}?userProfile=${Profiles.BOT}`)
}

export const getUsersForAssignment = ({ skillCodes }: UsersForAssignmentParams): Promise<UserForAssignment[]> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {}
  if (skillCodes !== undefined) params.skillCodes = skillCodes.join(',')
  return HttpService.getData<UserForAssignment[]>('/rest/skills/getUsersForAssignmentWithSkillCodes', {
    params,
  })
}

// get user landing page by jwt
export const getUserLandingPage = (): Promise<UserLandingPageDTOV1> => {
  return HttpService.getData<UserLandingPageDTOV1>(`${serviceRootV1}/me/landing-page`, { headers: { 'jwt-access-token': getCurrentKeycloakToken() } })
}

export const fetchUsers = async (cacheKey: string): Promise<UserDTOV2[]> => {
  return getCachedData<UserDTOV2[]>(cacheKey, serviceRootV2, {
    params: {
      includeDeactivated: true,
    },
  })
}
