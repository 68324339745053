import { SseEventType } from '@/common/types/sse'
import { type SseEngageMessageConfig, SseEngageActions } from '@/engage/types/sse'

const messageDisplayConfiguration: SseEngageMessageConfig = {
  [SseEventType.CC_HELP_REQUEST_REMOVE]: {
    [SseEngageActions.HELP_REQUEST_CANCELLED]: {
      color: 'warning50',
    },
  },
}

export default messageDisplayConfiguration
