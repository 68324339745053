import { type AxiosRequestConfig } from 'axios'
import HttpService from '@/common/services/HttpService'
import { get as getCachedData } from '@/common/services/LocalStorageService'
import { type i18nRefDTOV1, type i18nTranslation, type i18nMessageDTOV1, type i18nMessagesDTOV2 } from '@/common/types/i18n'
import { useSessionStore } from '@/common/stores/sessionStore'
import { type PrefixTranslationsEnum } from '@/common/types/i18n'
import i18n from '@/plugins/i18n'

const { t } = i18n.global

interface LocalizedMessagesV2Params {
  key: string
  isExactKey?: boolean
  locale?: boolean
}

// Request translations
export const requestTranslations = async (locale: string, prefixes: Array<string> | string, config: AxiosRequestConfig = {}) => {
  const i18nTranslations: i18nTranslation = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const translationPromises: any = []
  const _prefixes = typeof prefixes == 'string' ? [prefixes] : prefixes
  const assistPrefixIndex = _prefixes.indexOf('assist')

  _prefixes.forEach((prefix) => {
    translationPromises.push(HttpService.getData<i18nRefDTOV1[]>(`/api/v1/ref/i18n/${locale}/${prefix}`, config))
  })

  const translations = await Promise.all(translationPromises)

  translations.forEach((translation: i18nRefDTOV1[], index) =>
    translation.map((message: i18nRefDTOV1) => {
      // check if assist response is being processed AND key starts with assist.$vuetify
      // THEN remove assist prefix
      const translationKey = assistPrefixIndex === index && message.key.startsWith('assist.$vuetify') ? message.key.slice(7) : message.key
      i18nTranslations[translationKey] = message.value
    }),
  )

  return i18nTranslations
}

// Request translations and set them in local storage. Call only once in application
export const setTranslations = async (locale: string, prefix: Array<string> | string, cacheKey?: string) => {
  const translations = await getTranslations(locale, prefix, cacheKey)
  i18n.global.setLocaleMessage(i18n.global.locale.value, translations)
}

// Request translations and set them in local storage
export const addTranslations = async (locale: string, prefix: Array<string> | string, cacheKey?: string) => {
  const translations = await getTranslations(locale, prefix, cacheKey)
  i18n.global.mergeLocaleMessage(i18n.global.locale.value, translations)
}

export const getTranslations = async (locale: string, prefix: Array<string> | string, cacheKey?: string) => {
  if (cacheKey) return getCachedData(cacheKey, () => requestTranslations(locale, prefix))
  return requestTranslations(locale, prefix)
}

export const labelWithPrefix = (prefix: string, text: string): string => {
  if (text.indexOf(prefix) > -1) return t(text)
  return t(prefix + text)
}

/**
 * Get localized messages for a given key
 */
export const getLocalizedMessagesV2 = async (params: LocalizedMessagesV2Params): Promise<i18nMessagesDTOV2[]> => {
  return await HttpService.getData<i18nMessagesDTOV2[]>(`/api/v2/i18n-messages?key=${params.key}`)
}

/**
 * Save localized messages for a given key
 */
export const saveLocalizedMessagesV2 = async (params: i18nMessagesDTOV2[]): Promise<i18nMessagesDTOV2[]> => {
  return await HttpService.putData<i18nMessagesDTOV2[]>(`/api/v2/bulk-i18n-messages`, params)
}

/**
 * Get localized messages for a given key (Obsolete)
 */
export const getLocalizedMessages = async (key: string): Promise<i18nMessageDTOV1[]> => {
  return await HttpService.getData<i18nMessageDTOV1[]>(`/api/v1/i18n/${key}`)
}

/**
 * Save localized messages for a given key (Obsolete)
 */
export const saveLocalizedMessages = async (key: string, localizedMessage: i18nMessageDTOV1[]): Promise<i18nMessageDTOV1[]> => {
  return await HttpService.putData<i18nMessageDTOV1[]>(`/api/v1/i18n/${key}`, localizedMessage)
}

export const loadEnumTranslations = async (prefix: PrefixTranslationsEnum, cacheTranslations: boolean = true): Promise<void> => {
  const sessionStore = useSessionStore()
  await addTranslations(sessionStore.locale, prefix, cacheTranslations ? prefix : undefined)
}
