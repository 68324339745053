import { type MessageType, type Notification, NotificationStrategyBitEnum } from '@/common/types/notification'
import { Notify, type QNotifyCreateOptions } from 'quasar'
import { useNotifications } from '@/agent/stores/NotificationStore'
import i18n from '@/plugins/i18n'
import { type i18nTranslation } from '@/common/types/i18n'
import { requestTranslations } from '@/common/services/LocalizationService'
import { type ApiResponseData } from '@/common/types/http'
import { useSessionStore } from '@/common/stores/sessionStore'
import { getInlineIcon } from '@/common/helpers/icon'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { faCircleCheck, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
const { t } = i18n.global

const toasts: ((props?: QNotifyCreateOptions | undefined) => void)[] = []

export const notify = (options: string | QNotifyCreateOptions) => {
  toasts.push(Notify.create(options))
}

export function displayNotification(notification: Notification, html: boolean = false): void {
  const notificationStore = useNotifications()

  if (!notificationStore.opened) {
    notify({ ...notification, type: `notification-${notification.messageType || 'info'}`, html })
  }

  notificationStore.add(notification)
}

export function displayNotificationByStrategy(notification: Notification, notifStrategy: NotificationStrategyBitEnum): void {
  const notificationStore = useNotifications()

  if ((notifStrategy & NotificationStrategyBitEnum.POP) > 0) {
    const notificationOptions: QNotifyCreateOptions = {
      message: notification.message,
      position: 'top-right',
      html: true,
      color: 'primary',
      actions: [
        {
          label: t('assist.ui.toast.close'),
          color: 'white',
          handler: () => {},
        },
      ],
    }
    notify(notificationOptions)
  }
  if ((notifStrategy & NotificationStrategyBitEnum.STACK) > 0) {
    notificationStore.add(notification)
  }
}

// TODO change here
const defaultColorMap: Record<MessageType, string> = {
  danger: 'negative',
  warning: 'warning',
  info: 'info',
  success: 'positive',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function displayCaseActionNotification(message: string, type: MessageType, persistent: boolean, customActions?: any) {
  // close button
  let actions = [{ icon: getInlineIcon(faXmark), size: 'xs', color: 'white', round: true, handler: () => {} }]

  if (customActions) {
    actions = [...customActions, ...actions]
  }

  notify({
    message,
    position: 'bottom-right',
    color: defaultColorMap[type],
    icon: type === 'success' ? getInlineIcon(faCircleCheck) : getInlineIcon(faTriangleExclamation),
    iconSize: '16px',
    actions,
    timeout: persistent ? 0 : 5000,
  })
}

export const displayApiResponseData = async (apiMessage: ApiResponseData, type: MessageType = 'info') => {
  try {
    const sessionStore = useSessionStore()
    const translations: i18nTranslation = await requestTranslations(sessionStore.locale, apiMessage.key, { raw: true })
    let message = apiMessage.key
    if (Object.keys(translations).length && apiMessage.key in translations) {
      message = translations[apiMessage.key]

      // Handle message content parameters replacement
      if (apiMessage.parameters && Array.isArray(apiMessage.parameters)) {
        for (const { name, value } of apiMessage.parameters) {
          message = message.replace(`{${name}}`, value)
        }
      }
    }
    notify({ message, type: `api-${type}` })
  } catch (error) {
    const message = error instanceof Error ? error.message : String(error)
    notify({ message, type: 'api-danger' })
  }
}

export const displayMessage = (message: string, type: MessageType = 'info', options: QNotifyCreateOptions = {}) => {
  notify({ message, color: defaultColorMap[type], timeout: 3000, ...options })
}

/**
 * Close all the opened toasts
 */
export const dismissAll = () => {
  for (const toast of toasts) {
    toast()
  }
}
