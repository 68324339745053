export enum EntityEnum {
  TICKET = 'TICKET',
  CUSTOMER = 'CUSTOMER',
  INTERACTION = 'INTERACTION',
}

export const NULLUUID: UUID = '00000000-0000-0000-0000-000000000000'

export enum ResourceEnum {
  TICKET = 'tickets',
  CUSTOMER = 'customers',
  INTERACTION = 'interactions',
}
