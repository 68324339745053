import { type Category, type Entry, type Group } from '@/admin/types/menu'
import menuItems from '@/admin/components/menu/menuItems'
import { useSessionStore } from '@/common/stores/sessionStore'
import { type AuthoritiesEnum } from '@/common/types/session'
import { MENU_ITEMS_DISABLED_FOR_OIDC } from '@/common/services/AuthenticationService'
import { isDisabledFeatureView } from '@/common/services/FeatureFlagService'

export const menuTraverser = (accessRights: Array<AuthoritiesEnum>) => (category: Category) => {
  const keepGroup = (group: Group) => {
    if (filterDisabledOidcItems(group.link)) return false
    if (isDisabledFeatureView(group.link)) return false
    if (group.accessRights) return accessRights.some((r) => group.accessRights!.includes(r))
    return group.children?.find((entry) => accessRights.includes(entry.accessRight))
  }

  const keepEntry = (entry: Entry) => {
    return accessRights.includes(entry.accessRight)
  }

  let groups: Array<Group> = category.children.filter(keepGroup)
  groups = groups.map((group: Group) => {
    const entries = group.children?.filter(keepEntry)
    return { ...group, children: entries }
  })

  return { ...category, children: groups }
}

type SingleMenu = { category: Category; group: Group; entry: Entry | null }

export const getMenuByLink = (link: string): SingleMenu | null => {
  let linkLength = 0
  const activeMenu = {} as SingleMenu

  const isLinkFromItem = (item: Group | Entry) => item.link && link.includes(item.link) && item.link.length > linkLength

  const updateActiveMenu = (category: Category, group: Group, entry: Entry | null = null) => {
    const item = entry || group
    if (isLinkFromItem(item)) {
      activeMenu.category = category
      activeMenu.group = group
      activeMenu.entry = entry
      linkLength = item.link?.length ?? 0
    }
  }

  for (const category of menuItems) {
    for (const group of category.children) {
      updateActiveMenu(category, group)

      if (group.children) {
        for (const entry of group.children) {
          updateActiveMenu(category, group, entry)
        }
      }
    }
  }

  if (!linkLength) return null

  return activeMenu
}

export const userHaveAccessToMenu = (authorities: Array<string>, menu: SingleMenu) => {
  if (!menu) return false

  if (menu.group?.accessRights) return authorities.some((r) => menu.group.accessRights!.includes(r as AuthoritiesEnum))

  if (!menu.entry) return false

  if (menu.entry.accessRight) return authorities.includes(menu.entry.accessRight)

  return false
}

function filterDisabledOidcItems(itemLink: string | undefined): boolean {
  const oidcEnabled = useSessionStore().oidcEnabled
  return oidcEnabled && MENU_ITEMS_DISABLED_FOR_OIDC.includes(itemLink ?? '')
}
