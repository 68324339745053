import { useQuasar } from 'quasar'
import { useApplicationStore } from '@/common/stores/applicationStore'
import { watchEffect } from 'vue'

export default function useLoading() {
  const $q = useQuasar()
  const applicationStore = useApplicationStore()

  watchEffect(
    () => {
      if (applicationStore.ready) {
        $q.loading.hide()
      } else {
        $q.loading.show()
      }
    },
    {
      flush: 'post',
    },
  )
}
