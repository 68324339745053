import { ref, computed, type ComputedRef, nextTick } from 'vue'
import { defineStore } from 'pinia'
import { type EntityEnum } from '@/common/types/general'
import {
  createCriteria,
  getMaxResultParameter,
  getResultViewConfigToDisplay,
  fetchSearchResultIds,
  fetchResultDetails,
  getEntitiesOptions,
  getSavedFilters,
  paginate,
} from '@/search/services/SearchService'
import { EntityFieldCodeEnum } from '@/common/types/fields'
import type {
  SearchStoreIdEnum,
  GroupedCriteriaParentEnum,
  SearchFieldWrapper,
  Criteria,
  GroupedCriteria,
  SearchFilterDTOV1,
  EntitySearchable,
  ScopeForAttachParent,
} from '@/search/types/search'
import { PanelEnum, columnNameChannelIcon, FieldsUsedByGroupedCriteriaMap } from '@/search/types/search'
import { type ResultViewConfig } from '@/search/types/resultViewConfig'
import { useContextualSearchStore } from '@/search/stores/contextualSearchStore'

export const useSearchStore = (storeId: SearchStoreIdEnum = useContextualSearchStore().searchStoreId) => {
  return defineStore(storeId, () => {
    const displaySearchDialog = ref<boolean>(false)
    const ready = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const currentPanel = ref<PanelEnum>(PanelEnum.SearchForm)
    const entitiesOptions = ref<{ label: string; value: EntityEnum }[]>([])
    const entitySelected = ref<EntityEnum | null>(null)
    const criteria = ref<Criteria>({})
    const groupedCriteria = ref<GroupedCriteria>({})
    const resetInProgress = ref<boolean>(false)
    const maxResult = ref<number>(1000)
    const resultViewConfig = ref<{
      [entity: string]: ResultViewConfig
    }>({})
    const resultIds = ref<number[]>([])
    const resultMapEntities = ref<Map<number, EntitySearchable>>(new Map())
    const selectedResults = ref<EntitySearchable[]>([])
    const relaunchSearch = ref<boolean>(false)
    const savedFilters = ref<SearchFilterDTOV1[]>([])
    const selectedFilter = ref<SearchFilterDTOV1 | null>(null)
    const scopeForAttachParent = ref<ScopeForAttachParent>() // must be present if we search to attach a parent

    async function loadCriteria(fieldCodes?: string[]) {
      try {
        ;[criteria.value, groupedCriteria.value] = await createCriteria(fieldCodes)
      } catch (error) {
        console.log(error)
      }
    }

    async function loadMaxResultParameter() {
      try {
        const parameter = await getMaxResultParameter()
        const nb: number = parseInt(parameter.value)
        if (Number.isInteger(nb) && nb !== 0) {
          maxResult.value = nb
        } else {
          maxResult.value = 1000
        }
      } catch (error) {
        console.log(error)
      }
    }

    function initEntitiesOptions(entities?: EntityEnum[]) {
      entitiesOptions.value = getEntitiesOptions(entities)
      entitySelected.value = null
    }

    async function launchSearch() {
      if (isSearchAvailable.value && entitySelected.value) {
        loading.value = true
        resultViewConfig.value[entitySelected.value] =
          resultViewConfig.value[entitySelected.value] ?? (await getResultViewConfigToDisplay(storeId, entitySelected.value))
        currentPanel.value = PanelEnum.SearchResult
        loading.value = false
      }
    }

    async function fetchResultIds(sortBy: string | null, descending: boolean) {
      if (!entitySelected.value) return

      resultMapEntities.value.clear()
      let sort: string[] = []
      const sortSens = descending ? 'desc' : 'asc'
      resultViewConfig.value[entitySelected.value].sortBy = sortBy
      resultViewConfig.value[entitySelected.value].sortSens = sortSens
      if (sortBy !== null) {
        const sortByForRequest = sortBy === columnNameChannelIcon ? EntityFieldCodeEnum.CURRENT_CHANNEL : sortBy
        sort = [sortByForRequest + ':' + sortSens]
      }
      resultIds.value = await fetchSearchResultIds(
        storeId,
        entitySelected.value,
        getFieldWrapperList.value,
        sort,
        maxResult.value,
        scopeForAttachParent.value?.spaceCode,
      )
    }

    async function fetchEntitiesByPage(page: number, rowsPerPage: number): Promise<Array<EntitySearchable>> {
      if (!entitySelected.value) return []

      const currentIds: number[] = paginate(resultIds.value, page, rowsPerPage === 0 ? resultIds.value.length : rowsPerPage)

      resultViewConfig.value[entitySelected.value].rowsPerPage = rowsPerPage

      const resultDetails: Array<EntitySearchable> = await fetchResultDetails(
        entitySelected.value,
        currentIds.filter((id) => !resultMapEntities.value.has(id)),
        resultViewConfig.value[entitySelected.value].columns,
      )
      resultDetails.forEach((obj) => {
        resultMapEntities.value.set(obj.id, obj)
      })

      return currentIds.reduce((acc, id) => {
        const entity = resultMapEntities.value.get(id)
        return entity ? [...acc, entity] : acc
      }, [] as Array<EntitySearchable>)
    }

    async function fetchSavedFilters() {
      savedFilters.value = await getSavedFilters()
    }

    function removeOneSavedFilter(idToRemove: UUID) {
      savedFilters.value = savedFilters.value.filter((s) => s.id !== idToRemove)
    }

    function resetCriteria() {
      resetInProgress.value = true
      getFieldWrapperList.value.forEach((fieldWrapper) => {
        fieldWrapper.operation = fieldWrapper.defaultOperation
        fieldWrapper.currentValue = fieldWrapper.defaultValue.slice()
      })
      getFieldWrapperListInGroupedCriteria.value.forEach((fieldWrapper) => {
        fieldWrapper.operation = fieldWrapper.defaultOperation
        fieldWrapper.currentValue = fieldWrapper.defaultValue.slice()
      })
      nextTick(() => {
        resetInProgress.value = false
      })
    }

    function resetSearch() {
      resetCriteria()
      selectedFilter.value = null
      currentPanel.value = PanelEnum.SearchForm
    }

    const getFieldWrapperList: ComputedRef<SearchFieldWrapper[]> = computed(() => {
      return Object.values(criteria.value)
    })

    const getFieldWrapperByCode = computed(() => {
      return (code: string): SearchFieldWrapper => {
        return criteria.value[code]
      }
    })

    const getFieldWrapperListInGroupedCriteria: ComputedRef<SearchFieldWrapper[]> = computed(() => {
      return Object.values(groupedCriteria.value)
    })

    const getFieldWrapperInGroupedCriteriaByCode = computed(() => {
      return (code: string): SearchFieldWrapper => {
        return groupedCriteria.value[code]
      }
    })

    const getFieldWrappersForOneGroupedCriteriaParent = computed(() => {
      return (code: GroupedCriteriaParentEnum): SearchFieldWrapper[] => {
        const fieldWrappers: SearchFieldWrapper[] = []
        FieldsUsedByGroupedCriteriaMap.forEach((value: GroupedCriteriaParentEnum, key: string) => {
          if (code === value && groupedCriteria.value[key]) {
            fieldWrappers.push(groupedCriteria.value[key])
          }
        })
        return fieldWrappers
      }
    })

    const isSearchAvailable: ComputedRef<boolean> = computed(() => {
      return getFieldWrapperList.value.filter((fieldWrapper) => fieldWrapper.currentValue.length > 0).length > 0 && !!entitySelected.value
    })

    const getCounterFieldsFilled = computed(() => {
      return (entity: EntityEnum): number => {
        return Object.values(criteria.value).filter((fieldWrapper) => fieldWrapper.field.entity === entity && fieldWrapper.currentValue.length > 0)
          .length
      }
    })

    const getFieldsFilled = computed(() => {
      return (entity: EntityEnum): SearchFieldWrapper[] => {
        return Object.values(criteria.value).filter((fieldWrapper) => fieldWrapper.field.entity === entity && fieldWrapper.currentValue.length > 0)
      }
    })

    return {
      displaySearchDialog,
      ready,
      loading,
      currentPanel,
      entitiesOptions,
      entitySelected,
      criteria,
      groupedCriteria,
      resetInProgress,
      maxResult,
      resultViewConfig,
      resultIds,
      resultMapEntities,
      selectedResults,
      savedFilters,
      selectedFilter,
      relaunchSearch,
      scopeForAttachParent,
      loadCriteria,
      loadMaxResultParameter,
      initEntitiesOptions,
      launchSearch,
      fetchResultIds,
      fetchEntitiesByPage,
      fetchSavedFilters,
      removeOneSavedFilter,
      resetCriteria,
      resetSearch,
      getFieldWrapperList,
      getFieldWrapperByCode,
      getFieldWrapperListInGroupedCriteria,
      getFieldWrapperInGroupedCriteriaByCode,
      getFieldWrappersForOneGroupedCriteriaParent,
      isSearchAvailable,
      getCounterFieldsFilled,
      getFieldsFilled,
    }
  })()
}
