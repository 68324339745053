import * as VoiceService from '@/admin/services/VoiceService'
import {
  generateAxialysToken,
  getAllInboundCallConfigurations,
  getAllOutboundCallConfigurations,
  getAllProvidersConfigurations,
  getAxialysConfiguration,
  getAxialysInboundCallConfigurations,
  getOdigoConfiguration,
  getOdigoInboundCallConfigurations,
  getOdigoOutboundCallConfigurations,
} from '@/admin/services/VoiceService'
import {
  type IFrameStyle,
  type InboundCallConfigurationDTOV2,
  type OdigoConfigurationDTOV1,
  type OutboundCallConfigurationDTOV2,
  type ProviderGeneralConfigurationDTOV2,
  VoiceProvider,
} from '@/admin/types/voice'
import {
  CtiEventSource,
  CtiInteractionTypeEnum,
  CtiLandingPageEnum,
  type CtiOdigoAgentAndStatus,
  type CtiService,
  CtiStatus,
  CtiStatusColor,
  ctiStatusColorMap,
} from '@/cti/types/cti'
import { defineStore } from 'pinia'
import { type Ref, ref } from 'vue'
import { AuthoritiesEnum } from '@/common/types/session'
import { useSessionStore } from '@/common/stores/sessionStore'
import router from '@/router'
import { APP_ROUTE_NAMES } from '@/app/types/router'

const dummyCtiService: CtiService = {
  call() {
    /* Dummy == NoOp */
  },
  release() {
    /* Dummy == NoOp */
  },
}

const blankURL = new URL('about:blank')

export const useCtiStore = defineStore('cti', () => {
  const sessionStore = useSessionStore()

  const defaultProvider: Ref<VoiceProvider> = ref(VoiceProvider.NONE)
  const token: Ref<string> = ref('')
  const toReinitialized: Ref<boolean> = ref(false)
  const connectable: Ref<boolean> = ref(false)
  const voiceIsInitialized: Ref<boolean> = ref(false)
  const dialogVisibility: Ref<boolean> = ref(false)
  const dialogStateSource: Ref<CtiEventSource> = ref(CtiEventSource.Automatic)
  const callerPhone: Ref<string | null> = ref('')
  const service: Ref<CtiService> = ref(dummyCtiService)
  const idGroup: Ref<string | null> = ref(null)
  const url: Ref<URL> = ref(blankURL)
  const inboundCallsMap: Ref<Map<string, InboundCallConfigurationDTOV2>> = ref(new Map())
  const outboundCallsMap: Ref<Map<string, OutboundCallConfigurationDTOV2>> = ref(new Map())
  const urlString: Ref<string> = ref('')
  const calledPhone: Ref<string | null> = ref(null)
  const customerId: Ref<number | null> = ref(null)
  const idCall: Ref<string | null> = ref(null)
  const direction: Ref<CtiInteractionTypeEnum | null> = ref(null)
  const updateAtEnd: Ref<boolean> = ref(false)
  const landingPage: Ref<CtiLandingPageEnum> = ref(CtiLandingPageEnum.TICKET_HANDLING_SCREEN)
  const status: Ref<CtiStatus> = ref(CtiStatus.DISCONNECTED)
  const agentLogin: Ref<string | null> = ref(null)
  const ticketId: Ref<number | null> = ref(null)
  const iframeStyle: Ref<IFrameStyle> = ref({
    narrowWidth: '320px',
    wideWidth: '560px',
    height: '200vh',
    innerPadding: '48px',
  })
  const genesysConfiguration: Ref<ProviderGeneralConfigurationDTOV2 | undefined> = ref(undefined)
  const iframeUrlUpdate: Ref<boolean> = ref(false)

  const odigoAgentStatus: Ref<string | null> = ref(null)
  const incorrectOdigoUser: Ref<boolean> = ref(false)

  const odigoConfiguration: Ref<OdigoConfigurationDTOV1 | undefined> = ref(undefined)

  function statusColor(): CtiStatusColor {
    return ctiStatusColorMap.get(status.value) ?? CtiStatusColor.black
  }

  function toggleDialog(source: CtiEventSource) {
    dialogVisibility.value = !dialogVisibility.value
    dialogStateSource.value = source
  }

  function showDialog(source: CtiEventSource) {
    dialogVisibility.value = true
    dialogStateSource.value = source
  }

  function hideDialog(source: CtiEventSource) {
    dialogVisibility.value = false
    dialogStateSource.value = source
  }

  function newInboundCall(
    newCallerPhone: string,
    newIdGroup: string | null,
    newCalledPhone: string | null,
    newIdCall: string | null,
    newUpdateAtEnd: boolean,
  ) {
    direction.value = CtiInteractionTypeEnum.INBOUND
    showDialog(CtiEventSource.Automatic)
    callerPhone.value = newCallerPhone
    idGroup.value = newIdGroup
    calledPhone.value = newCalledPhone
    idCall.value = newIdCall
    updateAtEnd.value = newUpdateAtEnd
  }

  function newOutboundCall(
    newCallerPhone: string | null,
    newIdGroup: string | null,
    newCalledPhone: string | null,
    newCustomerId: number | null,
    newIdTicket: number | null,
    newIdCall: string | null,
    newUpdateAtEnd: boolean,
  ) {
    status.value = CtiStatus.ON_CALL
    direction.value = CtiInteractionTypeEnum.OUTBOUND
    showDialog(CtiEventSource.Automatic)
    callerPhone.value = newCallerPhone
    idGroup.value = newIdGroup
    calledPhone.value = newCalledPhone
    customerId.value = newCustomerId
    ticketId.value = newIdTicket
    idCall.value = newIdCall
    updateAtEnd.value = newUpdateAtEnd
  }

  function endCall(newIdGroup: string | null, newCalledPhone: string | null, newIdCall: string | null) {
    idGroup.value = newIdGroup
    calledPhone.value = newCalledPhone
    idCall.value = newIdCall
    status.value = CtiStatus.AVAILABLE
  }

  function release() {
    toReinitialized.value = true

    service.value.release()
    service.value = dummyCtiService
  }

  async function initCtiConfig() {
    defaultProvider.value = await VoiceService.getVoiceProvider()
    console.log('cti - store defaultProvider', defaultProvider.value, sessionStore.getAuthoritySet.has(AuthoritiesEnum.CTI_ACCESS))
    if (!sessionStore.getAuthoritySet.has(AuthoritiesEnum.CTI_ACCESS)) {
      connectable.value = false
      voiceIsInitialized.value = true
      return
    }

    switch (defaultProvider.value) {
      case VoiceProvider.AXIALYS:
        await initWithAxialys()
        break
      case VoiceProvider.ODIGO:
        await initWithOdigo()
        break
      case VoiceProvider.GENESYS:
        await initWithGenesys()
        break
      case VoiceProvider.NONE:
        connectable.value = false
        console.debug('There is no active voice provider')
        break
      default:
        connectable.value = false
        console.error('The selected voice provider is not implemented')
        break
    }
    voiceIsInitialized.value = true
  }

  function reloadUrl() {
    url.value.searchParams.set('refresh-time', `${new Date().getTime()}`)
    urlString.value = url.value.toString()
  }

  async function initWithAxialys() {
    try {
      const axialysConfiguration = await getAxialysConfiguration()

      if (!axialysConfiguration) {
        throw new Error('Configuration not loaded')
      }
      token.value = (<{ token: string }>await generateAxialysToken()).token
      landingPage.value = <CtiLandingPageEnum>axialysConfiguration.commonConfiguration?.landingPage?.toString()
      connectable.value = true

      url.value = new URL(axialysConfiguration.commonConfiguration.iframeUrl)
      urlString.value = url.value.toString()
      const inboundCalls = await getAxialysInboundCallConfigurations()
      inboundCalls.forEach((element) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        element.active && inboundCallsMap.value.set(element.code, element)
      })
    } catch (e) {
      console.error(e)
      connectable.value = false
    }
  }

  async function initWithOdigo() {
    try {
      iframeStyle.value.narrowWidth = '315px'
      iframeStyle.value.wideWidth = '615px'
      iframeStyle.value.height = '78vh'

      odigoConfiguration.value = await getOdigoConfiguration()
      if (!odigoConfiguration.value) {
        throw new Error('Configuration not loaded')
      }
      connectable.value = true
      landingPage.value = <CtiLandingPageEnum>odigoConfiguration.value.commonConfiguration?.landingPage?.toString()

      url.value = new URL(odigoConfiguration.value.commonConfiguration.iframeUrl)
      urlString.value = url.value.toString()
      const inboundCalls = await getOdigoInboundCallConfigurations()
      inboundCallsMap.value.clear()
      inboundCalls.forEach((element) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        element.active && inboundCallsMap.value.set(element.code, element)
      })
      outboundCallsMap.value.clear()
      const outboundCalls = await getOdigoOutboundCallConfigurations()
      outboundCalls.forEach((element) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        element.active && outboundCallsMap.value.set(element.code, element)
      })
    } catch (e) {
      console.error(e)
      connectable.value = false
    }
  }

  async function getOdigoAgentAndStatus() {
    try {
      const odigoAgentAndStatus: CtiOdigoAgentAndStatus | null = await VoiceService.getOdigoAgentAndStatus()

      odigoAgentStatus.value = odigoAgentAndStatus?.status || null
      agentLogin.value = odigoAgentAndStatus?.ctiIdentifier || null
    } catch (message) {
      console.error(message)
    }
  }

  function isOutboundcallAllowed(): boolean {
    if (defaultProvider.value === VoiceProvider.ODIGO) {
      return [CtiStatus.AVAILABLE, CtiStatus.ON_BREAK].includes(status.value)
    } else {
      return true
    }
  }

  async function initWithGenesys() {
    try {
      iframeStyle.value.height = '78vh'

      const configurations = await getAllProvidersConfigurations()
      genesysConfiguration.value = configurations.find(
        (configuration: ProviderGeneralConfigurationDTOV2) => configuration.provider === VoiceProvider.GENESYS,
      )

      if (!genesysConfiguration.value) {
        throw new Error('Configuration not loaded')
      }
      connectable.value = true

      initGenesysAuthentication()

      if (!genesysConfiguration.value.id) return
      const inboundCalls = await getAllInboundCallConfigurations()
      inboundCallsMap.value.clear()
      inboundCalls.forEach((element) => {
        if (!element.active) return
        inboundCallsMap.value.set(element.code, element)
      })
      outboundCallsMap.value.clear()
      const outboundCalls = await getAllOutboundCallConfigurations()
      outboundCalls.forEach((element) => {
        if (!element.active) return
        outboundCallsMap.value.set(element.code, element)
      })
    } catch (e) {
      console.error(e)
      connectable.value = false
    }
  }

  function initGenesysAuthentication() {
    const genesysAuthRoute = router.resolve({
      name: APP_ROUTE_NAMES.GENESYS_AUTH_IFRAME_NAME,
    })
    const authPageAssetUrlString = window.__toCdnUrl(genesysAuthRoute.path.replace('/', ''))
    if (!authPageAssetUrlString) {
      throw new Error(`Invalid URL of Genesys Auth Iframe: ${authPageAssetUrlString}`)
    }
    const authPageAssetUrl = new URL(authPageAssetUrlString)
    authPageAssetUrl.hash = `origin=${window.location.origin}`
    url.value = authPageAssetUrl
    urlString.value = url.value.toString()
  }

  return {
    defaultProvider,
    token,
    toReinitialized,
    connectable,
    voiceIsInitialized,
    dialogVisibility,
    dialogStateSource,
    callerPhone,
    service,
    idGroup,
    calledPhone,
    customerId,
    url,
    inboundCallsMap,
    outboundCallsMap,
    urlString,
    idCall,
    direction,
    updateAtEnd,
    landingPage,
    status,
    agentLogin,
    ticketId,
    statusColor,
    toggleDialog,
    showDialog,
    hideDialog,
    newInboundCall,
    newOutboundCall,
    endCall,
    release,
    initCtiConfig,
    reloadUrl,
    iframeStyle,
    odigoConfiguration,
    odigoAgentStatus,
    getOdigoAgentAndStatus,
    incorrectOdigoUser,
    isOutboundcallAllowed,
    genesysConfiguration,
    iframeUrlUpdate,
    initGenesysAuthentication,
  }
})
