/**
 * All screen types
 */
export enum ScreenTypeEnum {
  TICKET = 'TICKET',
  CALL = 'CALL',
  CLIENT = 'CLIENT',
  TICKETSEARCH = 'TICKETSEARCH',
  TICKETLIST = 'TICKETLIST',
  INTERACTION = 'INTERACTION',
  CUSTOMERSEARCH = 'CUSTOMERSEARCH',
  CLIENTLIST = 'CLIENTLIST',
  INTERACTIONLIST = 'INTERACTIONLIST',
}

/**
 * Field screen relation interface
 */
export interface FieldScreenRelation {
  id: number
  entityField?: string
  customField?: string
  updatable: boolean
  mandatory: boolean
  alwaysVisible: boolean
  inPasteArea: boolean
  displayOrder: number
  defaultSortDir?: 'asc' | 'desc'
}

// V1
/**
 * Screen base interface
 */
export interface ScreenBaseDTOV1 {
  id: number
  code: UUID
  name: string
  type: ScreenTypeEnum
}
/**
 * Screen interface
 */
export interface ScreenDTOV1 extends ScreenBaseDTOV1 {
  fieldScreenRelations: FieldScreenRelation[]
}

// V2
export interface ScreenBaseDTOV2 {
  code: UUID
  type: ScreenTypeEnum
  active: boolean
  default: boolean
}

export interface ScreenDTOV2 extends ScreenBaseDTOV2 {
  fields: ScreenField[]
}

export interface ScreenField {
  displayOrder: number
  code: string
  sortDirection?: ScreenFieldSort
  alwaysVisible: boolean
}

export enum ScreenFieldSort {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}
