import { useRootStore } from '@/engage/stores/root'
import middlewares from '@/engage/router/middlewares'
import { isSameRoutePath } from '@/engage/helper/router'
import { type NavigationGuardWithoutNext } from '@/app/types/router'

export const resetEngageStoreBeforeEach: NavigationGuardWithoutNext = async (to, from) => {
  if (!from.meta.engage || isSameRoutePath(to, from)) {
    return
  }

  const rootStore = useRootStore()
  await rootStore.resetRouteData(to.params?.ticketId as string)
}

export const engageBeforeEach: NavigationGuardWithoutNext = async (to, from) => {
  const isFirstRoute = !from.meta?.engage && to.meta?.engage

  if (isSameRoutePath(to, from)) return

  if (from.meta.worklog) {
    await middlewares.worklog.saveWorkLog()
  }

  // Await until require data has been fetch according to the route
  if (isFirstRoute) {
    await Promise.all([middlewares.initializeData.getLoggedUser(), middlewares.initializeData.getUserAccessRight()])
  }

  // Check right access
  if (to.meta.supervisorRoute && !middlewares.supervisorRoute.userHasAccess()) {
    return { name: '404' }
  } else if (middlewares.pathMatch.isEngageRoute(to) && middlewares.supervisorRoute.userHasAccess()) {
    // Block access to engage for supervisor
    return { name: 'command-center-default' }
  }

  // Check route availability and path
  if (isFirstRoute) {
    if (middlewares.pathMatch.isCommandCenterRoute(to)) {
      middlewares.pathMatch.delayRoutePathValidation({
        helpRequest: true,
      })
    } else {
      middlewares.pathMatch.delayRoutePathValidation()
    }
  } else if (middlewares.pathMatch.isTicketPathNotValid(to)) {
    return { name: '404' }
  }
}
