import HttpService from '@/common/services/HttpService'
import { type CaseActionModalEnum, type CaseActionDTOV1, type CompleteActionDTOV1 } from '@/common/types/caseAction'
export class QualificationError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'QualificationError'
  }
}

export const checkCaseAction = async (action: keyof typeof CaseActionModalEnum) => {
  const { type, message } = await HttpService.postData<{ type: 'error' | 'success'; message: string }>(
    `/ticket/checkAndUpdate?action=${action.toUpperCase()}&update=false`,
  )

  if (type === 'error') throw new QualificationError(message)
}

export const performCompleteCaseAction = (caseId: number, data: CompleteActionDTOV1) => {
  return HttpService.put(`/api/v1/cases/${caseId}/complete`, data)
}

export const performCompleteOnValidationCaseAction = (caseId: number, data: CompleteActionDTOV1) => {
  return HttpService.put(`/api/v1/cases/${caseId}/complete-on-validation`, data)
}

/**
 * @param ids Case action ids
 * @returns array of CaseActionDTOV1
 */
export const findCaseActions = async (ids: number[]): Promise<CaseActionDTOV1[]> => {
  return HttpService.getData<CaseActionDTOV1[]>('/api/v1/case-actions', {
    params: { ids: ids.join() },
  })
}
