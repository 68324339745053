import type { TeamDTOV1 } from '@/common/types/teams.ts'
import { loadEnumTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { get as getCachedData } from '@/common/services/LocalStorageService'

const serviceRoot = '/api/v1/teams'

export const getTeams = async (cacheKey: string): Promise<TeamDTOV1[]> => {
  return getCachedData<TeamDTOV1[]>(cacheKey, serviceRoot, {
    params: {
      includeDeactivated: true,
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchTeams = async (cacheKey: string): Promise<any> => {
  return await Promise.all([getTeams(cacheKey), loadEnumTranslations(PrefixTranslationsEnum.USERGROUP)])
}
