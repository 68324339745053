import { ref } from 'vue'
import { defineStore } from 'pinia'
import { fetchTeams } from '@/common/services/TeamsService'
import { type TeamDTOV1 } from '@/common/types/teams'
import { removeEntry } from '@/common/services/LocalStorageService'
import { API_CACHE_KEYS } from '@/common/types/cacheConstants'
import { PrefixTranslationsEnum } from '@/common/types/i18n'

export const useTeamStore = defineStore('team', () => {
  const teams = ref<TeamDTOV1[]>([])

  async function fetch(invalidate: boolean = false) {
    try {
      if (invalidate) {
        removeEntry(API_CACHE_KEYS.TEAMS)
        removeEntry(PrefixTranslationsEnum.USERGROUP)
      }
      ;[teams.value] = await fetchTeams(API_CACHE_KEYS.TEAMS)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    teams,
    fetch,
  }
})
