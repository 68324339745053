import { APP_ROUTE_NAMES } from '@/app/types/router'
import { type RouteRecordRaw } from 'vue-router'

const BaseHeader = () => import('@/agent/components/headers/BaseHeader.vue')

export default [
  {
    path: 'cti/newCall',
    component: BaseHeader,
    meta: {
      legacy: true,
      refresh: true,
      exclude: ['/assist/ticket/save'],
      allowBackNavigation: true,
      skipHistory: true,
    },
  },
  {
    path: '/call/provider/genesys/connect.html',
    component: {},
    name: APP_ROUTE_NAMES.GENESYS_AUTH_IFRAME_NAME,
  },
] as Array<RouteRecordRaw>
