import hostConfig from '@/common/helpers/configResolver'
import router from '@/router'
import { getNavigationHistory, storeNavigationHistory } from '@/common/services/NavigationService'
import { type RouteLocationOptions } from 'vue-router'
import { sanitizeUrl } from '@/plugins/sanitizer'
import { APP_ROUTE_PATHS } from '@/app/types/router'
import { useContextualSearchStore } from '@/search/stores/contextualSearchStore'

export default function useNavigation() {
  function redirectToLegacy(url: string) {
    window.location.href = sanitizeUrl(hostConfig.root + url)
  }

  function openCase(caseId: string | number, params?: RouteLocationOptions) {
    router.push({ path: `/assist/ticket/show/${caseId}`, ...params })
  }

  function openCustomer(customerId: string | number, params?: RouteLocationOptions) {
    useContextualSearchStore().resetContextualSearch()
    router.push({ path: `/assist/customer/edit/${customerId}`, ...params })
  }

  function openCustomerSearch() {
    redirectToLegacy('assist/tickets/list?showCustomerSearchTab=true&continue')
  }

  const goToPreviousPage = () => {
    const history = getNavigationHistory()
    if (history.length && history[history.length - 1].path !== router.currentRoute.value.fullPath) {
      const lastPath = history[history.length - 1]
      lastPath.backNavigation = true
      storeNavigationHistory(history)
      router.push({ path: lastPath.path, force: true })
    } else {
      router.push({ path: APP_ROUTE_PATHS.HOMEPAGE, force: true })
    }
  }

  return { redirectToLegacy, openCase, openCustomer, openCustomerSearch, goToPreviousPage }
}
