import CUSTOM_FIELD_TYPE from '@/engage/constants/customFieldType'

export default Object.freeze({
  // Example
  // TYPE: {
  //   component: [COMPONENT NAME],
  //   field: {
  //     [VUETIFY FIELD OPTIONS],
  //
  ///     DATE FIELD extra option
  //      dateType: ['datetime' | 'date'],
  //      datetimeReadOnlyFormat: [DAYJS FORMAT LOCALIZED], // https://day.js.org/docs/en/display/format#localized-formats
  //      datetimeDisplayFormat: [LUXON FORMAT],
  //      datetimeAPIFormat: [INNSO REQUIRE FORMAT], // default is UTC
  //   },
  //   display: {
  //     title: false,
  //     value: false,
  //     icon: {
  //        src: [ICON COMPONENT]
  //        color: [String]
  //     },
  //   },
  // },

  [CUSTOM_FIELD_TYPE.LOCAL_DATE]: {
    component: 'FieldDate',
    field: {
      dateType: 'date',
      hideDetails: false,
      datetimeReadOnlyFormat: 'L',
      datetimeDisplayFormat: 'dd/MM/yyyy',
      datetimeAPIFormat: 'YYYY-MM-DD',
    },
  },
  [CUSTOM_FIELD_TYPE.LOCAL_DATE_TIME]: {
    component: 'FieldDate',
    field: {
      dateType: 'datetime',
      datetimeReadOnlyFormat: 'L LT',
      datetimeDisplayFormat: 'dd/MM/yyyy HH:mm',
    },
  },
  [CUSTOM_FIELD_TYPE.EMAIL]: {
    component: 'FieldInput',
    field: {
      clearable: true,
      hideDetails: false,
      inputType: 'email',
    },
  },
  [CUSTOM_FIELD_TYPE.LABEL]: {
    component: 'FieldContent',
    field: {},
  },
  [CUSTOM_FIELD_TYPE.SELECT_LIST_MULTIPLE]: {
    component: 'FieldSelect',
    field: {
      clearable: true,
      hideDetails: false,
      multipleChoice: true,
      optionsText: 'name',
      optionsValue: 'id',
    },
  },
  [CUSTOM_FIELD_TYPE.TREE_MULTIPLE]: {
    component: 'FieldTree',
    field: {},
  },
  [CUSTOM_FIELD_TYPE.NUMBER]: {
    component: 'FieldInput',
    field: {
      clearable: true,
      hideDetails: false,
      inputType: 'number',
    },
  },
  [CUSTOM_FIELD_TYPE.CHECKBOX_SINGLE]: {
    component: 'FieldCheckbox',
    field: {},
  },
  [CUSTOM_FIELD_TYPE.ENCRYPTED_TEXT]: {
    component: 'FieldInput',
    field: {
      clearable: true,
      hideDetails: false,
    },
  },
  [CUSTOM_FIELD_TYPE.ENCRYPTED_TEXTAREA]: {
    component: 'FieldTextarea',
    field: {
      rows: 4,
      hideDetails: false,
    },
  },
  [CUSTOM_FIELD_TYPE.SELECT_LIST_SINGLE]: {
    component: 'FieldSelect',
    field: {
      clearable: true,
      optionsText: 'name',
      optionsValue: 'id',
      hideDetails: false,
    },
  },
  [CUSTOM_FIELD_TYPE.TREE_SINGLE]: {
    component: 'FieldTree',
    field: {},
  },
  [CUSTOM_FIELD_TYPE.TREE_SINGLE_ASCENDING]: {
    component: 'FieldTree',
    field: {},
  },
  [CUSTOM_FIELD_TYPE.TEXT]: {
    component: 'FieldInput',
    field: {
      clearable: true,
      hideDetails: false,
    },
  },
  [CUSTOM_FIELD_TYPE.TEXTAREA]: {
    component: 'FieldTextarea',
    field: {
      rows: 4,
      hideDetails: false,
    },
  },
  [CUSTOM_FIELD_TYPE.RADIO]: {
    component: 'FieldRadio',
    field: {
      hideDetails: false,
    },
  },
  [CUSTOM_FIELD_TYPE.CHECKBOX_MULTIPLE]: {
    component: 'FieldCheckboxMultiple',
    field: {
      hideDetails: false,
    },
  },

  // Custom
  [CUSTOM_FIELD_TYPE.TYPOLOGY]: {
    component: 'FieldTypologies',
    field: {
      clearable: true,
      hideDetails: false,
    },
  },
  [CUSTOM_FIELD_TYPE.TICKET_USERGROUP]: {
    component: 'FieldUserGroup',
    field: {
      clearable: true,
      hideDetails: false,
      optionsText: 'name',
      optionsValue: 'id',
    },
  },
  [CUSTOM_FIELD_TYPE.SWITCH]: {
    component: 'FieldSwitch',
    field: {
      hideDetails: false,
    },
  },
})
