export default Object.freeze({
  REGULAR: {
    name: 'regular',
    prefix: 'far',
  },
  SOLID: {
    name: 'solid',
    prefix: 'fas',
  },
})
