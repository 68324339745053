import CHANNEL_TYPE from '@/engage/constants/channelType'
import CHANNEL_GROUP from '@/engage/constants/channelGroup'
import { Channels } from '@/agent/types/channels'

export default Object.freeze({
  [Channels.SMS]: {
    name: CHANNEL_TYPE.SMS.name,
    color: '#5959EB',
    icon: 'fa:fas fa-sms',
    counterEditor: (provider: string) => (provider == 'VONAGE' ? 1000 : null),
    group: CHANNEL_GROUP.PRIVATE_CHANNELS,
  },
  [Channels.CHAT]: {
    name: CHANNEL_TYPE.CHAT.name,
    color: '#5959EB',
    icon: 'fa:fas fa-comment',
    counterEditor: null,
    group: CHANNEL_GROUP.HOT_CHANNELS,
  },
  [Channels.FACEBOOK_POST]: {
    name: CHANNEL_TYPE.FACEBOOK.name,
    color: '#1877F2',
    icon: 'fa:fab fa-facebook',
    like: '#3578e5',
    counterEditor: null,
    group: CHANNEL_GROUP.PUBLIC_CHANNELS,
  },
  [Channels.TWITTER]: {
    name: CHANNEL_TYPE.TWITTER.name,
    color: '#1D9BF0',
    icon: 'fa:fab fa-twitter',
    love: '#e0245e',
    counterEditor: () => 280,
    group: CHANNEL_GROUP.PUBLIC_CHANNELS,
  },
  [Channels.INSTAGRAM]: {
    name: CHANNEL_TYPE.INSTAGRAM.name,
    color: '#D0487A',
    icon: 'fa:fab fa-instagram',
    love: '#3578e5',
    counterEditor: null,
    group: CHANNEL_GROUP.PUBLIC_CHANNELS,
  },
  [Channels.INSTAGRAM_DM]: {
    name: CHANNEL_TYPE.INSTAGRAMDM.name,
    color: '#D0487A',
    icon: 'fa:fab fa-instagram-square',
    counterEditor: null,
    group: CHANNEL_GROUP.PRIVATE_CHANNELS,
  },
  [Channels.FB_MESSENGER]: {
    name: CHANNEL_TYPE.MESSENGER.name,
    color: '#7E4CF6',
    icon: 'fa:fab fa-facebook-messenger',
    counterEditor: null,
    group: CHANNEL_GROUP.PRIVATE_CHANNELS,
  },
  [Channels.TWITTER_DM]: {
    name: CHANNEL_TYPE.TWITTERDM.name,
    color: '#1D9BF0',
    colorAlt: '#3663CC',
    icon: 'fa:fab fa-square-twitter',
    counterEditor: null,
    group: CHANNEL_GROUP.PRIVATE_CHANNELS,
  },
  [Channels.WHATSAPP]: {
    name: CHANNEL_TYPE.WHATSAPP.name,
    color: '#25D366',
    icon: 'fa:fab fa-whatsapp',
    counterEditor: null,
    group: CHANNEL_GROUP.PRIVATE_CHANNELS,
  },
})
