import { defineStore } from 'pinia'
import { ref } from 'vue'
import Api from '@/engage/plugins/api'
import { getBreaks } from '@/common/services/breakService'
import { useRouterStore } from '@/engage/stores/router'
import { useEngageCasesStore } from '@/engage/stores/engageCases'
import type { BreakDTOV1 } from '@/agent/types/break'
import type { LegacyEngageCase } from '@/engage/types/case'

export const usePauseStore = defineStore('pause', () => {
  const isPrePause = ref(false)
  const isPause = ref(false)
  const currentPrePauseId = ref<number | null>(null)
  const currentPause = ref<BreakDTOV1 | null>(null)
  const currentActivity = ref<LegacyEngageCase | null>(null)
  const userSessionStateId = ref<number | null>(null)
  const listPause = ref<BreakDTOV1[]>([])

  const setCurrentPrePauseId = (data: number) => {
    currentPrePauseId.value = data
  }
  const setIsPrePause = (data: boolean) => {
    isPrePause.value = data
  }

  const getListPause = async () => {
    listPause.value = await getBreaks()
  }
  const startPause = async (payload: { currentActivity: LegacyEngageCase; item: BreakDTOV1 }) => {
    const routerStore = useRouterStore()
    const casesStore = useEngageCasesStore()
    currentActivity.value = payload.currentActivity
    currentPause.value = payload.item

    try {
      const { data } = await Api.pause.startPause(payload.item.userStateId)
      if (data.data) {
        userSessionStateId.value = data.data
        isPause.value = true
        routerStore.updateRouterStore()
        casesStore.closeActivity()
      }
    } catch {
      _resetPause()
    }
  }
  const stopPause = async () => {
    if (!userSessionStateId.value) return
    await Api.pause.stopPause(userSessionStateId.value)
    _resetPause()
  }
  const startPrePause = async ({ item }: { item: BreakDTOV1 }) => {
    const { data } = await Api.pause.startPrePause(item.userStateId)

    if (data?.status === 'success') {
      setIsPrePause(true)
      setCurrentPrePauseId(item.userStateId)
    }
    return data?.status === 'success'
  }
  const stopPrePause = async () => {
    if (!currentPrePauseId.value) return
    await Api.pause.stopPrePause(currentPrePauseId.value)
    _resetPrePause()
  }

  // internals
  const _resetPrePause = () => {
    isPrePause.value = false
    currentPrePauseId.value = null
  }
  const _resetPause = () => {
    userSessionStateId.value = null
    currentPause.value = null
    currentActivity.value = null
    isPause.value = false
  }

  return {
    isPrePause,
    isPause,
    currentPrePauseId,
    currentPause,
    currentActivity,
    userSessionStateId,
    listPause,
    setCurrentPrePauseId,
    setIsPrePause,
    getListPause,
    startPause,
    stopPause,
    startPrePause,
    stopPrePause,
  }
})
