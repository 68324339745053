import { getCommandCenterRouteByActivity, getEngageRouteByActivity } from '@/engage/helper/router'
import { useEngageCasesStore } from '@/engage/stores/engageCases'
import { type RouteLocationNormalized } from 'vue-router'

const activityExist = (ticketId: string) => {
  const casesStore = useEngageCasesStore()
  const id = Number(ticketId)
  return casesStore.getActivityById(id)
}

const isCorrectCommandCenterPath = (to: RouteLocationNormalized) => {
  const nextActivity = activityExist(to.params.ticketId as string)

  if (!nextActivity) {
    return false
  }

  const nextRoute = getCommandCenterRouteByActivity(nextActivity)
  return to.name === nextRoute.name
}

const isCorrectEngagePath = (to: RouteLocationNormalized) => {
  const nextActivity = activityExist(to.params.ticketId as string)

  if (!nextActivity) {
    return false
  }

  const nextRoute = getEngageRouteByActivity(nextActivity)
  return to.name === nextRoute.name
}

export default {
  activityExist,
  isCorrectEngagePath,
  isCorrectCommandCenterPath,
}
