export default Object.freeze({
  CASE: 'CASE',
  ACTION: 'ACTION',
  CUSTOMER: 'CUSTOMER',
  CONVERSATION: 'CONVERSATION',
  HISTORY: 'HISTORY',
  SURVEY: 'SURVEY',
  INTERACTION_QUALIFICATION: 'INTERACTION_QUALIFICATION',
  ATTACHMENTS: 'ATTACHMENTS',
})
