import { type IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { type AuthoritiesEnum } from '@/common/types/session'

export enum StateNames {
  'CTI_PROVIDER' = 'cti-provider',
}

export type Menu = Array<Category>

export type Category = {
  name: string
  icon: IconDefinition
  children: Array<Group>
}

export type Group = {
  name: string
  accessRights?: AuthoritiesEnum[] // if the user has one of these rights
  link?: string
  children?: Array<Entry>
  loader?: () => void
}

export type Entry = {
  name: string
  accessRight: AuthoritiesEnum
  link: string
  stateName?: StateNames
  state?: string
  alwaysActive?: boolean
}
