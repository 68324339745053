export const API_CACHE_KEYS = {
  USERS: 'users',
  TEAMS: 'teams',
  SKILLS: 'skills',
  TYPOLOGIES: 'typologies',
  EXPORT_DEFINITIONS: 'export-definitions',
  LOCALES: 'locales',
  SPACES: 'spaces',
  SCREENS: 'screens',
} as const
