import activities from '@/engage/router/middlewares/activities'
import worklog from '@/engage/router/middlewares/worklog'
import supervisorRoute from '@/engage/router/middlewares/supervisorRoute'
import pathMatch from '@/engage/router/middlewares/pathMatch'
import initializeData from '@/engage/router/middlewares/initializeData'

export default {
  activities,
  worklog,
  supervisorRoute,
  pathMatch,
  initializeData,
}
