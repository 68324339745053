import { type Dayjs } from 'dayjs'
import type dayjs from 'dayjs'
import { humanizer } from 'humanize-duration'

interface humanPluginOptions {
  language: string
}

export default (options: humanPluginOptions, _: typeof Dayjs, dayjsFactory: typeof dayjs) => {
  dayjsFactory.humanizeDuration = humanizer({
    language: options.language,
    largest: 2,
    round: false,
    maxDecimalPoints: 0,
    units: ['h', 'm', 's'],
  })
}
