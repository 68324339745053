import { type RouteRecordRaw } from 'vue-router'
import { agentBeforeEnter } from '@/router/guards'

const EngageView = () => import('@/engage/views/EngageView.vue')
import routes from '@/engage/router/routes'

export default [
  {
    path: '/assist/engage/view',
    component: EngageView,
    beforeEnter: agentBeforeEnter,
    children: [...routes],
    meta: {
      engage: true,
    },
  },
] as Array<RouteRecordRaw>
