import { type AxiosRequestConfig } from 'axios'
import HttpService from '@/common/services/HttpService'

const storagePrefix = 'assist'

type mapPromise = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [code: string]: Promise<any>
}
const promiseInProgressMap: mapPromise = {}

export const get = async <TResult>(key: string, endpoint: string | (() => Promise<TResult>), config: AxiosRequestConfig = {}) => {
  const storageKey = `${storagePrefix}-${key}`
  const localData = localStorage.getItem(storageKey)

  try {
    if (!localData) {
      if (promiseInProgressMap[key] === undefined) {
        const customFetch = (url: string) => HttpService.getData<TResult>(url, config)
        promiseInProgressMap[key] = typeof endpoint === 'string' ? customFetch(endpoint) : (endpoint as () => Promise<TResult>)()
      }
      const remoteData = await promiseInProgressMap[key]
      localStorage.setItem(storageKey, JSON.stringify(remoteData))
      delete promiseInProgressMap[key]
      return remoteData
    } else {
      return JSON.parse(localData) as TResult
    }
  } catch (error) {
    console.log(error)
  }
}

export const clear = (exclude: string[] = []) => {
  const excludePrefixList = exclude.map((p) => `${storagePrefix}-${p}`)
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const itemKey = localStorage.key(i)
    if (itemKey?.startsWith(`${storagePrefix}-`) && !excludePrefixList.find((k) => itemKey.startsWith(k))) {
      localStorage.removeItem(itemKey)
    }
  }

  Object.keys(promiseInProgressMap).forEach((key) => delete promiseInProgressMap[key])
}

export const removeEntry = (key: string) => {
  const storageKey = `${storagePrefix}-${key}`
  localStorage.removeItem(storageKey)
}
