export const Channels = {
  SMS: 8,
  CHAT: 9,
  FACEBOOK_POST: 12,
  TWITTER: 13,
  FB_MESSENGER: 14,
  TWITTER_DM: 15,
  WHATSAPP: 19,
  INSTAGRAM: 20,
  INSTAGRAM_DM: 21,
} as const

export type ChannelsIds = (typeof Channels)[keyof typeof Channels]

export type ChannelsAvailability = Partial<Record<ChannelsIds, boolean>>
