import { type RouteRecordRaw } from 'vue-router'
import { checkAccessRightsGuard, knowledgeBaseBeforeEnter } from '@/admin/router/guards'
import { ADMIN_ROUTE_NAMES } from '@/admin/types/router'
import { FieldContextEnum } from '@/common/types/fields'
import { ScreenTypeEnum } from '@/common/types/screen'
import { ResourceEnum } from '@/common/types/general'
import { FileDefinitionTypeEnum } from '@/common/types/fileDefinition'

const VoicebotConfiguration = () => import('@/admin/components/voice/voicebot/VoicebotConfiguration.vue')
const VoicebotInboundCallConfigurationEditionPage = () =>
  import('@/admin/components/voice/voicebot/inbound/VoicebotInboundCallConfigurationEditionPage.vue')
const VoicebotInboundCallConfigurationCreationPage = () =>
  import('@/admin/components/voice/voicebot/inbound/VoicebotInboundCallConfigurationCreationPage.vue')

const RedirectBackHandlingPage = () => import('@/admin/views/RedirectBackHandlingPage.vue')

const AdminView = () => import('@/admin/views/AdminView.vue')
const LegacyFrame = () => import('@/common/components/legacy/LegacyFrame.vue')

const ReasonsLandingPage = () => import('@/admin/components/reasons/ReasonsLandingPage.vue')
const ReasonsCreationPage = () => import('@/admin/components/reasons/ReasonsCreationPage.vue')
const ReasonsEditionPage = () => import('@/admin/components/reasons/ReasonsEditionPage.vue')
const AxialysConfiguration = () => import('@/admin/components/voice/axialys/AxialysConfiguration.vue')
const AxialysOutBoundCallConfigurationCreationPage = () =>
  import('@/admin/components/voice/axialys/outbound/AxialysOutboundCallConfigurationCreationPage.vue')
const AxialysOutBoundCallConfigurationEditionPage = () =>
  import('@/admin/components/voice/axialys/outbound/AxialysOutboundCallConfigurationEditionPage.vue')
const AxialysInboundCallConfigurationCreationPage = () =>
  import('@/admin/components/voice/axialys/inbound/AxialysInboundCallConfigurationCreationPage.vue')
const AxialysInboundCallConfigurationEditionPage = () =>
  import('@/admin/components/voice/axialys/inbound/AxialysInboundCallConfigurationEditionPage.vue')
const OdigoConfiguration = () => import('@/admin/components/voice/odigo/OdigoConfiguration.vue')
const OdigoOutBoundCallConfigurationCreationPage = () =>
  import('@/admin/components/voice/odigo/outbound/OdigoOutboundCallConfigurationCreationPage.vue')
const OdigoOutBoundCallConfigurationEditionPage = () =>
  import('@/admin/components/voice/odigo/outbound/OdigoOutboundCallConfigurationEditionPage.vue')
const OdigoInboundCallConfigurationCreationPage = () => import('@/admin/components/voice/odigo/inbound/OdigoInboundCallConfigurationCreationPage.vue')
const OdigoInboundCallConfigurationEditionPage = () => import('@/admin/components/voice/odigo/inbound/OdigoInboundCallConfigurationEditionPage.vue')
const GenesysConfiguration = () => import('@/admin/components/voice/genesys/GenesysConfiguration.vue')
const GenesysInboundCallConfigurationPage = () => import('@/admin/components/voice/genesys/inbound/GenesysInboundCallConfigurationPage.vue')
const GenesysOutboundCallConfigurationPage = () => import('@/admin/components/voice/genesys/outbound/GenesysOutboundCallConfigurationPage.vue')
const SitelCtiConfiguration = () => import('@/admin/components/voice/SitelCtiConfiguration.vue')
const BotEditionDyduPage = () => import('@/admin/components/bots/BotEditionDyduPage.vue')
const BotCreationPage = () => import('@/admin/components/bots/BotCreationPage.vue')
const BotListPage = () => import('@/admin/components/bots/BotListPage.vue')
const VersionPage = () => import('@/admin/components/settings/VersionPage.vue')
const SearchConfigLandingPage = () => import('@/admin/components/search/SearchConfigLandingPage.vue')
const SearchConfigCreationPage = () => import('@/admin/components/search/SearchConfigCreationPage.vue')
const SearchConfigEditionPage = () => import('@/admin/components/search/SearchConfigEditionPage.vue')
const ActivitiesListPage = () => import('@/admin/components/activities/ActivitiesListPage.vue')
const ActivitiesEditionPage = () => import('@/admin/components/activities/ActivitiesEditionPage.vue')
const ActivitiesCreationPage = () => import('@/admin/components/activities/ActivitiesCreationPage.vue')
const FoundeverGPTConfigurationPage = () => import('@/admin/components/settings/FoundeverGPTConfigurationPage.vue')
const CustomerSettingsPage = () => import('@/admin/components/settings/CustomerSettingsPage.vue')
const JobsLogsReport = () => import('@/admin/components/automation/JobsLogsReport.vue')

const VonageConfigurationLandingPage = () => import('@/admin/components/sms/vonage/configurations/VonageConfigurationLandingPage.vue')
const VonageConfigurationCreationPage = () => import('@/admin/components/sms/vonage/configurations/VonageConfigurationCreationPage.vue')
const VonageConfigurationEditPage = () => import('@/admin/components/sms/vonage/configurations/VonageConfigurationEditionPage.vue')
const VonageSmsSenderEditPage = () => import('@/admin/components/sms/vonage/smsNumbers/VonageSmsNumberEditionPage.vue')
const VonageSmsSenderCreationPage = () => import('@/admin/components/sms/vonage/smsNumbers/VonageSmsNumberCreationPage.vue')
const VonageWhatsAppAccountPage = () => import('@/admin/components/social/whatsapp/vonage/VonageWhatsAppAccountPage.vue')
const KnowledgeBasePage = () => import('@/admin/components/knowledge_base/KnowledgeBasePage.vue')
// listing
const ListLandingPageContent = () => import('@/admin/components/listing/ListLandingPageContent.vue')
const ListEditPageContent = () => import('@/admin/components/listing/ListEditPageContent.vue')

const customTypesListPage = () => import('@/admin/components/interactions/customTypes/CustomTypesListPage.vue')
const customTypesEditionPage = () => import('@/admin/components/interactions/customTypes/CustomTypesEditionPage.vue')
const customTypesCreationPage = () => import('@/admin/components/interactions/customTypes/CustomTypesCreationPage.vue')

// export
const ExportLandingPageContent = () => import('@/admin/components/export/ExportLandingPageContent.vue')
const ExportEditPageContent = () => import('@/admin/components/export/ExportEditPageContent.vue')

// settings
const WorkflowPage = () => import('@/admin/components/workflow/WorkflowPage.vue')

export default [
  {
    name: ADMIN_ROUTE_NAMES.ADMIN_ROOT,
    path: '/assist/admin',
    component: AdminView,
    beforeEnter: checkAccessRightsGuard,
    meta: { admin: true },
    children: [
      {
        path: 'rules',
        redirect: '/assist/admin/rules/list',
      },
      {
        path: 'products',
        redirect: '/assist/admin/products/0',
      },

      {
        path: 'bot',
        name: 'admin-bot-list',
        component: BotListPage,
        props: true,
      },
      {
        path: 'bot/dydu/edit/:botId(\\d+)',
        component: BotEditionDyduPage,
        name: 'admin-bot-edit',
        props: (route) => ({ botId: Number(route.params.botId) }),
      },
      {
        path: 'bot/create',
        name: 'admin-bot-create',
        component: BotCreationPage,
        props: true,
      },
      {
        path: 'activities',
        name: 'admin-activities-list',
        component: ActivitiesListPage,
        props: true,
      },
      {
        path: 'activities/edit/:activityId',
        component: ActivitiesEditionPage,
        name: 'admin-activities-edit',
        props: (route) => ({ activityId: route.params.activityId }),
      },
      {
        path: 'activities/create',
        name: 'admin-activities-create',
        component: ActivitiesCreationPage,
        props: true,
      },
      {
        path: 'reasons',
        name: 'admin-reasons-landing-page',
        component: ReasonsLandingPage,
      },
      {
        path: 'reasons/create/:actionType',
        name: 'admin-reason-create',
        component: ReasonsCreationPage,
        props: true,
      },
      {
        path: 'reasons/edit/:code',
        name: 'admin-reason-edit',
        component: ReasonsEditionPage,
        props: true,
      },
      {
        path: 'voice/axialys-configuration/',
        name: 'admin-axialys-configuration',
        component: AxialysConfiguration,
      },
      {
        path: 'voice/axialys-configuration/outbound/edit/:id',
        name: 'admin-axialys-outbound-configuration-edit',
        component: AxialysOutBoundCallConfigurationEditionPage,
        props: true,
      },
      {
        path: 'voice/axialys-configuration/outbound/create',
        name: 'admin-axialys-outbound-configuration-create',
        component: AxialysOutBoundCallConfigurationCreationPage,
      },
      {
        path: 'voice/axialys-configuration/inbound/edit/:id',
        name: 'admin-axialys-inbound-configuration-edit',
        component: AxialysInboundCallConfigurationEditionPage,
        props: true,
      },
      {
        path: 'voice/axialys-configuration/inbound/create',
        name: 'admin-axialys-inbound-configuration-create',
        component: AxialysInboundCallConfigurationCreationPage,
      },
      {
        path: 'voice/voicebot-configuration/',
        name: 'admin-voicebot-configuration',
        component: VoicebotConfiguration,
      },
      {
        path: 'voice/voicebot-configuration/inbound/edit/:id',
        name: 'admin-voicebot-inbound-configuration-edit',
        component: VoicebotInboundCallConfigurationEditionPage,
        props: true,
      },
      {
        path: 'voice/voicebot-configuration/inbound/create',
        name: 'admin-voicebot-inbound-configuration-create',
        component: VoicebotInboundCallConfigurationCreationPage,
      },
      {
        path: 'voice/odigo-configuration/',
        name: 'admin-odigo-configuration',
        component: OdigoConfiguration,
      },
      {
        path: 'voice/odigo-configuration/outbound/edit/:id',
        name: 'admin-odigo-outbound-configuration-edit',
        component: OdigoOutBoundCallConfigurationEditionPage,
        props: true,
      },
      {
        path: 'voice/odigo-configuration/outbound/create',
        name: 'admin-odigo-outbound-configuration-create',
        component: OdigoOutBoundCallConfigurationCreationPage,
      },
      {
        path: 'voice/odigo-configuration/inbound/edit/:id',
        name: 'admin-odigo-inbound-configuration-edit',
        component: OdigoInboundCallConfigurationEditionPage,
        props: true,
      },
      {
        path: 'voice/odigo-configuration/inbound/create',
        name: 'admin-odigo-inbound-configuration-create',
        component: OdigoInboundCallConfigurationCreationPage,
      },
      {
        path: 'voice/genesys-configuration/',
        name: 'admin-genesys-configuration',
        component: GenesysConfiguration,
      },
      {
        path: 'voice/genesys-configuration/inbound/edit/:id',
        name: 'admin-genesys-inbound-configuration-edit',
        component: GenesysInboundCallConfigurationPage,
        props: true,
      },
      {
        path: 'voice/genesys-configuration/inbound/create',
        name: 'admin-genesys-inbound-configuration-create',
        component: GenesysInboundCallConfigurationPage,
      },
      {
        path: 'voice/genesys-configuration/outbound/edit/:id',
        name: 'admin-genesys-outbound-configuration-edit',
        component: GenesysOutboundCallConfigurationPage,
        props: true,
      },
      {
        path: 'voice/genesys-configuration/outbound/create',
        name: 'admin-genesys-outbound-configuration-create',
        component: GenesysOutboundCallConfigurationPage,
      },
      {
        path: 'voice/sitel-cti-configuration/',
        component: SitelCtiConfiguration,
      },
      {
        path: 'vonage/configurations',
        name: 'admin-vonage-configuration-landing',
        component: VonageConfigurationLandingPage,
      },
      {
        path: 'vonage/configurations/create',
        name: 'admin-vonage-configuration-create',
        component: VonageConfigurationCreationPage,
      },
      {
        path: 'vonage/configurations/edit/:id',
        name: 'admin-vonage-configuration-edit',
        component: VonageConfigurationEditPage,
        props: true,
      },
      {
        path: 'sms/senders/vonage/edit/:id',
        name: 'admin-vonage-sms-sender-edit',
        component: VonageSmsSenderEditPage,
        props: true,
      },
      {
        path: 'sms/senders/vonage/create',
        name: 'admin-vonage-sms-sender-create',
        component: VonageSmsSenderCreationPage,
      },
      {
        path: 'social/whatsApp/vonage/edit/:id',
        name: 'admin-vonage-whatsapp-account-edit',
        component: VonageWhatsAppAccountPage,
        props: true,
      },
      {
        path: 'social/whatsApp/vonage/create',
        name: 'admin-vonage-whatsapp-account-create',
        component: VonageWhatsAppAccountPage,
      },
      {
        path: 'versions/',
        component: VersionPage,
      },
      {
        path: 'search',
        name: 'admin-search-landing-page',
        component: SearchConfigLandingPage,
      },
      {
        path: 'search/create',
        name: 'admin-search-create',
        component: SearchConfigCreationPage,
      },
      {
        path: 'search/edit/:configId',
        name: 'admin-search-edit',
        component: SearchConfigEditionPage,
        props: true,
      },
      {
        path: 'settings/customer/view',
        name: 'admin-customer-settings',
        component: CustomerSettingsPage,
      },
      {
        path: 'foundeverGPT',
        name: 'foundeverGPT-configuration',
        component: FoundeverGPTConfigurationPage,
      },
      {
        path: 'ticketExportDefinition/add',
        name: 'admin-export-tickets-create',
        component: ExportEditPageContent,
        props: () => ({
          fieldContext: FieldContextEnum.TICKET_EXPORT,
          exportType: FileDefinitionTypeEnum.EXPORT_TICKET,
          resource: ResourceEnum.TICKET,
        }),
      },
      {
        path: 'ticketExportDefinition/edit/:exportDefCode',
        name: 'admin-export-tickets-edit',
        component: ExportEditPageContent,
        props: (route) => ({
          exportDefCode: route.params?.exportDefCode,
          fieldContext: FieldContextEnum.TICKET_EXPORT,
          exportType: FileDefinitionTypeEnum.EXPORT_TICKET,
          resource: ResourceEnum.TICKET,
        }),
      },
      {
        path: 'ticketExportDefinition',
        name: 'admin-export-tickets-landing-page',
        component: ExportLandingPageContent,
        props: () => ({
          exportType: FileDefinitionTypeEnum.EXPORT_TICKET,
          resource: ResourceEnum.TICKET,
        }),
      },
      {
        path: 'customerExportDefinition/add',
        name: 'admin-export-customers-create',
        component: ExportEditPageContent,
        props: () => ({
          fieldContext: FieldContextEnum.CUSTOMER_EXPORT,
          exportType: FileDefinitionTypeEnum.EXPORT_CUSTOMER,
          resource: ResourceEnum.CUSTOMER,
        }),
      },
      {
        path: 'customerExportDefinition/edit/:exportDefCode',
        name: 'admin-export-customers-edit',
        component: ExportEditPageContent,
        props: (route) => ({
          exportDefCode: route.params?.exportDefCode,
          fieldContext: FieldContextEnum.CUSTOMER_EXPORT,
          exportType: FileDefinitionTypeEnum.EXPORT_CUSTOMER,
          resource: ResourceEnum.CUSTOMER,
        }),
      },
      {
        path: 'customerExportDefinition',
        name: 'admin-export-customers-landing-page',
        component: ExportLandingPageContent,
        props: () => ({
          exportType: FileDefinitionTypeEnum.EXPORT_CUSTOMER,
          resource: ResourceEnum.CUSTOMER,
        }),
      },
      {
        path: 'interactionExportDefinition/add',
        name: 'admin-export-interactions-create',
        component: ExportEditPageContent,
        props: () => ({
          fieldContext: FieldContextEnum.INTERACTION_EXPORT,
          exportType: FileDefinitionTypeEnum.EXPORT_INTERACTION,
          resource: ResourceEnum.INTERACTION,
        }),
      },
      {
        path: 'interactionExportDefinition/edit/:exportDefCode',
        name: 'admin-export-interactions-edit',
        component: ExportEditPageContent,
        props: (route) => ({
          exportDefCode: route.params?.exportDefCode,
          fieldContext: FieldContextEnum.INTERACTION_EXPORT,
          exportType: FileDefinitionTypeEnum.EXPORT_INTERACTION,
          resource: ResourceEnum.INTERACTION,
        }),
      },
      {
        path: 'interactionExportDefinition',
        name: 'admin-export-interactions-landing-page',
        component: ExportLandingPageContent,
        props: () => ({
          exportType: FileDefinitionTypeEnum.EXPORT_INTERACTION,
          resource: ResourceEnum.INTERACTION,
        }),
      },
      {
        path: 'tickets/list',
        name: 'admin-tickets-list-landing-page',
        component: ListLandingPageContent,
        props: () => ({
          screenType: ScreenTypeEnum.TICKETLIST,
          resource: ResourceEnum.TICKET,
        }),
      },
      {
        path: 'tickets/list/create',
        name: 'admin-tickets-list-create',
        component: ListEditPageContent,
        props: () => ({
          fieldContext: FieldContextEnum.TICKET_LIST,
          screenType: ScreenTypeEnum.TICKETLIST,
          resource: ResourceEnum.TICKET,
        }),
      },
      {
        path: 'tickets/list/edit/:screenCode',
        name: 'admin-tickets-list-edit',
        component: ListEditPageContent,
        props: (route) => ({
          screenCode: route.params?.screenCode,
          fieldContext: FieldContextEnum.TICKET_LIST,
          screenType: ScreenTypeEnum.TICKETLIST,
          resource: ResourceEnum.TICKET,
        }),
      },
      {
        path: 'customers/list',
        name: 'admin-customers-list-landing-page',
        component: ListLandingPageContent,
        props: () => ({
          screenType: ScreenTypeEnum.CLIENTLIST,
          resource: ResourceEnum.CUSTOMER,
        }),
      },
      {
        path: 'customers/list/create',
        name: 'admin-customers-list-create',
        component: ListEditPageContent,
        props: () => ({
          fieldContext: FieldContextEnum.CUSTOMER_LIST,
          screenType: ScreenTypeEnum.CLIENTLIST,
          resource: ResourceEnum.CUSTOMER,
        }),
      },
      {
        path: 'customers/list/edit/:screenCode',
        name: 'admin-customers-list-edit',
        component: ListEditPageContent,
        props: (route) => ({
          screenCode: route.params?.screenCode,
          fieldContext: FieldContextEnum.CUSTOMER_LIST,
          screenType: ScreenTypeEnum.CLIENTLIST,
          resource: ResourceEnum.CUSTOMER,
        }),
      },
      {
        path: 'interactions/list',
        name: 'admin-interactions-list-landing-page',
        component: ListLandingPageContent,
        props: () => ({
          screenType: ScreenTypeEnum.INTERACTIONLIST,
          resource: ResourceEnum.INTERACTION,
        }),
      },
      {
        path: 'interactions/list/create',
        name: 'admin-interactions-list-create',
        component: ListEditPageContent,
        props: () => ({
          fieldContext: FieldContextEnum.INTERACTION_LIST,
          screenType: ScreenTypeEnum.INTERACTIONLIST,
          resource: ResourceEnum.INTERACTION,
        }),
      },
      {
        path: 'interactions/list/edit/:screenCode',
        name: 'admin-interactions-list-edit',
        component: ListEditPageContent,
        props: (route) => ({
          screenCode: route.params?.screenCode,
          fieldContext: FieldContextEnum.INTERACTION_LIST,
          screenType: ScreenTypeEnum.INTERACTIONLIST,
          resource: ResourceEnum.INTERACTION,
        }),
      },
      {
        path: 'interactions/custom-types',
        name: 'admin-custom-types-list',
        component: customTypesListPage,
      },
      {
        path: 'interactions/custom-types/edit/:id',
        name: 'admin-custom-types-edit',
        component: customTypesEditionPage,
        props: true,
      },
      {
        path: 'interactions/custom-types/create',
        name: 'admin-custom-types-create',
        component: customTypesCreationPage,
      },
      {
        path: 'settings/kb/',
        name: 'knowledge-base',
        component: KnowledgeBasePage,
        beforeEnter: knowledgeBaseBeforeEnter,
      },
      {
        path: 'joblogs',
        name: 'admin-automation-jobslogs',
        component: JobsLogsReport,
      },
      {
        path: 'wkfwTransition/',
        name: 'admin-workflow',
        component: WorkflowPage,
      },
      {
        path: 'sms/senders/add/:id',
        name: 'admin-sms-sender-add',
        component: LegacyFrame,
        meta: {
          legacy: true,
          exclude: ['.*\\/save$'],
        },
      },
      {
        path: 'social/whatsApp/add/:id',
        name: 'admin-whatsapp-account-add',
        component: LegacyFrame,
        meta: {
          legacy: true,
          exclude: ['.*\\/save$'],
        },
      },
      {
        path: 'redirect/back/:foundeverFrom',
        name: 'redirect-back',
        component: RedirectBackHandlingPage,
        props: true,
        meta: {
          technical: true,
        },
      },
      {
        path: ':url+',
        component: LegacyFrame,
        meta: {
          legacy: true,
          exclude: ['.*\\/save$'],
        },
      },
    ],
  },
] as Array<RouteRecordRaw>
