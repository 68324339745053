/*
 * List of action menu type (related to actionType.js) used for:
 * - check if action is allowed
 */
export default Object.freeze({
  TRANSFER: 'TRANSFER',
  STACK: 'STACK',
  COMPLETE: 'COMPLETE',
  CHAT_TRANSFER: 'CHAT_TRANSFER',
  CHAT_REASSIGN: 'CHAT_REASSIGN',
  CHAT_RELEASE: 'CHAT_RELEASE',
})
