import { convertArrayToObject } from '@/common/helpers/arrayUtils'
import { setValueGenericWay } from '@/common/helpers/reflectionUtils'
import { findCaseActions } from '@/common/services/CaseActionService'
import { getBulkCases } from '@/common/services/CaseService'
import { getCustomers } from '@/common/services/CustomerService'
import { getInteractions } from '@/common/services/InteractionService'
import { type Case, type CaseData, CaseExpandEnum } from '@/common/types/case'
import { CaseIncludeEnum } from '@/common/types/case'
import type { CaseActionDTOV1 } from '@/common/types/caseAction'
import { type CustomFieldValueData, CustomFieldValueDataTypeEnum, EntityFieldCodeEnum } from '@/common/types/fields'
import { EntityEnum } from '@/common/types/general'
import { type Interaction, type InteractionDTOV4, InteractionIncludeEnum } from '@/common/types/interaction'
import { type UserDTOV2 } from '@/common/types/users'
import { type ColumnDefinition } from '@/search/types/resultViewConfig'
import { type EntitySearchable } from '@/search/types/search'
import { useUserStore } from '@/common/stores/userStore'
import { useTeamStore } from '@/common/stores/teamStore'
import { useSkillStore } from '@/common/stores/skillStore'
import { type TeamDTOV1 } from '@/common/types/teams'
import { type SkillDTOV2 } from '@/common/types/skills'

/**
 * Fetch one entity to get details
 * @param entitySelected Entity to fetch
 * @param id entity id
 * @param resultViewConfig listing configuration
 * @returns Promise for one entity
 */
export const fetchEntities = async (
  entitySelected: EntityEnum,
  ids: number[],
  columnsDisplayed: ColumnDefinition[],
): Promise<Array<EntitySearchable>> => {
  if (entitySelected === EntityEnum.TICKET) {
    return fetchCases(ids, columnsDisplayed)
  } else if (entitySelected === EntityEnum.CUSTOMER) {
    return getCustomers(ids)
  } else if (entitySelected === EntityEnum.INTERACTION) {
    return fetchInteractions(ids, columnsDisplayed)
  }
  return []
}

/**
 * FETCH CASES
 */

/**
 * Map of case fields added by an include
 */
const CaseFieldsAddedByIncludeMap = new Map<EntityFieldCodeEnum, CaseIncludeEnum>([
  [EntityFieldCodeEnum.HAS_ATTACHMENTS, CaseIncludeEnum.HAS_ATTACHMENTS],
  [EntityFieldCodeEnum.FIRST_ASSIGNEE, CaseIncludeEnum.ASSIGNEES],
  [EntityFieldCodeEnum.LAST_ASSIGNEE, CaseIncludeEnum.ASSIGNEES],
  [EntityFieldCodeEnum.CREATION_SCREEN, CaseIncludeEnum.CREATION_STATE],
  [EntityFieldCodeEnum.CREATION_CHANNEL, CaseIncludeEnum.CREATION_STATE],
  [EntityFieldCodeEnum.IMPORT_ID, CaseIncludeEnum.CREATION_STATE],
  [EntityFieldCodeEnum.IMPORT_DATE, CaseIncludeEnum.CREATION_STATE],
  [EntityFieldCodeEnum.FIRST_CLAIM_DATE, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.NB_REOPEN_ACTIONS, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_COMMENT, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_REOPEN_ACTION, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_ACTION_REASON_NOT_EMPTY, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_ACTION_TYPE, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_ACTION_TYPE_REASON_NOT_EMPTY, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_ACTION_REASON, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_EVENT_DATE, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_VALIDATION_ACTION_COMMENT, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_VALIDATION_ACTION_DATE, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_VALIDATION_ACTION_TYPE_NAME, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_VALIDATION_ACTION_USER, CaseIncludeEnum.KEY_ACTIONS_DATA],
  [EntityFieldCodeEnum.FIRST_INTERACTION_DIRECTION, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_INTERACTION_DATE, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
  [EntityFieldCodeEnum.LAST_INTERACTION_WITH, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
  [EntityFieldCodeEnum.FIRST_OUTBOUND_STATUS, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
  [EntityFieldCodeEnum.FIRST_OUTBOUND_TYPE, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
  [EntityFieldCodeEnum.FIRST_OUTBOUND_DATE, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
  [EntityFieldCodeEnum.FIRST_INBOUND_STATUS, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
  [EntityFieldCodeEnum.FIRST_INBOUND_TYPE, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
  [EntityFieldCodeEnum.FIRST_INBOUND_DATE, CaseIncludeEnum.KEY_INTERACTIONS_DATA],
])

type keyOfCase = keyof Case
type keyOfCaseData = keyof CaseData

/**
 * Case Data properties who matches with action data properties
 */
type keyOfCaseActionData = keyof CaseActionDTOV1
const CorrespondindCaseDataToActionDataMap = new Map<EntityFieldCodeEnum, [keyOfCaseData, keyOfCaseActionData]>([
  [EntityFieldCodeEnum.LAST_ACTION_TYPE, ['lastActionId', 'type']],
  [EntityFieldCodeEnum.LAST_ACTION_REASON, ['lastActionId', 'reasonCode']],
  [EntityFieldCodeEnum.LAST_EVENT_DATE, ['lastActionId', 'date']],
  [EntityFieldCodeEnum.LAST_ACTION_TYPE_REASON_NOT_EMPTY, ['lastActionIdWithReason', 'type']],
  [EntityFieldCodeEnum.LAST_ACTION_REASON_NOT_EMPTY, ['lastActionIdWithReason', 'reasonCode']],
  [EntityFieldCodeEnum.LAST_VALIDATION_ACTION_COMMENT, ['lastValidationActionId', 'comment']],
  [EntityFieldCodeEnum.LAST_VALIDATION_ACTION_DATE, ['lastValidationActionId', 'date']],
  [EntityFieldCodeEnum.LAST_VALIDATION_ACTION_TYPE_NAME, ['lastValidationActionId', 'type']],
  [EntityFieldCodeEnum.LAST_VALIDATION_ACTION_USER, ['lastValidationActionId', 'user']],
])

/**
 * Case Data properties who matches with interaction data properties
 */
type keyOfInteractionData = keyof InteractionDTOV4
const CorrespondindCaseDataToInteractionDataMap = new Map<EntityFieldCodeEnum, [keyOfCaseData, keyOfInteractionData]>([
  [EntityFieldCodeEnum.FIRST_OUTBOUND_STATUS, ['firstOutboundInteractionId', 'status']],
  [EntityFieldCodeEnum.FIRST_OUTBOUND_TYPE, ['firstOutboundInteractionId', 'type']],
  [EntityFieldCodeEnum.FIRST_OUTBOUND_DATE, ['firstOutboundInteractionId', 'interactionDate']],
  [EntityFieldCodeEnum.FIRST_INBOUND_STATUS, ['firstInboundInteractionId', 'status']],
  [EntityFieldCodeEnum.FIRST_INBOUND_TYPE, ['firstInboundInteractionId', 'type']],
  [EntityFieldCodeEnum.FIRST_INBOUND_DATE, ['firstInboundInteractionId', 'interactionDate']],
])

/**
 * user properties from case properties who match with assignee user properties
 */
type keyOfUserSummary = keyof UserDTOV2
const CorrespondindAssigneeToUserSummaryMap = new Map<EntityFieldCodeEnum, keyOfUserSummary>([
  [EntityFieldCodeEnum.ASSIGNEE_EXTERNAL_ID, 'externalId'],
  [EntityFieldCodeEnum.ASSIGNEE_PHONE, 'phone'],
  [EntityFieldCodeEnum.ASSIGNEE_USERGROUP_NAME, 'teamCode'],
])

/**
 * Case Data properties who matches with team properties
 */
type keyOfTeam = keyof TeamDTOV1
const CorrespondindTeamDataCaseToTeamMap = new Map<EntityFieldCodeEnum, [keyOfCaseData, keyOfTeam]>([
  [EntityFieldCodeEnum.ASSIGNEE_SITE, ['assignee', 'site']],
  [EntityFieldCodeEnum.CREATION_USER_SITE, ['creator', 'site']],
])

const fetchCases = async (ids: number[], columnsDisplayed: ColumnDefinition[]): Promise<Array<Case>> => {
  const {
    includes,
    expands,
    hasCustomerFields,
    hasSkillTargetProcessTimeField,
    keysActionsDataToFetch,
    propertyActionsDataToSetOnCase,
    keysInteractionsDataToFetch,
    propertyInteractionsDataToSetOnCase,
    propertyAssigneeToSetOnCase,
    propertyTeamDataToSetOnCase,
    propertyCustomFieldsToSetOnCase,
  } = prepareInformationsToFetchCaseAndFields(columnsDisplayed)

  const cases: CaseData[] = await getBulkCases(ids, [...includes], [...expands])

  const { customerCases, actionDataCases, interactionDataCases, caseAssigneeCreator } = getInformationsToFetchCasesFields(
    cases,
    keysActionsDataToFetch,
    keysInteractionsDataToFetch,
  )

  await Promise.all([
    fetchCasesCustomers(customerCases, hasCustomerFields),
    fetchCasesActionsData(actionDataCases, propertyActionsDataToSetOnCase),
    fetchCasesInteractionsData(interactionDataCases, propertyInteractionsDataToSetOnCase),
    fetchCasesUsersData(caseAssigneeCreator, propertyAssigneeToSetOnCase, propertyTeamDataToSetOnCase),
    fetchCasesSkillsData(hasSkillTargetProcessTimeField, cases),
    flattenCustomFields(expands, cases, propertyCustomFieldsToSetOnCase),
  ])

  return cases
}

const prepareInformationsToFetchCaseAndFields = (columnsDisplayed: ColumnDefinition[]) => {
  const includes: Set<CaseIncludeEnum> = new Set([CaseIncludeEnum.NB_INTERACTIONS])
  // For fetch customers
  let hasCustomerFields: boolean = false
  // For include KEY_ACTIONS_DATA
  const keysActionsDataToFetch: Set<keyOfCaseData> = new Set()
  const propertyActionsDataToSetOnCase = new Map<EntityFieldCodeEnum, keyOfCase>([])
  // For include KEY_INTERACTIONS_DATA
  const keysInteractionsDataToFetch: Set<keyOfCaseData> = new Set()
  const propertyInteractionsDataToSetOnCase = new Map<EntityFieldCodeEnum, keyOfCase>([])
  // For assignee data and team data properties
  const propertyAssigneeToSetOnCase = new Map<EntityFieldCodeEnum, keyOfCase>([])
  const propertyTeamDataToSetOnCase = new Map<EntityFieldCodeEnum, keyOfCase>([])
  // For fetch skills
  let hasSkillTargetProcessTimeField: boolean = false
  // For expand CUSTOM_FIELDS
  const expands: Set<CaseExpandEnum> = new Set()
  const propertyCustomFieldsToSetOnCase = new Map<string, string>([])

  for (const column of columnsDisplayed) {
    const nativeFieldCode = column.code as EntityFieldCodeEnum

    // For fetch customers
    if (column.entity === EntityEnum.CUSTOMER && column.code !== EntityFieldCodeEnum.CUSTOMER_ID) {
      hasCustomerFields = true
    }

    addInformationsAboutIncludes(
      column,
      nativeFieldCode,
      includes,
      keysActionsDataToFetch,
      propertyActionsDataToSetOnCase,
      keysInteractionsDataToFetch,
      propertyInteractionsDataToSetOnCase,
    )

    // For assignee data and team data properties
    if (CorrespondindAssigneeToUserSummaryMap.has(nativeFieldCode)) {
      propertyAssigneeToSetOnCase.set(nativeFieldCode, column.property as keyOfCase)
    } else if (CorrespondindTeamDataCaseToTeamMap.has(nativeFieldCode)) {
      propertyTeamDataToSetOnCase.set(nativeFieldCode, column.property as keyOfCase)
    }

    // For fetch skills
    if (nativeFieldCode === EntityFieldCodeEnum.SKILL_TARGET_PROCESSING_TIME) {
      hasSkillTargetProcessTimeField = true
    }

    // For expand CUSTOM_FIELDS
    if (column.custom) {
      expands.add(CaseExpandEnum.CUSTOM_FIELDS)
      propertyCustomFieldsToSetOnCase.set(column.code, column.property)
    }
  }

  return {
    includes,
    expands,
    hasCustomerFields,
    hasSkillTargetProcessTimeField,
    keysActionsDataToFetch,
    propertyActionsDataToSetOnCase,
    keysInteractionsDataToFetch,
    propertyInteractionsDataToSetOnCase,
    propertyAssigneeToSetOnCase,
    propertyTeamDataToSetOnCase,
    propertyCustomFieldsToSetOnCase,
  }
}

const addInformationsAboutIncludes = (
  column: ColumnDefinition,
  nativeFieldCode: EntityFieldCodeEnum,
  includes: Set<CaseIncludeEnum>,
  keysActionsDataToFetch: Set<keyOfCaseData>,
  propertyActionsDataToSetOnCase: Map<EntityFieldCodeEnum, keyOfCase>,
  keysInteractionsDataToFetch: Set<keyOfCaseData>,
  propertyInteractionsDataToSetOnCase: Map<EntityFieldCodeEnum, keyOfCase>,
) => {
  if (CaseFieldsAddedByIncludeMap.has(nativeFieldCode)) {
    const include: CaseIncludeEnum = CaseFieldsAddedByIncludeMap.get(nativeFieldCode)!

    includes.add(include)

    // For include KEY_ACTIONS_DATA
    if (CorrespondindCaseDataToActionDataMap.has(nativeFieldCode) && include === CaseIncludeEnum.KEY_ACTIONS_DATA) {
      keysActionsDataToFetch.add(CorrespondindCaseDataToActionDataMap.get(nativeFieldCode)![0])
      propertyActionsDataToSetOnCase.set(nativeFieldCode, column.property as keyOfCase)
    }
    // For include KEY_INTERACTIONS_DATA
    else if (CorrespondindCaseDataToInteractionDataMap.has(nativeFieldCode) && include === CaseIncludeEnum.KEY_INTERACTIONS_DATA) {
      keysInteractionsDataToFetch.add(CorrespondindCaseDataToInteractionDataMap.get(nativeFieldCode)![0])
      propertyInteractionsDataToSetOnCase.set(nativeFieldCode, column.property as keyOfCase)
    }
  }
}

const getInformationsToFetchCasesFields = (
  cases: CaseData[],
  keysActionsDataToFetch: Set<keyOfCaseData>,
  keysInteractionsDataToFetch: Set<keyOfCaseData>,
) => {
  return cases.reduce<{
    customerCases: Map<number, Case[]>
    actionDataCases: Map<number, { oneCase: Case; key: keyOfCaseData }>
    interactionDataCases: Map<number, { oneCase: Case; key: keyOfCaseData }>
    caseAssigneeCreator: Map<Case, { assignee?: string; creator?: string }>
  }>(
    (accumulator, caseData) => {
      if (caseData.customerId) {
        if (accumulator.customerCases.has(caseData.customerId)) accumulator.customerCases.get(caseData.customerId)?.push(caseData)
        else accumulator.customerCases.set(caseData.customerId, [caseData])
      }

      for (const key of keysActionsDataToFetch) {
        if (caseData[key]) {
          accumulator.actionDataCases.set(caseData[key] as number, { oneCase: caseData, key })
        }
      }

      for (const key of keysInteractionsDataToFetch) {
        if (caseData[key]) {
          accumulator.interactionDataCases.set(caseData[key] as number, { oneCase: caseData, key })
        }
      }

      accumulator.caseAssigneeCreator.set(caseData, { assignee: caseData.assignee, creator: caseData.creator })

      return accumulator
    },
    {
      customerCases: new Map<number, Case[]>(),
      actionDataCases: new Map<number, { oneCase: Case; key: keyOfCaseData }>(),
      interactionDataCases: new Map<number, { oneCase: Case; key: keyOfCaseData }>(),
      caseAssigneeCreator: new Map<Case, { assignee?: string; creator?: string }>(),
    },
  )
}

const fetchCasesCustomers = async (customerCases: Map<number, Case[]>, hasCustomerFields: boolean) => {
  if (customerCases.size > 0) {
    const customers = hasCustomerFields ? await getCustomers([...customerCases.keys()]) : [...customerCases.keys()].map((id) => ({ id }))

    customers.forEach((customer) => {
      const caseList = customerCases.get(customer.id) ?? []
      caseList.forEach((c) => {
        c.customer = customer
      })
    })
  }
}

const fetchCasesActionsData = async (
  actionDataCases: Map<number, { oneCase: Case; key: keyOfCaseData }>,
  propertyActionsDataToSetOnCase: Map<EntityFieldCodeEnum, keyOfCase>,
) => {
  const caseActionIds: Set<number> = new Set(actionDataCases.keys())
  if (caseActionIds.size > 0) {
    const caseActions: {
      [id: number]: CaseActionDTOV1
    } = convertArrayToObject(await findCaseActions([...caseActionIds]), 'id')

    actionDataCases.forEach(({ oneCase, key }, caseActionId) => {
      const caseAction = caseActions[caseActionId]
      if (caseAction) {
        propertyActionsDataToSetOnCase.forEach((property: keyOfCase, fieldCode: EntityFieldCodeEnum) => {
          if (key === CorrespondindCaseDataToActionDataMap.get(fieldCode)![0]) {
            const value = caseAction[CorrespondindCaseDataToActionDataMap.get(fieldCode)![1]]
            if (value !== undefined) {
              setValueGenericWay(oneCase, property, value)
            }
          }
        })
      }
    })
  }
}

const fetchCasesInteractionsData = async (
  interactionDataCases: Map<number, { oneCase: Case; key: keyOfCaseData }>,
  propertyInteractionsDataToSetOnCase: Map<EntityFieldCodeEnum, keyOfCase>,
) => {
  const interactionsIds: Set<number> = new Set(interactionDataCases.keys())
  if (interactionsIds.size > 0) {
    const interactions: {
      [id: number]: InteractionDTOV4
    } = convertArrayToObject(await getInteractions(undefined, [...interactionsIds], 0, interactionsIds.size), 'id')

    interactionDataCases.forEach(({ oneCase, key }, interactionId) => {
      const interaction = interactions[interactionId]
      if (interaction) {
        propertyInteractionsDataToSetOnCase.forEach((property: keyOfCase, fieldCode: EntityFieldCodeEnum) => {
          if (key === CorrespondindCaseDataToInteractionDataMap.get(fieldCode)![0]) {
            const value = interaction[CorrespondindCaseDataToInteractionDataMap.get(fieldCode)![1]]
            if (value !== undefined) {
              setValueGenericWay(oneCase, property, value)
            }
          }
        })
      }
    })
  }
}

const fetchCasesUsersData = async (
  caseAssigneeCreator: Map<Case, { assignee?: string; creator?: string }>,
  propertyAssigneeToSetOnCase: Map<EntityFieldCodeEnum, keyOfCase>,
  propertyTeamDataToSetOnCase: Map<EntityFieldCodeEnum, keyOfCase>,
) => {
  if (caseAssigneeCreator.size > 0 && (propertyAssigneeToSetOnCase.size > 0 || propertyTeamDataToSetOnCase.size > 0)) {
    const userStore = useUserStore()
    const teamStore = useTeamStore()

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const promises: Promise<any>[] = [userStore.fetch()]
    if (propertyTeamDataToSetOnCase.size > 0) {
      promises.push(teamStore.fetch())
    }
    await Promise.all(promises)

    const users: {
      [id: string]: UserDTOV2
    } = convertArrayToObject(userStore.users, 'login')

    const teams: {
      [code: UUID]: TeamDTOV1
    } = convertArrayToObject(teamStore.teams, 'code')

    caseAssigneeCreator.forEach(({ assignee, creator }, oneCase) => {
      const assigneeUser = assignee ? users[assignee] : undefined
      if (propertyAssigneeToSetOnCase.size > 0 && assigneeUser) {
        propertyAssigneeToSetOnCase.forEach((property: keyOfCase, fieldCode: EntityFieldCodeEnum) => {
          const value = assigneeUser[CorrespondindAssigneeToUserSummaryMap.get(fieldCode)!]
          if (value !== undefined) {
            setValueGenericWay(oneCase, property, value)
          }
        })
      }
      if (propertyTeamDataToSetOnCase.size > 0) {
        const creatorUser = creator ? users[creator] : undefined
        propertyTeamDataToSetOnCase.forEach((property: keyOfCase, fieldCode: EntityFieldCodeEnum) => {
          if ('assignee' === CorrespondindTeamDataCaseToTeamMap.get(fieldCode)![0] && assigneeUser) {
            setTeamFieldOnCase(teams, assigneeUser, fieldCode, oneCase, property)
          } else if ('creator' === CorrespondindTeamDataCaseToTeamMap.get(fieldCode)![0] && creatorUser) {
            setTeamFieldOnCase(teams, creatorUser, fieldCode, oneCase, property)
          }
        })
      }
    })
  }
}

const setTeamFieldOnCase = (
  teams: {
    [code: UUID]: TeamDTOV1
  },
  user: UserDTOV2,
  fieldCode: EntityFieldCodeEnum,
  oneCase: Case,
  property: keyOfCase,
) => {
  const team = teams[user.teamCode]
  const value = team[CorrespondindTeamDataCaseToTeamMap.get(fieldCode)![1]]
  if (value !== undefined) {
    setValueGenericWay(oneCase, property, value)
  }
}

const fetchCasesSkillsData = async (hasSkillTargetProcessTimeField: boolean, cases: Case[]) => {
  if (hasSkillTargetProcessTimeField) {
    const skillStore = useSkillStore()
    await skillStore.fetch()

    const skills: {
      [code: string]: SkillDTOV2
    } = convertArrayToObject(skillStore.skills, 'code')

    for (const oneCase of cases) {
      oneCase.skillTargetProcessingTime = skills[oneCase.currentSkillCode].targetProcessingTime
    }
  }
}

const flattenCustomFields = async (expands: Set<CaseExpandEnum>, cases: Case[], propertyCustomFieldsToSetOnCase: Map<string, string>) => {
  if (expands.has(CaseExpandEnum.CUSTOM_FIELDS)) {
    for (const oneCase of cases) {
      const customFieldValues = oneCase.customFields
      if (customFieldValues?.length) {
        oneCase.flatCustomFields = {}
        for (const customFieldValue of customFieldValues) {
          const property = propertyCustomFieldsToSetOnCase.get(customFieldValue.customFieldCode)
          setCustomFieldOnCase(oneCase.flatCustomFields, customFieldValue, property)
        }
      }
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setCustomFieldOnCase = (flatCustomFields: any, customFieldValue: CustomFieldValueData, property?: string) => {
  if (property) {
    if (
      customFieldValue.dataType === CustomFieldValueDataTypeEnum.CustomFieldValueString ||
      customFieldValue.dataType === CustomFieldValueDataTypeEnum.CustomFieldValueDate ||
      customFieldValue.dataType === CustomFieldValueDataTypeEnum.CustomFieldValueDateTime
    ) {
      flatCustomFields[property] = customFieldValue.value as string
    } else if (customFieldValue.dataType === CustomFieldValueDataTypeEnum.CustomFieldValueNumber) {
      flatCustomFields[property] = customFieldValue.value as number
    } else if (customFieldValue.dataType === CustomFieldValueDataTypeEnum.CustomFieldValueBoolean) {
      flatCustomFields[property] = customFieldValue.value as boolean
    } else if (customFieldValue.dataType === CustomFieldValueDataTypeEnum.CustomFieldValueOptions) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      flatCustomFields[property] = customFieldValue.value as Array<any>
    }
  }
}

/**
 * FETCH INTERACTIONS
 */

/**
 * Map of interaction fields added by an include
 */
const InteractionFieldsAddedByIncludeMap = new Map<EntityFieldCodeEnum, InteractionIncludeEnum>([
  [EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS, InteractionIncludeEnum.HAS_ATTACHMENTS],
])

const fetchInteractions = async (ids: number[], columnsDisplayed: ColumnDefinition[]): Promise<Array<Interaction>> => {
  const { includes, hasCaseFields } = prepareInformationsToFetchInteractionAndFields(columnsDisplayed)

  const interactions: Interaction[] = await getInteractions(undefined, ids, 0, ids.length, [...includes])

  const { caseInteractions } = getInformationsToFetchInteractionsFields(interactions)

  await Promise.all([fetchInteractionsCases(caseInteractions, hasCaseFields)])

  return interactions
}

const prepareInformationsToFetchInteractionAndFields = (columnsDisplayed: ColumnDefinition[]) => {
  const includes: Set<InteractionIncludeEnum> = new Set()
  // For fetch cases
  let hasCaseFields: boolean = false

  for (const column of columnsDisplayed) {
    const nativeFieldCode = column.code as EntityFieldCodeEnum

    // For fetch customers
    if (column.entity === EntityEnum.TICKET && column.code !== EntityFieldCodeEnum.TICKET_ID) {
      hasCaseFields = true
    }

    if (InteractionFieldsAddedByIncludeMap.has(nativeFieldCode)) {
      const include: InteractionIncludeEnum = InteractionFieldsAddedByIncludeMap.get(nativeFieldCode)!

      includes.add(include)
    }
  }

  return {
    includes,
    hasCaseFields,
  }
}

const getInformationsToFetchInteractionsFields = (interactions: Interaction[]) => {
  return interactions.reduce<{
    caseInteractions: Map<number, Interaction[]>
  }>(
    (accumulator, interactionData) => {
      if (interactionData.caseId) {
        if (accumulator.caseInteractions.has(interactionData.caseId)) accumulator.caseInteractions.get(interactionData.caseId)?.push(interactionData)
        else accumulator.caseInteractions.set(interactionData.caseId, [interactionData])
      }

      return accumulator
    },
    {
      caseInteractions: new Map<number, Interaction[]>(),
    },
  )
}

const fetchInteractionsCases = async (caseInteractions: Map<number, Interaction[]>, hasCaseFields: boolean) => {
  if (caseInteractions.size > 0) {
    const cases = hasCaseFields ? await getBulkCases([...caseInteractions.keys()]) : [...caseInteractions.keys()].map((id) => ({ id }))

    cases.forEach((oneCase) => {
      const interactionList = caseInteractions.get(oneCase.id) ?? []
      interactionList.forEach((i) => {
        i.case = oneCase as CaseData
      })
    })
  }
}
