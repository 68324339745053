import { ref } from 'vue'
import type { BreakDTOV1 } from '@/agent/types/break'
import { getBreaks } from '@/common/services/breakService'
import { defineStore } from 'pinia'

export const useBreaksStore = defineStore('breaks', () => {
  const breaks = ref<BreakDTOV1[]>([])

  const getBreakById = (id?: number | null) => {
    return breaks.value.find((b: BreakDTOV1) => b.userStateId === id)
  }

  const load = async () => {
    try {
      breaks.value = await getBreaks()
    } catch {
      // DO SOMETHING
    }
  }

  return {
    breaks,
    load,
    getBreakById,
  }
})
