import { Channels } from '@/agent/types/channels'

export default Object.freeze({
  SMS: { name: 'sms', id: Channels.SMS, code: 'SMS' },
  CHAT: { name: 'chat', id: Channels.CHAT, code: 'CHAT' },
  FACEBOOK: {
    name: 'facebook',
    id: Channels.FACEBOOK_POST,
    code: 'FACEBOOK_POST',
  },
  TWITTER: { name: 'twitter', id: Channels.TWITTER, code: 'TWITTER' },
  MESSENGER: {
    name: 'messenger',
    id: Channels.FB_MESSENGER,
    code: 'FB_MESSENGER',
  },
  TWITTERDM: { name: 'twitterDM', id: Channels.TWITTER_DM, code: 'TWITTER_DM' },
  WHATSAPP: { name: 'whatsapp', id: Channels.WHATSAPP, code: 'WHATSAPP' },
  INSTAGRAM: { name: 'instagram', id: Channels.INSTAGRAM, code: 'INSTAGRAM' },
  INSTAGRAMDM: {
    name: 'instagramDM',
    id: Channels.INSTAGRAM_DM,
    code: 'INSTAGRAM_DM',
  },
})
