export interface CtiService {
  call(phone: string): void

  release(): void
}

// Documentation for some data model here  : https://guide.axialys.com/guide/api-notifications-voice-management/

export interface CtiProvider {
  ref: string
  url: string
  connection: CtiConnectionTypeEnum
  ui: CtiUITypeEnum
}

export interface CtiAxialysEvents {
  ready: () => void | null
  notif_status: () => void | null
  notif_in: () => void | null
  notif_out: () => void | null
  notif_missed: () => void | null
  notif_endCall: () => void | null
  auth_invalid: () => void | null
}

export const enum CtiConnectionTypeEnum {
  'TOKEN' = 'token',
}

export const enum CtiUITypeEnum {
  'FRAMED' = 'framed',
}

export const enum CtiOdigoStatusEventName {
  'WRAP_UP' = 'WRAP_UP',
  'FROM_STATUSES' = 'FROM_STATUSES',
}

export const enum CtiOdigoGlobalStatus {
  'AVAILABLE' = 202,
  'PROCESSING' = 227,
  'DISCONNECTED' = 200,
  'ACTIVITY' = 204,
}

export const enum CtiOdigoVoiceStatus {
  'IN_ALERTING_AGENT' = '203',
  'OUT_ALERTING_RESOURCE' = '210',
  'IN_COMMUNICATION' = '206',
  'OUT_COMMUNICATION' = '216',
}

export const enum CtiOdigoAgentStatus {
  'AVAILABLE' = 'AVAILABLE',
  'DISCONNECTED' = 'DISCONNECTED',
  'ON_BREAK' = 'ON_BREAK',
  'ON_CALL' = 'ON_CALL',
  'NONE' = 'NONE',
  'PROCESSING' = 'PROCESSING',
}

// FYI :
// * This interface is commented as vars_ref namming is arguable
// * I decide the type by myself only LOV types are define in the doc
// * Maybe all attributes are optionnals
// * Another definition of vars_ref exists in the documentation but I bet we will not use it
export interface CtiAxialysInMessageVarRef {
  call_duration: number // call duration
  caller_id_num: string // inbound call number
  duration_in_queue: number // wainting duration inside the waiting queue
  event:
    | 'connect_call' // pick up phone and start connection
    | 'delete_call' // end of the phone call
    | 'insert_call' // inbound phone call waiting in the waiting queue
  id_call: string // call technical id
  id_operator: string // operator technical id
  id_queue: string // waiting queue technical id
  rec: string // This is documented as "appel enregistré" but I don't know yet what it is (an URL, a boolean, ... ???)
  statut: 'available' | 'ringing' | 'calling' | 'connected' | 'unavailable' // status of the operator 'statut' is french word for 'status'
  id_group?: string // group of the agent (use to determine which number was called)
  e164_in?: string // called number in e164 norme format
  id_session: string // a session id
}

// Same comment as for CtiAxialysInMessageVarRef
export interface CtiAxialysInMessageMessage {
  call_duration: number // call duration
  caller_id_num: string // inbound call number
  id_call: string // call technical id
  id_operator: string // operator technical id
  id_queue: string // waiting queue technical id
  begin_ts: number
  catchup: number
  id_client: string
  service: string
  p: number
  group: string
  numSVI: string
  status: 'available' | 'ringing' | 'calling' | 'connected' | 'unavailable' // status of the operator
}

export interface CtiAxialysInMessageArgs {
  status?: string
  message?: CtiAxialysInMessageMessage
  vars_ref?: CtiAxialysInMessageVarRef
}

export interface CtiAxialysOutMessageArgs {
  token?: string
  phone?: string
}

export interface CtiAxialysInMessage {
  action: string
  args: CtiAxialysInMessageArgs
  e164?: string
  ctxid?: string
}

export interface CtiAxialysOutMessage {
  action: string
  args: CtiAxialysOutMessageArgs
}

export interface CtiGenesysInMessage {
  type: GenesysInteractionType
  category: AuthenticationCategoryType | InteractionCategoryType | NotificationCategoryType | UserActionCategoryType
  interaction?: GenesysConversationInformation
  data: GenesysInData & GenesysRoutingStatus
}

interface GenesysInData {
  provider: string
  token?: string
  expires_in?: string
  url?: string
  status?: GenesysCloudStatus
  textToTranslate?: string
}

export interface CtiGenesysOutMessage {
  type: string
  category?: AuthenticationCategoryType
  data: GenesysOutData
}

interface GenesysOutData {
  provider: string
  clientId?: string
  token?: string
  embeddableURL?: string
  translatedText?: string
}

export interface InteractionContextData {
  CLIENT_DISPLAY_NUMBER: string
  CLIENT_SERVICE_NUMBER: string
  SKILL_ID: string
}

export interface CtiOdigoInMessage {
  eventName: string
  currentGlobalStatus: number | ''
  agentLogin: string
  serviceIvr: string
  folderNumber: string
  skillId: string
  agentSelectedSkills: string[]
  voiceTaskId: string
  voiceTasksStatusId: string
  voiceInteractionId: string
  voiceSkillId: string
  interactionContextData: InteractionContextData
}

export interface CtiOdigoOutMessage {
  eventName: string
  currentGlobalStatus: number | ''
  agentLogin: string
  serviceIvr: string
  folderNumber: string
  skillId: string
  agentSelectedSkills: string[]
  voiceTaskId: string
  voiceTasksStatusId: string
  voiceInteractionId: string
  voiceSkillId: string
  interactionContextData: InteractionContextData
}

export interface CtiOdigoAgentAndStatus {
  ctiIdentifier: string
  status: string
}

export interface CtiNewCall {
  ref: string | null
  callingNumber: string | null
  idGroup: string | null
  type: CtiInteractionTypeEnum
  customer: CtiCustomer
  agent?: CtiIAgent
}

export interface CtiCustomer {
  calledNumber: string | null
  userToUserInformation?: string
}

export interface CtiIAgent {
  ctiLogin?: string
  extension?: string
  desktopLogin?: string
}

export const enum CtiInteractionTypeEnum {
  'INBOUND' = 'inbound',
  'OUTBOUND' = 'outbound',
}

export interface CtiInteraction {
  ticket: number
}

export const enum CtiLandingPageEnum {
  'TICKET_HANDLING_SCREEN' = 'ticket-handling-screen',
  'SEARCH_CUSTOMER_SCREEN' = 'search-customer-screen',
}

export const enum CtiStatus {
  'DISCONNECTED' = 'unconnected',
  'AVAILABLE' = 'available',
  'ON_BREAK' = 'on-break',
  'ON_CALL' = 'on-call',
}

export const enum CtiStatusColor {
  'black' = '#3a4b4e',
  'green' = '#00aba5',
  'orange' = '#b25e26',
  'blue' = '#5e9ce9',
}

export const ctiStatusColorMap = new Map<CtiStatus, CtiStatusColor>([
  [CtiStatus.DISCONNECTED, CtiStatusColor.black],
  [CtiStatus.AVAILABLE, CtiStatusColor.green],
  [CtiStatus.ON_BREAK, CtiStatusColor.orange],
  [CtiStatus.ON_CALL, CtiStatusColor.blue],
])

export const enum CtiEventSource {
  'Manual' = 'MANUAL',
  'Automatic' = 'AUTOMATIC',
}

export type CallParams = {
  ani?: string
  dnis?: string
  ucid?: string
  extension?: string
  userLogin?: string
  pcLogin?: string
  uui?: string
  parentInteractionId?: string
}

export const enum GenesysInteractionType {
  INTERACTION = 'Interaction',
  NOTIFICATION = 'Notification',
  USER_ACTION = 'UserAction',
  AUTHORIZATION = 'Authorization',
}

export type AuthenticationCategoryType = 'initialized' | 'connect' | 'connected' | 'redirect' | 'initTranslation'

export type InteractionCategoryType =
  | 'add'
  | 'change'
  | 'connect'
  | 'disconnect'
  | 'acw'
  | 'deallocate'
  | 'callbackPlaced'
  | 'callbackCallEnded'
  | 'blindTransfer'
  | 'consultTransfer'
  | 'completeConsultTransfer'
export type NotificationCategoryType = 'interactionSelection' | 'chatUpdate' | 'interactionWindow' | 'conversationTranscription' | 'messageUpdate'
export type UserActionCategoryType = 'login' | 'logout' | 'station' | 'status' | 'modal' | 'routingStatus' | 'view'

export interface GenesysConversationInformation {
  // Descriptions of attributes are available in Genesys official documentation: Condensed conversation information
  ani: string
  attributes: Record<string, string> // customInteractionAttributes in framework.js -> config
  calledNumber: string
  connectedTime: string
  dialerContactId: string
  dialerContactListId: string
  dialerCampaignId: string
  direction: string
  displayAddress: string
  disposition: string
  dispositionDurationSeconds: number
  emailSubject: string
  endTime: string
  flagged: boolean
  hasJourneyContext: boolean
  id: string
  interactionDurationSeconds: number
  isConnected: boolean
  isDisconnected: boolean
  isCallback: boolean
  isChat: boolean
  isCoBrowsing: boolean
  isDialer: boolean
  isDialerPreview: boolean
  isEmail: boolean
  isInternal: boolean
  isMessage: boolean
  isSharingScreen: boolean
  isThirdPartyEmail: boolean
  isVoicemail: boolean
  messageType: string
  name: string
  phone: string
  queueName: string
  remoteName: string
  securePause: boolean
  startTime: string
  state: string
  socialUserName: string
  totalAcdDurationSeconds: number
  totalIvrDurationSeconds: number
  uuiData: string
  voicemailId: string
}

export const enum GenesysRoutingStatus {
  'OFF_QUEUE' = 'OFF_QUEUE',
  'IDLE' = 'IDLE',
  'INTERACTING' = 'INTERACTING',
  'COMMUNICATING' = 'COMMUNICATING',
  'NOT_RESPONDING' = 'NOT_RESPONDING',
  'UNAVAILABLE' = 'UNAVAILABLE',
}

export const enum GenesysCloudStatus {
  'ON_QUEUE' = 'ON_QUEUE',
  'TRAINING' = 'TRAINING',
  'BREAK' = 'BREAK',
  'BUSY' = 'BUSY',
  'MEETING' = 'MEETING',
  'MEAL' = 'MEAL',
  'OUT_OF_OFFICE' = 'OUT_OF_OFFICE',
  'AWAY' = 'AWAY',
  'AVAILABLE' = 'AVAILABLE',
}

export type GenesysStatus = GenesysRoutingStatus | GenesysCloudStatus
export type GenesysAgentDTOV2 = {
  id?: UUID
  name: string
  selfUri: string
  state: GenesysActivityState
  skills: GenesysAgentSkill[]
  username?: string
  version?: number
  acdAutoAnswer?: boolean
  addresses?: Array<{
    address?: string
    display?: string
    mediaType?: GenesysMediaType
    type?: GenesysContactType
    extension?: string
    countryCode?: string
    integration?: string
  }>
  division: {
    id: UUID
    name: string
    selfUri: string
  }
  email: string
  primaryContactInfo: Array<{
    address: string
    mediaType?: GenesysMediaType
    type?: GenesysContactType
  }>
}
export type GenesysAgentSkill = {
  id: string
  name: string
  proficiency?: number
  selfUri?: string
  skillUri?: string
  state?: GenesysActivityState
}

type GenesysActivityState = 'active' | 'inactive' | 'deleted'
type GenesysMediaType = 'PHONE' | 'EMAIL' | 'SMS'
type GenesysContactType = 'PRIMARY' | 'WORK' | 'WORK2' | 'WORK3' | 'WORK4' | 'HOME' | 'MOBILE' | 'MAIN' | 'OTHER'
