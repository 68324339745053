import { type Customer } from '@/common/types/customer'
import { type CaseChannelEnum } from '@/common/types/channel'
import { type InteractionStatusEnum, type InteractionTypeEnum, type InteractionDirectionEnum } from '@/common/types/interaction'
import { type CustomFieldValueData } from '@/common/types/fields'

// get cases v1
export type CaseDataV1 = {
  id: number
  comments?: string
  createdDate: string
  initialSkill: string
  status: CaseStatusEnum
  ref?: string
  typology?: string
  emergency?: string
  priority: CasePriorityEnum
  dueDate: string
  currentSkill: string
  assigneeUser?: string
  firstClaimDate?: string
  closingDate?: string
  lastEventDate?: string
  wakeupDate?: string
  lastActionReason?: string
  lastActionComment?: string
  skillTargetProcessingTime?: number
  lastAssignee?: string
  minPushDate?: string
  lastInteractionWith?: string
  assigneeUserGroupSiteName?: string
  creationUserGroupSiteName?: string
  creationImportId?: number
  creationImportDate?: string
  numberOfReopenActions?: number
  lastReopenActionDate?: string
  parent?: number
  creationChannel: CaseChannelEnum
  currentChannel: CaseChannelEnum
  lastInteractionDateIn?: string
  userGroup?: string
  creationScreen?: string
  assigneeUserExternalId?: string
  assigneeUserPhone?: string
  initialSkillCode: string
  currentSkillCode: string
  firstAssignee?: string
  product?: string
  lastActionReasonNotEmpty?: string
  lastActionTypeReasonNotEmpty?: string
  lastActionType?: string
  assigneeUserGroupName?: string
  lastValidationActionDate?: string
  lastValidationActionUser?: string
  lastValidationActionType?: string
  lastValidationActionComment?: string
  firstInteractionDirection?: string
  firstInboundDate?: string
  firstOutboundDate?: string
  firstInboundType?: string
  firstOutboundType?: string
  firstInboundStatus?: string
  firstOutboundStatus?: string
  assigneeUserLogin?: string
  dueDateAlert?: string
  customerId?: number
  hasNonAnsweredInteractions: boolean
  hasAttachments?: boolean
}

// Bulk fetch V1
export type CaseData = {
  // basic fields without include or expand
  id: number
  currentChannelCode: CaseChannelEnum
  creator?: string
  status: CaseStatusEnum
  currentSkillCode: string
  dueDateAlert?: string
  wakeupDate?: string
  emergencyCode?: CaseEmergencyEnum
  closingDate?: string
  externalReference?: string
  parentId?: number
  description?: string
  priorityCode: CasePriorityEnum
  contactDriverCode?: string
  teamCode?: UUID
  customerId?: number
  productCode?: string
  assignee?: string
  dueDate: string
  creationDate: string
  minPushDate?: string
  hasNonAnsweredInteractions: boolean
  // include CREATION_STATE
  creationChannelCode?: CaseChannelEnum
  screenCode?: UUID
  initialDueDate?: string
  importId?: number
  importDate?: string
  initialSkillCode?: string
  // include HAS_ATTACHMENTS
  hasAttachments?: boolean
  // include ASSIGNEES
  firstAssignee?: string
  lastAssignee?: string
  // include KEY_ACTIONS_DATA
  firstTakeonDate?: string
  lastActionComment?: string
  lastReopenActionDate?: string
  numberOfReopenActions?: number
  lastActionIdWithReason?: number
  lastActionId?: number
  lastValidationActionId?: number
  // include KEY_INTERACTIONS_DATA
  firstInteractionDirection?: InteractionDirectionEnum
  lastInboundInteractionDate?: string
  lastInteractionWith?: string
  firstInboundInteractionId?: number
  firstOutboundInteractionId?: number
  // include NB_INTERACTIONS
  numberOfInteractions?: number
  // expand CUSTOM_FIELDS
  customFields?: CustomFieldValueData[]
}

export type Case = CaseData & {
  // depends of include KEY_ACTIONS_DATA
  lastActionReasonNotEmpty?: string
  lastActionReason?: string
  lastActionType?: TicketActionTypeEnum
  lastActionTypeReasonNotEmpty?: TicketActionTypeEnum
  lastEventDate?: string
  lastValidationActionDate?: string
  lastValidationActionUser?: string
  lastValidationActionType?: TicketActionTypeEnum
  lastValidationActionComment?: string
  // depends of include KEY_INTERACTIONS_DATA
  firstInboundDate?: string
  firstInboundStatus?: InteractionStatusEnum
  firstInboundType?: InteractionTypeEnum
  firstOutboundDate?: string
  firstOutboundStatus?: InteractionStatusEnum
  firstOutboundType?: InteractionTypeEnum
  // by fetching /api/v2/users
  assigneeUserExternalId?: string
  assigneeUserPhone?: string
  assigneeTeamCode?: UUID
  // by fetching /api/v2/users then /api/v1/teams
  assigneeUserGroupSiteName?: string
  creationUserGroupSiteName?: string
  // by fetching /api/v2/skills with include PRODUCTIVITY
  skillTargetProcessingTime?: number
  // by fetching /api/v1/customers
  customer?: Customer
  // depends of expand CUSTOM_FIELDS
  flatCustomFields?: object
}

export enum CaseIncludeEnum {
  HAS_ATTACHMENTS = 'HAS_ATTACHMENTS',
  ASSIGNEES = 'ASSIGNEES',
  CREATION_STATE = 'CREATION_STATE',
  KEY_ACTIONS_DATA = 'KEY_ACTIONS_DATA',
  KEY_INTERACTIONS_DATA = 'KEY_INTERACTIONS_DATA',
  NB_INTERACTIONS = 'NB_INTERACTIONS',
}

export enum CaseExpandEnum {
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
}

export enum CaseStatusEnum {
  TODO = 'TODO',
  RESERVED = 'RESERVED',
  INPROGRESS = 'INPROGRESS',
  PENDING = 'PENDING',
  TOBEVALIDATED = 'TOBEVALIDATED',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  START = 'START',
}

export enum CaseStatusTypeEnum {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}

export const mapCaseStatusType: Record<CaseStatusTypeEnum, Array<CaseStatusEnum>> = {
  OPENED: [CaseStatusEnum.TODO, CaseStatusEnum.RESERVED, CaseStatusEnum.INPROGRESS, CaseStatusEnum.PENDING, CaseStatusEnum.TOBEVALIDATED],
  CLOSED: [CaseStatusEnum.COMPLETED, CaseStatusEnum.CANCELED],
}

export const enum CasePriorityEnum {
  VERYLOW = 'VERYLOW',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERYHIGH = 'VERYHIGH',
}

export const enum CaseEmergencyEnum {
  VERYLOW = 'VERYLOW',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  VERYHIGH = 'VERYHIGH',
}

export const enum WorklogItemEnum {
  HANDLE = 'HANDLE',
  CONSULT = 'CONSULT',
  CREATE = 'CREATE',
}

export const enum UserStateEnum {
  CONNECTED = 'CONNECTED',
  WORKING = 'WORKING',
  CONSULTING = 'CONSULTING',
  CREATING = 'CREATING',
}

export interface WorklogItem {
  beginDate: string
  duration: number
  id: number
  skill: string
  case: number
  type: WorklogItemEnum
  user: number
}

export interface WorklogTooltipItem {
  skill: string
  duration: number
  userState: string
}

export type CaseChannel = {
  code: CaseChannelEnum
  active: boolean
  displayOrder: number
}

export type Emergency = {
  code: CaseEmergencyEnum
  displayOrder: number
  active: boolean
  backgroundColor: string
}

export type Priority = {
  code: CasePriorityEnum
  displayOrder: number
  active: boolean
  default: boolean
}

export type MailboxDTOV1 = {
  id: number
  name: string
  emailAddress: string
  active: boolean
  spaceCode: UUID
}

export const enum TicketActionTypeEnum {
  ABANDON_HELP_REQUEST = 'ABANDON_HELP_REQUEST',
  ADD_ATTACHMENT = 'ADD_ATTACHMENT',
  APPROVE = 'APPROVE',
  ASKFORVALIDATION = 'ASKFORVALIDATION',
  ASSIGN = 'ASSIGN',
  ASSIGN_HELP_REQUEST = 'ASSIGN_HELP_REQUEST',
  ATTACHPARENT = 'ATTACHPARENT',
  CANCEL_ESCALATE = 'CANCEL_ESCALATE',
  CANCEL_HELP_REQUEST = 'CANCEL_HELP_REQUEST',
  CHAT_ADD_IN_QUEUING = 'CHAT_ADD_IN_QUEUING',
  CHAT_BOT_ESCALATE = 'CHAT_BOT_ESCALATE',
  CHAT_CLOSE = 'CHAT_CLOSE',
  CHAT_REASSIGN = 'CHAT_REASSIGN',
  CHAT_RELEASE = 'CHAT_RELEASE',
  CHAT_TRANSFER = 'CHAT_TRANSFER',
  CLOSE_HELP_REQUEST = 'CLOSE_HELP_REQUEST',
  COMMENT = 'COMMENT',
  COMPLETE = 'COMPLETE',
  COMPLETEONCREATION = 'COMPLETEONCREATION',
  COMPLETEONVALIDATION = 'COMPLETEONVALIDATION',
  CONVERT_TO_SMS = 'CONVERT_TO_SMS',
  CONVERT_TO_WHATSAPP = 'CONVERT_TO_WHATSAPP',
  CREATE_HELP_REQUEST = 'CREATE_HELP_REQUEST',
  CREATION = 'CREATION',
  DELETE = 'DELETE',
  DELETE_ATTACHMENT = 'DELETE_ATTACHMENT',
  DISAPPROVE = 'DISAPPROVE',
  ESCALATE = 'ESCALATE',
  HANDLE_INTERACTIONS = 'HANDLE_INTERACTIONS',
  IDENTIFIED_BY_IMPORT = 'IDENTIFIED_BY_IMPORT',
  MOVE_TICKET_CUSTOMER = 'MOVE_TICKET_CUSTOMER',
  PUTBACKINPOOL = 'PUTBACKINPOOL',
  REASSIGN = 'REASSIGN',
  REOPEN = 'REOPEN',
  STACK = 'STACK',
  STACKANDPUTBACKINPOOL = 'STACKANDPUTBACKINPOOL',
  STACKANDREASSIGN = 'STACKANDREASSIGN',
  STACKANDTRANSFER = 'STACKANDTRANSFER',
  TAKEON = 'TAKEON',
  TRANSFER = 'TRANSFER',
  UNDOVALIDATION = 'UNDOVALIDATION',
  UPDATEDUEDATE = 'UPDATEDUEDATE',
  UPDATEVALUES = 'UPDATEVALUES',
  WAKEUP = 'WAKEUP',
}

export type TicketActionType = keyof typeof TicketActionTypeEnum
