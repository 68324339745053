// https://stackoverflow.com/a/71760032/4568927

import cloneDeep from 'lodash/cloneDeep'
import { type Store } from 'pinia'

export function resetStore({ store }: { store: Store }) {
  const initialState = cloneDeep(store.$state)
  store.$reset = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store.$patch(($state: any) => {
      Object.assign($state, cloneDeep(initialState))
    })
  }
}
