import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { filterByType, groupByDay, filterNoDraft } from '@/engage/helper/interactions'
import _orderBy from 'lodash/orderBy'
import { publish } from '@/common/services/eventBusService'
import { INTERACTION_TYPE_STATUS, INTERACTION_TYPE } from '@/engage/constants'
import Api from '@/engage/plugins/api'
import dayjs from 'dayjs'
import type {
  LegacyEngageInteraction,
  LegacyEngageInteractionDraftMailAttributes,
  LegacyEngageInteractionTypeCodeValues,
} from '@/engage/types/interactions'
import type { NewInteractionValues } from '@/engage/types/newInteractions'

export const useInteractionsStore = defineStore('interactions', () => {
  const interactions = ref<LegacyEngageInteraction[]>([])
  const displayedInteractionsIds = ref<number[]>([])
  const firstInteraction = ref<LegacyEngageInteraction | null>(null)
  const firstInteractionsVisibility = ref<Record<number, { isVisible: boolean }>>({})
  const interactionSelectedList = ref<number[]>([])
  const newInteraction = ref<NewInteractionValues | null>(null)
  const newInteractionObject = ref<LegacyEngageInteractionDraftMailAttributes | null>(null)
  const preventExitInteraction = ref(false)
  const preventExitInteractionCb = ref<(() => void) | null>(null)
  const activeInteraction = ref<number | null>(null)

  const interactionsByDate = computed(() => {
    return (typesAllowed: LegacyEngageInteractionTypeCodeValues[] = []) => {
      const interactionsAllowed = filterByType(interactions.value, typesAllowed)
      const noDraft = filterNoDraft(interactionsAllowed)
      return groupByDay(noDraft)
    }
  })
  const interactionsDrafts = computed(() => {
    return interactions.value.filter((interaction) => interaction.type.status === INTERACTION_TYPE_STATUS.DRAFT)
  })
  const isInteractionSelected = computed(() => {
    return (interactionId: number) => interactionSelectedList.value.indexOf(interactionId) !== -1
  })
  const firstInteractionVisibility = computed(() => {
    return (ticketId: number) => {
      return typeof firstInteractionsVisibility.value[ticketId] !== 'undefined' ? firstInteractionsVisibility.value[ticketId].isVisible : true
    }
  })
  const findInteractionById = computed(() => {
    return (id: number) => {
      return interactions.value.find((o) => o.id === id)
    }
  })
  const isNewInteraction = computed(() => {
    return (typeChannel: string) => {
      return !!([INTERACTION_TYPE.CALL, INTERACTION_TYPE.EMAIL, INTERACTION_TYPE.SMS] as string[]).includes(typeChannel)
    }
  })
  const isSurvey = computed(() => {
    return (interaction: LegacyEngageInteraction) => {
      return interaction?.attributes?.type === INTERACTION_TYPE.SURVEY
    }
  })
  const lastInteraction = computed(() => {
    if (!interactions.value.length) {
      return null
    }

    return _orderBy(interactions.value, 'id', 'asc').slice(-1)[0]
  })

  // mutations
  const setAddMultipleSelection = (interactionId: number) => {
    const index = interactionSelectedList.value.indexOf(interactionId)
    if (index === -1) {
      interactionSelectedList.value.push(interactionId)
    }
  }
  const setRemoveMultipleSelection = (interactionId: number) => {
    const index = interactionSelectedList.value.indexOf(interactionId)
    if (index !== -1) {
      interactionSelectedList.value.splice(index, 1)
    }
  }
  const setAddMultipleByBatchSelection = (interactions: number[]) => {
    interactionSelectedList.value = interactions
  }
  const resetSelection = () => {
    interactionSelectedList.value = []
  }
  const setNewInteraction = ({ type, interaction }: { type: NewInteractionValues; interaction?: LegacyEngageInteractionDraftMailAttributes }) => {
    newInteractionObject.value = interaction || null
    newInteraction.value = type
  }
  const resetNewInteraction = () => {
    newInteraction.value = null
    newInteractionObject.value = null
  }
  const setFirstInteractions = (interaction: LegacyEngageInteraction) => {
    firstInteraction.value = { ...interaction }
  }
  const resetInteractions = () => {
    interactions.value = []
    firstInteraction.value = null
  }
  const updateFirstInteractionVisibility = (ticketId: number) => {
    const interaction = firstInteractionsVisibility.value[ticketId]
    firstInteractionsVisibility.value[ticketId] = {
      isVisible: typeof interaction === 'undefined' ? false : !interaction.isVisible,
    }
  }
  const removeFirstInteractionVisibility = (ticketId: number) => {
    if (typeof firstInteractionsVisibility.value[ticketId] !== 'undefined') {
      delete firstInteractionsVisibility.value[ticketId]
    }
  }
  const updateInteractionById = (id: number, data: LegacyEngageInteraction) => {
    const index = interactions.value.findIndex((o) => o.id === id)

    if (~index) {
      interactions.value.splice(index, 1, data)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateInteractionProperty = ({ id, key, value }: { id: number; key: string; value: any }) => {
    const index = interactions.value.findIndex((o) => o.id === id)

    if (~index) {
      interactions.value[index] = {
        ...interactions.value[index],
        [key]: value,
      }
    }
  }
  /**
   * Remove interaction from store list by interactionId
   * On first level, not deeper in children array
   */
  const removeInteraction = (id: number) => {
    interactions.value = interactions.value.filter((item) => item.id !== id)
  }
  const addDisplayedInteraction = (id: number) => {
    if (!displayedInteractionsIds.value.includes(id)) displayedInteractionsIds.value.push(id)
  }
  const removeDisplayedInteraction = (id: number) => {
    displayedInteractionsIds.value = displayedInteractionsIds.value.filter((item) => item !== id)
  }

  // actions
  /**
   * Stock prevent exit interaction state and function delayed
   * Manage closing alert saving delayed actions's args
   */
  const preventClose = ({ open, cb = null }: { open: boolean; cb?: (() => void) | null }) => {
    if (open === false) {
      publish('innso:break-resume')
    }
    preventExitInteraction.value = open
    preventExitInteractionCb.value = cb
  }
  /**
   * Remove new interaction and execute delayed action if needed
   */
  const cancelNewInteraction = () => {
    resetNewInteraction()

    if (preventExitInteractionCb.value) {
      preventExitInteractionCb.value()
    }
    _resetPreventExit()
  }
  const setInteractions = (list: LegacyEngageInteraction[]) => {
    // Deep copy due to assignement interactionParent.children.open = open
    // inside InteractionListPublicMixin.js
    interactions.value = JSON.parse(JSON.stringify(list))
  }
  const addInteractions = async (list: LegacyEngageInteraction[]) => {
    setInteractions(interactions.value.concat(list))
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateInteractionPolarity = ({ id, value }: { id: number; value: any }) => {
    updateInteractionProperty({
      id,
      key: 'polaritySentiment',
      value,
    })
  }
  const fetchLastInteractions = async () => {
    if (!lastInteraction.value) {
      return
    }

    const lastInteractionCreationDate = dayjs(lastInteraction.value.creationDate).valueOf()

    const interactions = await Api.interaction.getLastInteractions(lastInteractionCreationDate)

    const list = Array.isArray(interactions) ? interactions : [interactions]

    if (list.length) {
      await addInteractions(list)
    }
  }
  const activateInteraction = ({ interactionId }: { interactionId: number }) => {
    activeInteraction.value = interactionId
  }
  const resetActiveInteraction = () => {
    activeInteraction.value = null
  }

  // internals
  const _resetPreventExit = () => {
    preventExitInteraction.value = false
    preventExitInteractionCb.value = null
  }

  return {
    interactions,
    displayedInteractionsIds,
    firstInteraction,
    firstInteractionsVisibility,
    preventExitInteractionCb,
    interactionSelectedList,
    newInteraction,
    newInteractionObject,
    preventExitInteraction,
    activeInteraction,
    interactionsByDate,
    interactionsDrafts,
    isInteractionSelected,
    firstInteractionVisibility,
    findInteractionById,
    isNewInteraction,
    isSurvey,
    lastInteraction,
    setAddMultipleSelection,
    setRemoveMultipleSelection,
    setAddMultipleByBatchSelection,
    resetSelection,
    setNewInteraction,
    resetNewInteraction,
    setFirstInteractions,
    setInteractions,
    resetInteractions,
    updateFirstInteractionVisibility,
    removeFirstInteractionVisibility,
    removeInteraction,
    addDisplayedInteraction,
    removeDisplayedInteraction,
    cancelNewInteraction,
    updateInteractionPolarity,
    fetchLastInteractions,
    activateInteraction,
    resetActiveInteraction,
    preventClose,
    updateInteractionProperty,
    addInteractions,
    updateInteractionById,
  }
})
