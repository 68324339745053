import type { GmailAuthenticationParameters } from '@/admin/types/gmailAuthentication'

export enum SseContext {
  ASSIST_AGENT = 'ASSIST_AGENT',
  ENGAGE = 'ENGAGE',
  COMMAND_CENTER = 'COMMAND_CENTER',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventSseRegister = Partial<Record<SseEventType, (event: MessageEvent) => any>>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SseMessageAttributes = Record<string, any>
type SseMessageSchemaField<T> = T
export interface SseMessageSchema {
  action?: SseMessageSchemaField<string>
  data: SseMessageSchemaField<unknown>
  messageAttributes?: SseMessageAttributes
}
export type SseEventsSchema = Record<SseEventType, SseMessageSchema>
export type SseParseMessageFn = <T extends keyof SseEventsSchema, U extends SseEventsSchema>(event: MessageEvent) => U[T] | void

export enum SseEventType {
  // ASSIST AGENT
  SCREEN_POP = 'SCREEN_POP',
  GMAIL_AUTHENTICATION = 'GMAIL_AUTHENTICATION',

  // ENGAGE
  EG_NEW = 'EG_NEW',
  EG_UPDATE = 'EG_UPDATE',
  EG_REMOVE = 'EG_REMOVE',
  EG_CHAT_CONTEXT_CHANGED = 'EG_CHAT_CONTEXT_CHANGED',
  EG_AGENT_UPDATE = 'EG_AGENT_UPDATE',
  EG_HELP_REQUEST_UPDATE = 'EG_HELP_REQUEST_UPDATE',
  EG_HELP_REQUEST_REMOVE = 'EG_HELP_REQUEST_REMOVE',
  EG_HELP_REQUEST_AVAILABILITY = 'EG_HELP_REQUEST_AVAILABILITY',
  EG_UPDATE_INTERACTION = 'EG_UPDATE_INTERACTION',

  // COMMAND CENTER
  CC_NEW = 'CC_NEW',
  CC_UPDATE = 'CC_UPDATE',
  CC_REMOVE = 'CC_REMOVE',
  CC_HELP_REQUEST_NEW = 'CC_HELP_REQUEST_NEW',
  CC_HELP_REQUEST_UPDATE = 'CC_HELP_REQUEST_UPDATE',
  CC_HELP_REQUEST_REMOVE = 'CC_HELP_REQUEST_REMOVE',
}

export interface SseAssistAgentEvents extends SseEventsSchema {
  [SseEventType.GMAIL_AUTHENTICATION]: {
    data: GmailAuthenticationParameters
  }
  [SseEventType.SCREEN_POP]: {
    data: string
  }
}
