import HttpService from '@/common/services/HttpService'
import type { Customer } from '@/common/types/customer'
import type { SearchDuplicatesParam, CustomerWithCustomfield, MergedCustomerWithCustomfield } from '@/agent/types/customerFederation'

export const getCustomer = async (customerId: number): Promise<CustomerWithCustomfield> => {
  return HttpService.getData<CustomerWithCustomfield>(`/api/v1/customers/${customerId}`)
}

export const getCustomers = async (ids: number[]): Promise<Customer[]> => {
  return HttpService.getData<Customer[]>(`/api/v1/customers`, {
    params: {
      ids: ids.join(),
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCustomerFields = async (screenIdClientEdit: number): Promise<any> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return HttpService.getData<any>(`/api/v1/screens/${screenIdClientEdit}`)
}

export const getIfCustomerHasDuplicates = (customerRef: string | null, customerPhones: Array<string>, customerEmails: Array<string>) => {
  if (!customerRef && !customerPhones.length && !customerEmails.length) return []

  const customerParams: SearchDuplicatesParam = {
    customerReference: customerRef || undefined,
    phones: customerPhones.join(',') || undefined,
    emails: customerEmails.join(',') || undefined,
  }

  return HttpService.putData<Array<number>>(
    `/api/v1/customers/search-duplicates`,
    {},
    {
      params: customerParams,
    },
  )
}

export const findDuplicateCustomersById = (customerId: number) => {
  return HttpService.putData<Array<number>>(`/api/v1/customers/${customerId}/search-duplicates`)
}

export const mergeCustomer = (archivedCustomerId: number | null, targetCustomer: MergedCustomerWithCustomfield) => {
  return HttpService.putData<{ id: number }>(`/api/v1/customers/${archivedCustomerId}/merge`, targetCustomer)
}

export const mergeCustomerOnCreate = (
  existingCustomerId: number | null,
  newCustomer: CustomerWithCustomfield,
  targetCustomer: MergedCustomerWithCustomfield,
) => {
  return HttpService.putData<{ id: number }>(`/api/v1/customers/${existingCustomerId}/update-by-merge-on-create`, {
    customerMerge: targetCustomer,
    customer: newCustomer,
  })
}
