import GROUPS_COMMAND_CENTER from '@/engage/constants/groupsCommandCenter'
import CHANNEL_TYPE from '@/engage/constants/channelType'
import { type RouteLocationNormalized } from 'vue-router'

export default [
  {
    path: '',
    alias: '/engage',
    component: () => import('@/engage/views/AgentView.vue'),
    meta: {
      name: 'engage',
    },
    children: [
      {
        path: '',
        name: 'default',
        component: () => import('@/engage/pages/engage/PageDefault.vue'),
      },
      {
        path: 'chat',
        name: 'chat',
        meta: {
          channelRoute: true,
          worklog: true,
        },
        children: [
          {
            path: `:ticketId(\\d+)/close`,
            name: `chat-close`,
            component: () => import('@/engage/pages/engage/PageChatClose.vue'),
            props: ticketIdForceNumber,
          },
          {
            path: `:ticketId(\\d+)/open`,
            name: `chat-open`,
            component: () => import('@/engage/pages/engage/PageChatOpen.vue'),
            props: ticketIdForceNumber,
          },
        ],
      },
      {
        path: 'public',
        name: 'public',
        meta: {
          channelRoute: true,
          worklog: true,
        },
        children: [
          {
            path: `${CHANNEL_TYPE.FACEBOOK.name}/:ticketId(\\d+)`,
            name: CHANNEL_TYPE.FACEBOOK.name,
            component: () => import('@/engage/pages/engage/PageFacebook.vue'),
            props: ticketIdForceNumber,
          },
          {
            path: `${CHANNEL_TYPE.TWITTER.name}/:ticketId(\\d+)`,
            name: CHANNEL_TYPE.TWITTER.name,
            component: () => import('@/engage/pages/engage/PageTwitter.vue'),
            props: ticketIdForceNumber,
          },
          {
            path: `${CHANNEL_TYPE.INSTAGRAM.name}/:ticketId(\\d+)`,
            name: CHANNEL_TYPE.INSTAGRAM.name,
            component: () => import('@/engage/pages/engage/PageInstagram.vue'),
            props: ticketIdForceNumber,
          },
        ],
      },
      {
        path: 'private',
        name: 'private',
        meta: {
          channelRoute: true,
          worklog: true,
        },
        children: [
          {
            path: `${CHANNEL_TYPE.SMS.name}/:ticketId(\\d+)`,
            name: CHANNEL_TYPE.SMS.name,
            component: () => import('@/engage/pages/engage/PageSms.vue'),
            props: ticketIdForceNumber,
          },
          {
            path: `${CHANNEL_TYPE.INSTAGRAMDM.name}/:ticketId(\\d+)`,
            name: CHANNEL_TYPE.INSTAGRAMDM.name,
            component: () => import('@/engage/pages/engage/PageInstagramDM.vue'),
            props: ticketIdForceNumber,
          },
          {
            path: `${CHANNEL_TYPE.MESSENGER.name}/:ticketId(\\d+)`,
            name: CHANNEL_TYPE.MESSENGER.name,
            component: () => import('@/engage/pages/engage/PageMessenger.vue'),
            props: ticketIdForceNumber,
          },
          {
            path: `${CHANNEL_TYPE.TWITTERDM.name}/:ticketId(\\d+)`,
            name: CHANNEL_TYPE.TWITTERDM.name,
            component: () => import('@/engage/pages/engage/PageTwitterDM.vue'),
            props: ticketIdForceNumber,
          },
          {
            path: `${CHANNEL_TYPE.WHATSAPP.name}/:ticketId(\\d+)`,
            name: CHANNEL_TYPE.WHATSAPP.name,
            component: () => import('@/engage/pages/engage/PageWhatsapp.vue'),
            props: ticketIdForceNumber,
          },
        ],
      },
    ],
  },
  {
    path: 'command-center',
    component: () => import('@/engage/views/CommandCenterView.vue'),
    meta: {
      name: 'command-center',
      supervisorRoute: true,
    },
    children: [
      {
        path: '',
        name: 'command-center-default',
        component: () => import('@/engage/pages/commandCenter/PageDefault.vue'),
        meta: {
          supervisorRoute: true,
        },
      },
      {
        path: `${GROUPS_COMMAND_CENTER.HELP}/:ticketId(\\d+)`,
        name: GROUPS_COMMAND_CENTER.HELP,
        component: () => import('@/engage/pages/commandCenter/PageChat.vue'),
        props: ticketIdForceNumber,
        meta: {
          channelRoute: true,
          supervisorRoute: true,
        },
      },
      {
        path: `${GROUPS_COMMAND_CENTER.OTHER}/:ticketId(\\d+)`,
        name: GROUPS_COMMAND_CENTER.OTHER,
        component: () => import('@/engage/pages/commandCenter/PageChat.vue'),
        props: ticketIdForceNumber,
        meta: {
          channelRoute: true,
          supervisorRoute: true,
        },
      },
    ],
  },
  {
    path: '404',
    name: '404',
    component: () => import('@/engage/pages/PageNotFound.vue'),
  },
  {
    path: ':pathMatch(.*)*',
    redirect: { name: '404' },
  },
]

function ticketIdForceNumber(route: RouteLocationNormalized) {
  const ticketId = route.params.ticketId as string
  return {
    ticketId: parseInt(ticketId, 10),
  }
}
