import { defineStore } from 'pinia'
import { ref } from 'vue'
import Api from '@/engage/plugins/api'
import type { PolaritySentiment } from '@/engage/types/polaritySentiment'

export const usePolaritySentimentStore = defineStore('polaritySentiment', () => {
  const listByTicket = ref<PolaritySentiment[]>([])
  const listByInteraction = ref<PolaritySentiment[]>([])

  const setListPolaritySentimentTicket = (data: PolaritySentiment[]) => {
    if (!data) return
    listByTicket.value = data
  }

  const setListPolaritySentimentInteraction = (data: PolaritySentiment[]) => {
    if (!data) return
    listByInteraction.value = data
  }

  const getFindActive = async () => {
    return Promise.all([(_getFindActiveForInteraction(), _getFindActiveForTicket())])
  }

  const _getFindActiveForInteraction = async () => {
    const { data } = await Api.polaritySentiment.getFindActiveForInteraction()
    setListPolaritySentimentInteraction(data)
  }

  const _getFindActiveForTicket = async () => {
    const { data } = await Api.polaritySentiment.getFindActiveForTicket()
    setListPolaritySentimentTicket(data)
  }

  return {
    listByTicket,
    listByInteraction,
    getFindActive,
  }
})
