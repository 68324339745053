import { defineStore } from 'pinia'
import { getAgentActivities } from '@/common/services/AgentActivityService'
import { type ActivityDTOV1Named } from '@/common/types/activity'
import { ref, computed, watch, type WatchStopHandle } from 'vue'
import { publish, subscribe, unsubscribe } from '@/common/services/eventBusService'
import { useSessionStore } from '@/common/stores/sessionStore'

export const useActivitiesStore = defineStore('activities', () => {
  const sessionStore = useSessionStore()
  const activitiesList = ref<ActivityDTOV1Named[]>([])
  let watchBlockAutomaticPush: WatchStopHandle

  const noActivityDefault = {
    name: 'assist.activities.no-activity',
    description: 'assist.activities.no-activity.description',
    blockAutomaticPush: false,
    active: true,
    order: 0,
  }

  const userCurrentActivity = ref<ActivityDTOV1Named>(noActivityDefault)

  async function fetchAgentActivities() {
    activitiesList.value = await getAgentActivities()
  }
  const getAgentAllowingPushActivitiesByAlphabeticalOrder = computed(() => {
    const allowingPushActivities = activitiesList.value?.filter((activity) => !activity.blockAutomaticPush)

    return sortByAlphabeticalOrder(allowingPushActivities)
  })

  const getAgentBlockingPushActivitiesByAlphabeticalOrder = computed(() => {
    const blockingActivities = activitiesList.value?.filter((activity) => activity.blockAutomaticPush)

    return sortByAlphabeticalOrder(blockingActivities)
  })

  const getAgentActivityById = computed(() => {
    return (id: UUID) => activitiesList.value.find((activity) => activity.id === id)
  })

  const switchChannelsOnEngageOrLegacy = (force?: boolean) => {
    if (userCurrentActivity.value.blockAutomaticPush || force) {
      publish('assist:switch-input-channels', userCurrentActivity.value.blockAutomaticPush)
    }
  }

  function registerActivitiesEvents() {
    unregisterActivitiesEvents()
    if (sessionStore.isPushAuto) {
      subscribe('engage:channels-are-loaded', () => switchChannelsOnEngageOrLegacy(false))
      subscribe('assist:channels-are-loaded', () => switchChannelsOnEngageOrLegacy(false))

      watchBlockAutomaticPush = watch(
        () => userCurrentActivity.value,
        () => switchChannelsOnEngageOrLegacy(true),
      )
    }
  }

  function unregisterActivitiesEvents() {
    unsubscribe('engage:channels-are-loaded')
    unsubscribe('assist:channels-are-loaded')

    if (watchBlockAutomaticPush) watchBlockAutomaticPush()
  }

  const sortByAlphabeticalOrder = (activities: ActivityDTOV1Named[]) => {
    return activities.sort((a, b) => a.name.localeCompare(b.name))
  }

  return {
    userCurrentActivity,
    activitiesList,
    fetchAgentActivities,
    getAgentActivityById,
    noActivityDefault,
    getAgentBlockingPushActivitiesByAlphabeticalOrder,
    getAgentAllowingPushActivitiesByAlphabeticalOrder,
    registerActivitiesEvents,
    unregisterActivitiesEvents,
    switchChannelsOnEngageOrLegacy,
  }
})
