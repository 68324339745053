import { type PrefixTranslationsEnum } from '@/common/types/i18n'
import { requestTranslations } from '@/common/services/LocalizationService'

type ItemExtendWithName<T> = T & { name: string }

export const includeTranslationsInItems = async <T, U extends ItemExtendWithName<T> = ItemExtendWithName<T>>(
  getItems: () => Promise<T[]>,
  local: string,
  i18nPrefix: PrefixTranslationsEnum,
  i18nItemKey: keyof T,
) => {
  const [list, labels] = await Promise.all([getItems(), requestTranslations(local, i18nPrefix)])

  return addNamePropertyToItems<T, U>(list, (item) => labels[i18nPrefix + item[i18nItemKey]])
}

export const addNamePropertyToItems = <T, U extends ItemExtendWithName<T> = ItemExtendWithName<T>>(list: T[], nameGenerator: (item: T) => string) => {
  return list.map((item: T) => ({
    ...item,
    name: nameGenerator(item),
  })) as U[]
}
