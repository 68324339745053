export default Object.freeze({
  CREATION: 0,
  COMPLETEONCREATION: 1,
  WAKEUP: 4,
  ASSIGN: 101,
  PUTBACKINPOOL: 103,
  TAKEON: 104,
  ESCALATE: 110,
  CANCEL_ESCALATE: 120,
  REASSIGN: 121,
  PUTONHOLD: 201,
  ASKVALIDATION: 202,
  APPROVE: 203,
  DISAPPROVE: 204,
  TRANSFER: 209,
  COMPLETE: 210,
  COMPLETEONVALIDATION: 212,
  REOPEN: 213,
  UPDATEVALUES: 300,
  ATTACHPARENT: 301,
  UDPATEDUEDATE: 302,
  ADD_ATTACHMENT: 330,
  DELETE_ATTACHMENT: 339,
  CHAT_REASSIGN: 600,
  CHAT_TRANSFER: 601,
  CHAT_RELEASE: 602,
  CHAT_ESCALATION: 603,
  CHAT_CLOSE: 604,
  CHAT_BOT_ESCALATION: 605,
  CHAT_ADD_IN_QUEUING: 606,
  MOVE_TICKET_CUSTOMER: 501,
  CONVERT_TO_SMS: 503,
  CONVERT_TO_WHATSAPP: 504,
  DELETE: 990,
  COMMENT: 998,
  LIKE: 'LIKE',
  UNLIKE: 'UNLIKE',
  HIDE: 'HIDE',
  DISPLAY: 'DISPLAY',
  REPLY: 'REPLY',
  QUALIFY: 'QUALIFY',
  POLARIZE: 'POLARIZE',
  HELP_REQUEST: 1001,
  CANCEL_HELP_REQUEST: 1002,
  ASSIGN_HELP_REQUEST: 1003,
  ABANDON_HELP_REQUEST: 1004,
  CLOSE_HELP_REQUEST: 1005,
})
