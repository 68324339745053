import HttpService from '@/common/services/HttpService'
import {
  type ScreenBaseDTOV1,
  type ScreenBaseDTOV2,
  type ScreenDTOV2,
  type ScreenDTOV1,
  type ScreenTypeEnum,
  type ScreenField,
} from '@/common/types/screen'
import { get as getCachedData } from '@/common/services/LocalStorageService'
import { loadEnumTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'

interface listScreensParams {
  types?: ScreenTypeEnum[]
  creation?: boolean
  userIdToFilterBySkills?: number
}

/**
 * Retrieve all screens of the requested types
 * @param params
 * @returns screens list
 * @deprecated
 */
export const listScreens = async (params: listScreensParams): Promise<ScreenBaseDTOV1[]> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const paramsToSent: any = { ...params }
  if (params.types) paramsToSent.types = params.types.join()
  const cacheKey = 'screenslist-' + params.types?.join() + '-' + params.creation + '-' + params.userIdToFilterBySkills
  return (
    (await getCachedData<ScreenBaseDTOV1[]>(cacheKey, '/api/v1/screens', {
      params: paramsToSent,
    })) || []
  )
}

export const getScreensV2 = async (cacheKey: string): Promise<ScreenBaseDTOV2[]> => {
  return getCachedData<ScreenBaseDTOV2[]>(cacheKey, '/api/v2/screens/', {
    params: {
      includeDeactivated: true,
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchScreens = async (cacheKey: string): Promise<any> => {
  return await Promise.all([getScreensV2(cacheKey), loadEnumTranslations(PrefixTranslationsEnum.SCREEN)])
}

/**
 * Get screen by id
 * @param id screen id
 * @returns ScreenDTOV1
 */
export const getScreenById = async (id: number): Promise<ScreenDTOV1> => {
  return HttpService.getData<ScreenDTOV1>('/api/v1/screens/' + id)
}

/**
 * Handle screen activation
 */
export const handleScreenActivation = (code: UUID, active: boolean) => {
  return HttpService.patch(`/api/v2/screens/${code}`, {
    activeToSet: active,
  })
}

/**
 * Get screen by code
 * @param screenCode screen code
 * @returns ScreenDTOV2
 */
export const getScreenByCode = async (screenCode: UUID): Promise<ScreenDTOV2> => {
  return HttpService.getData<ScreenDTOV2>(`/api/v2/screens/${screenCode}`)
}

/**
 * Create new screen
 * @returns created screen
 */
export const createScreen = async (newScreen: { type: ScreenTypeEnum; active: boolean; fields: ScreenField[] }): Promise<ScreenBaseDTOV2> => {
  return HttpService.postData<ScreenBaseDTOV2>('/api/v2/screens', newScreen)
}

/**
 * Update existing screen
 * @returns updated screen
 */
export const updateScreen = async (screenCode: UUID, updateScreenPayload: { active: boolean; fields: ScreenField[] }): Promise<ScreenBaseDTOV2> => {
  return HttpService.putData<ScreenBaseDTOV2>(`/api/v2/screens/${screenCode}`, updateScreenPayload)
}
