import { AGENT_ROUTE_NAMES } from '@/agent/types/router'
import { type RouteRecordRaw } from 'vue-router'
import ctiRoutes from '@/cti/routes'
import { agentBeforeEnter, contextualSearchBeforeEnter } from '@/router/guards'

const LegacyView = () => import('@/common/views/LegacyView.vue')
const BaseHeader = () => import('@/agent/components/headers/BaseHeader.vue')
const CaseListHeader = () => import('@/agent/components/headers/CaseListHeader.vue')
const CaseView = () => import('@/agent/views/CaseView.vue')
const CustomerHeader = () => import('@/agent/components/headers/CustomerHeader.vue')
const CaseConvertHeader = () => import('@/agent/components/headers/CaseConvertHeader.vue')
const SupervisionHeader = () => import('@/agent/components/headers/SupervisionHeader.vue')

export default [
  {
    path: '/assist',
    component: LegacyView,
    beforeEnter: agentBeforeEnter,
    meta: {
      legacy: true,
      agent: true,
    },
    children: [
      ...ctiRoutes,
      {
        name: AGENT_ROUTE_NAMES.TICKET_LIST,
        path: 'tickets/list',
        component: CaseListHeader,
        beforeEnter: [contextualSearchBeforeEnter],
        meta: {
          exclude: ['/assist/tickets/bulk/bulkSave'],
        },
      },
      {
        path: 'ticket/show/:caseId',
        component: CaseView,
        props: true,
        meta: {
          refresh: true,
          allowBackNavigation: true,
          exclude: ['/assist/ticket/save', '/assist/ticket/takeon'],
        },
      },
      {
        path: 'ticket/create/oneclick/:skillGroupId',
        component: BaseHeader,
        meta: {
          allowBackNavigation: true,
          skipHistory: true,
        },
      },
      {
        path: ':channel/create/:id',
        component: CaseConvertHeader,
        props: {
          menu: false,
          redirectToHomePage: null,
        },
        meta: {
          exclude: ['/assist/sms/save'],
          allowBackNavigation: true,
          skipHistory: true,
        },
      },
      {
        path: 'pause/start/:id',
        component: BaseHeader,
        props: {
          menu: false,
          redirectToHomePage: null,
        },
      },
      {
        path: 'user/myprofile',
        component: BaseHeader,
        props: {
          menu: true,
          backButton: true,
        },
      },
      {
        path: 'customer/new',
        component: CustomerHeader,
        props: {
          menu: true,
          backButton: true,
        },
        meta: {
          exclude: ['/assist/customer/save'],
        },
      },
      {
        path: 'customer/edit/:customerId',
        component: CustomerHeader,
        beforeEnter: [contextualSearchBeforeEnter],
        props: (route) => ({
          customerId: route.params.customerId,
          menu: true,
          backButton: true,
        }),
        meta: {
          exclude: ['/assist/customer/delete', '/assist/customer/save'],
          allowBackNavigation: true,
        },
      },
    ],
  },
  {
    path: '/assist',
    component: LegacyView,
    meta: {
      legacy: true,
    },
    children: [
      {
        path: 'stats/:url+',
        component: BaseHeader,
        props: {
          menu: true,
          backButton: true,
        },
      },
      {
        path: 'stats/supervision/users',
        component: SupervisionHeader,
        props: {
          menu: true,
          backButton: true,
        },
      },
      {
        path: 'stats/supervision/connections',
        component: SupervisionHeader,
        props: {
          menu: true,
          backButton: true,
        },
      },
      {
        path: 'imports/init',
        component: BaseHeader,
        props: {
          menu: true,
          backButton: true,
        },
      },
      {
        path: 'exclusions/init',
        component: BaseHeader,
        props: {
          menu: true,
          backButton: true,
        },
      },
    ],
  },
] as Array<RouteRecordRaw>
