export default Object.freeze({
  chrome: {
    name: 'Google Chrome',
    icon: 'chrome.svg',
  },
  firefox: {
    name: 'Firefox',
    icon: 'mozilla.svg',
  },
  safari: {
    name: 'Safari',
    icon: 'safari.svg',
  },
  edge: {
    name: 'Edge',
    icon: 'windows.svg',
  },
  'internet-explorer': {
    name: 'Internet Explorer',
    icon: 'explorer.svg',
  },
})
