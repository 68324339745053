import { uid } from 'quasar'
import HttpService from '@/common/services/HttpService'
import {
  NOTIFICATION_SCOPE,
  type NotificationResponse,
  type NotificationList,
  type Notification,
  type NotificationToken,
} from '@/common/types/notification'

export const GET_NOTIFICATIONS_URL = 'user/notifications'

export const fetchNotifications = async (): Promise<NotificationList> => {
  const notifications = await HttpService.getData<NotificationResponse>(GET_NOTIFICATIONS_URL)

  // Inject source property to the notification
  return notifications.map((n) => ({ ...n, source: NOTIFICATION_SCOPE.Remote })) as NotificationList
}

/**
 *
 * Sort notifications
 */
const compare = (a: NotificationToken, b: NotificationToken) => (a.date < b.date ? 1 : -1)
const sort = (notifications: NotificationList) => {
  return notifications.sort(compare)
}

/**
 * Update the notfication list with remote notification list
 */
export const updateNotifications = async (current: NotificationList) => {
  const remote = await fetchNotifications()

  // Remove current remote notifications
  const local = current.filter((n) => n.source !== NOTIFICATION_SCOPE.Remote)

  // Remove local notification with the same reference as a remote one
  // ex: a completed export notification has the same id as the locally
  // created notification
  const references = remote.map((n) => n.ref)
  const localFiltered = local.filter((n) => !references.includes(n.ref))

  return sort([...localFiltered, ...remote])
}

/**
 * Request deletion for all deletable notifications
 */
export const removeAllNotifications = (notifications: NotificationList) => {
  if (notifications.length) {
    const deletableList = notifications.filter((n) => n.id && n.type && n.deletable).map((n) => ({ id: n.id, type: n.type }))
    if (deletableList.length) {
      return HttpService.delete(`${GET_NOTIFICATIONS_URL}`, { data: deletableList })
    } else {
      return Promise.resolve('There is no deletable notification')
    }
  } else {
    return Promise.reject('There is no notification to remove')
  }
}

export const removeNotification = (notification: Notification) => {
  const { id, type, deletable } = notification
  if (!id || !type || !deletable) return Promise.resolve()

  const deleteUrl = `${GET_NOTIFICATIONS_URL}/${type}/${id}`
  return HttpService.delete(deleteUrl)
}
/**
 * Add a new notification to the list
 */
export const addNotification = (notification: Notification, current: NotificationList) => {
  if (!notification.ref) notification.ref = uid()
  return sort([...current, notification])
}

/**
 * Update the browser tab icon and title with the notification count
 * count: notification count
 * */
export const refreshBrowserTab = (count: number) => {
  const TAB_COUNTER_REGEX = /^\(\d+\) /i
  const FAVICON_SIZE = 32

  const $favicon = document.querySelectorAll<HTMLLinkElement>('link[rel*="icon"]')

  // Add new notification marker to the original icon
  if (count) {
    const canvas = document.createElement('canvas')
    canvas.width = FAVICON_SIZE
    canvas.height = FAVICON_SIZE

    const context = canvas.getContext('2d')

    if (context) {
      $favicon.forEach(($icon: HTMLLinkElement) => {
        const img = document.createElement('img')
        const origin = $icon.dataset.origin
        if (img && origin) {
          img.src = origin
          img.onload = function () {
            // Draw Original Favicon as Background
            context.drawImage(img, 0, 6, FAVICON_SIZE - 6, FAVICON_SIZE - 6)

            // Draw Notification Circle
            context.beginPath()
            context.arc(24, 8, FAVICON_SIZE / 4, 0, 2 * Math.PI)
            context.fillStyle = 'green'
            context.fill()

            // Replace favicon
            $icon.href = canvas.toDataURL($icon.type)
          }
        }
      })
    }
  } else {
    $favicon.forEach(($icon: HTMLLinkElement) => {
      const origin = $icon.dataset.origin
      if (origin) {
        $icon.href = origin
      }
    })
  }

  // Add notification count to the document title
  let title = document.title
  if (count) {
    if (TAB_COUNTER_REGEX.test(title)) {
      title = title.replace(TAB_COUNTER_REGEX, '(' + count + ') ')
    } else {
      title = '(' + count + ') ' + title
    }
  } else {
    title = title.replace(TAB_COUNTER_REGEX, '')
  }
  document.title = title
}

export const canRemove = (notification: Notification) => {
  return notification.source === NOTIFICATION_SCOPE.Local || notification.deletable
}
