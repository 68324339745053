import type { App } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faInfo,
  faSquare,
  faCircle,
  faStepBackward,
  faStepForward,
  faArrowsAltV,
  faPaperclip,
  faMinus,
  faCalendar,
  faEyeDropper,
  faArrowsAlt,
  faSms,
  faPhoneAlt,
  faEnvelope,
  faComment,
  faTimes,
  faLayerGroup,
  faCheckSquare,
  faMinusSquare,
  faTimesCircle,
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamation,
  faExclamationTriangle,
  faChevronLeft,
  faChevronRight,
  faSortUp,
  faChevronDown,
  faChevronUp,
  faBars,
  faCaretUp,
  faCaretDown,
  faEdit,
  faStar,
  faStarHalf,
  faUser,
  faArchive,
  faSearch,
  faEllipsisV,
  faUserCircle,
  faCog,
  faPlus,
  faArrowUp,
  faArrowDown,
  faPoll,
  faSignature,
  faPaperPlane,
  faFolder,
  faFolderOpen,
  faSync,
  faReply,
  faThumbsUp as faThumbsUpSolid,
  faHeart as faHeartSolid,
  faEye,
  faEyeSlash,
  faTrashAlt,
  faArrowLeft,
  faUserEdit,
  faUserHeadset,
  faArrowDownToBracket,
  faUtensils,
  faAddressBook,
  faBell,
  faBriefcase,
  faBug,
  faCoffee,
  faComments,
  faDesktop,
  faFireExtinguisher,
  faGraduationCap,
  faHourglass,
  faLifeRing,
  faMedkit,
  faMobile,
  faPhone,
  faQuestion,
  faTrash,
  faUniversity,
  faUpload,
  faUsers,
  faWrench,
  faSpinnerThird,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faStar as farStar,
  faSquare as farSquare,
  faMeh,
  faSmile,
  faFrown,
  faThumbsUp,
  faHeart,
  faCircle as farCircle,
  faDotCircle,
  faImages,
  faBooks,
  faGlasses,
  faFileAlt,
  faFileVideo,
  faFileAudio,
  faFilePdf,
  faFileCode,
  faFont,
  faImage,
  faChevronDown as faChevronDownRegular,
  faCheck as faCheckRegular,
  faAlarmClock,
  faEnvelope as faEnvelopeRegular,
  faAddressCard,
  faDownload,
  faTrashCan,
  faGripDotsVertical,
  faHashtag,
  faNotebook,
  faInboxOut,
  faInboxIn,
  faMailboxFlagUp,
  faTypewriter,
  faPeopleGroup,
  faCalendarDays,
  faNetworkWired,
  faVideo,
  faUsbDrive,
  faFax,
  faLaptopArrowDown,
  faMicrophone,
  faBookOpenCover,
  faEnvelopesBulk,
  faCloud,
  faBuilding,
  faPenNib,
  faWaveformLines,
  faDiamond,
  faEnvelopeOpenText,
  faBriefcase as farBriefcase,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faTwitter,
  faTwitterSquare,
  faWhatsapp,
  faFacebook,
  faFacebookMessenger,
  faInstagram,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons'
import {
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownLeftAndArrowUpRightToCenter,
  faUnlink,
  faThumbsUp as faThumbsUpLight,
  faHeart as faHeartLight,
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCheck,
  faTimes,
  faTimesCircle,
  faCheckCircle,
  faInfo,
  faExclamation,
  faExclamationTriangle,
  faCheckSquare,
  faSquare,
  faCircle,
  faMinusSquare,
  faArrowUp,
  faArrowDown,
  faBars,
  faCaretDown,
  faEdit,
  faStar,
  faStarHalf,
  faSync,
  faStepBackward,
  faStepForward,
  faArrowsAltV,
  faPaperclip,
  faPlus,
  faMinus,
  faCalendar,
  faEyeDropper,
  faSquare,
  faDotCircle,
  farStar,
  farSquare,
  farCircle,
  faTrashAlt,
  faArrowsAlt,
  faInfoCircle,
  faSms,
  faPhoneAlt,
  faEnvelope,
  faComment,
  faCheckSquare,
  faMinusSquare,
  faTimes,
  faTimesCircle,
  faLayerGroup,
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faExclamation,
  faExclamationTriangle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faSortUp,
  faChevronDown,
  faChevronUp,
  faBars,
  faCaretUp,
  faCaretDown,
  faDotCircle,
  faEdit,
  faStar,
  faStarHalf,
  faTwitter,
  faTwitterSquare,
  faWhatsapp,
  faFacebook,
  faFacebookMessenger,
  faInstagram,
  faInstagramSquare,
  faUser,
  faMeh,
  faSmile,
  faArchive,
  faFrown,
  faThumbsUp,
  faHeart,
  faSearch,
  faEllipsisV,
  faUserCircle,
  faCog,
  faPlus,
  faArrowUp,
  faArrowDown,
  faPoll,
  faImages,
  faBooks,
  faSignature,
  faPaperPlane,
  faFolder,
  faFolderOpen,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownLeftAndArrowUpRightToCenter,
  faGlasses,
  faUnlink,
  faFileAlt,
  faFileVideo,
  faFileAudio,
  faFilePdf,
  faFileCode,
  faFont,
  faImage,
  faSync,
  faChevronDownRegular,
  faCheckRegular,
  faReply,
  faThumbsUpSolid,
  faHeartSolid,
  faThumbsUpLight,
  faHeartLight,
  faEye,
  faEyeSlash,
  faAlarmClock,
  faEnvelopeRegular,
  faTrashAlt,
  faArrowLeft,
  faAddressCard,
  faUserEdit,
  faUserHeadset,
  faDownload,
  faArrowDownToBracket,
  faTrashCan,
  faGripDotsVertical,
  faHashtag,
  faNotebook,
  faUtensils,
  faAddressBook,
  faBell,
  faBriefcase,
  faBug,
  faCoffee,
  faComments,
  faDesktop,
  faFireExtinguisher,
  faGraduationCap,
  faHourglass,
  faLifeRing,
  faMedkit,
  faMobile,
  faPhone,
  faQuestion,
  faTrash,
  faUniversity,
  faUpload,
  faUsers,
  faWrench,
  faSpinnerThird,
  faInboxOut,
  faInboxIn,
  faMailboxFlagUp,
  faTypewriter,
  faPeopleGroup,
  faBuilding,
  faPenNib,
  faCalendarDays,
  faNetworkWired,
  faVideo,
  faUsbDrive,
  faFax,
  faLaptopArrowDown,
  faMicrophone,
  faWaveformLines,
  faBookOpenCover,
  faEnvelopesBulk,
  faCloud,
  faDiamond,
  faEnvelopeOpenText,
  farBriefcase,
)

export default (app: App) => {
  app.component('FontAwesomeIcon', FontAwesomeIcon)
}
