import axios from 'axios'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractErrorMessage = (error: any): string => {
  let errorMessage = String(error)
  if (axios.isAxiosError(error)) {
    errorMessage = encodeURIComponent(error.response?.data?.message)
  }
  return errorMessage
}
