import { type WithRequired } from '@/common/types/utils'
import { defineStore } from 'pinia'
import { ref } from 'vue'

type SnackbarOptions = {
  text: string | null
  mode: string
  multiline: boolean
  color?: string
  timeout: number
  visible: boolean
  x: string | null
  y: string
}

export type SnackbarOptionsToken = WithRequired<Partial<SnackbarOptions>, 'text'>

export const useSnackbarStore = defineStore('snackbar', () => {
  const options = ref<SnackbarOptions>({
    mode: '',
    multiline: false,
    text: null,
    timeout: 3000,
    visible: false,
    x: null,
    y: 'bottom',
  })

  const showSnackbar = (payload: SnackbarOptionsToken) => {
    if (payload.text && Array.isArray(payload.text)) {
      payload.multiline = true
      payload.text = payload.text.map((o) => o.message).join('<br/>')
    }

    const multiline = payload.multiline ? payload.multiline : !!(payload.text && payload.text.length > 50)

    options.value = {
      ...options.value,
      ...payload,
      multiline,
      visible: true,
    }
  }

  const closeSnackbar = () => {
    options.value = {
      mode: '',
      multiline: false,
      text: null,
      timeout: 3000,
      visible: false,
      x: null,
      y: 'bottom',
    }
  }

  return {
    options,
    showSnackbar,
    closeSnackbar,
  }
})
