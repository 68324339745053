export default Object.freeze({
  LOCAL_DATE: 'LOCAL_DATE',
  LOCAL_DATE_TIME: 'TIMESTAMP',
  EMAIL: 'EMAIL',
  LABEL: 'LABEL',
  SELECT_LIST_MULTIPLE: 'SELECT_LIST_MULTIPLE',
  TREE_MULTIPLE: 'TREE_MULTIPLE',
  NUMBER: 'NUMBER',
  CHECKBOX_SINGLE: 'CHECKBOX_SINGLE',
  ENCRYPTED_TEXT: 'ENCRYPTED_TEXT',
  ENCRYPTED_TEXTAREA: 'ENCRYPTED_TEXTAREA',
  SELECT_LIST_SINGLE: 'SELECT_LIST_SINGLE',
  TREE_SINGLE: 'TREE_SINGLE',
  TREE_SINGLE_ASCENDING: 'TREE_SINGLE_ASCENDING',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  RADIO: 'RADIO',
  CHECKBOX_MULTIPLE: 'CHECKBOX_MULTIPLE',

  // Custom types defined by front end
  TYPOLOGY: 'TYPOLOGY',
  TICKET_USERGROUP: 'TICKET_USERGROUP',
  SWITCH: 'SWITCH',
  FACEBOOK_LIKE: 'CUSTOMFIELD.INTERACTION_FB_ISLIKED',
  FACEBOOK_HIDDEN: 'CUSTOMFIELD.INTERACTION_FB_ISHIDDEN',
  INSTAGRAM_STORY_URL: 'CUSTOMFIELD.INTERACTION_INSTAGRAM_STORY_URL',
  INTERACTION_INSTAGRAM_MEDIA_VIDEO_URL: 'CUSTOMFIELD.INTERACTION_INSTAGRAM_MEDIA_VIDEO_URL',
  INSTAGRAM_STORY_DATE: 'CUSTOMFIELD.INTERACTION_INSTAGRAM_STORY_DATE',
})
