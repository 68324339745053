import { type NavigationGuard } from 'vue-router'

export type NavigationGuardWithoutNext = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: Parameters<NavigationGuard> extends [to: infer A, from: infer B, ...args: any[]] ? [A, B] : never
) => ReturnType<NavigationGuard>

export const APP_ROUTE_NAMES = {
  ERROR_ROUTE_NAME: 'ERROR_ROUTE_NAME',
  GENESYS_AUTH_IFRAME_NAME: 'GENESYS_AUTH_IFRAME_NAME',
  OIDC_LOGOUT_ROUTE_NAME: 'OIDC_LOGOUT_ROUTE_NAME',
  OIDC_LANDING_ROUTE_NAME: 'OIDC_LANDING_ROUTE_NAME',
} as const

export const APP_ROUTE_PATHS = {
  NOT_FOUND_PATH: '/assist/tickets/list',
  HOMEPAGE: '/assist/tickets/list',
} as const

export type NavigationHistoryItem = {
  path: string
  backNavigation?: boolean // did we come back on this path ?
}
