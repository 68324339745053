/**
 * @description Helper to get data attributes from div entry point
 */

const { pathname } = document.body.dataset // get data- attributes
const csrfToken = document.head.querySelector<HTMLMetaElement>("meta[name='_csrf']")?.content
const csrfHeader = document.head.querySelector<HTMLMetaElement>("meta[name='_csrf_header']")?.content
const protocol = window.location.protocol
const host = window.location.host
const root = `${protocol}//${host}${pathname}`

interface CSRFConfig {
  token: string // value
  header: string // name
}

interface HostConfig {
  pathname?: string
  protocol: string
  host: string
  root: string
  csrf: CSRFConfig
}

/**
 * In assist-agent, routes are prefixed by "/assist" and
 * monolith server-side routes are deducted by replacing
 * this server context path base.
 *
 *  When the navigation is initated by the application,
 *  "/assist" prefix should be replaced by the server
 *  base path
 */
export const normalizeAssistPath = (path: string) => {
  if (!pathname) return ''
  return path.replace(pathname, rootPath)
}

export const pathCombine = (args: string[]) => {
  return args.join('/').replace(/([^:]\/)\/+/g, '$1')
}

/**
 *  When the navigation is initated by the iframe,
 *  "/assist" prefix should replace the server
 *  base path to be compared with the current route.
 */
export const normalizeMonolithPath = (path: string) => {
  if (!pathname) return ''

  if (path.startsWith(rootPath)) return path.replace(rootPath, pathname)
  else {
    return `${pathname}${path}`
  }
}

const initialConfig: HostConfig = {
  protocol,
  host,
  root,
  csrf: {
    token: csrfToken || '',
    header: csrfHeader || '',
  },
}

const hostConfig: HostConfig = Object.freeze(pathname ? { ...initialConfig, pathname } : initialConfig)

export const rootPath = '/assist/'

export const featureFlags = JSON.parse(document.body.dataset.features || '[]')

const stagingUrlPattern = /^(http|https):\/\/assist.staging.innso.io/
export const isStagingInstance = () => stagingUrlPattern.test(window.__toCdnUrl(''))

export default hostConfig
