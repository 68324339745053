import { defineRule, configure } from 'vee-validate'
import { required, min, max, min_value, numeric, one_of, confirmed } from '@vee-validate/rules'
import { loadLocaleFromURL, localize } from '@vee-validate/i18n'

const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
const uuid = (value: string) => {
  return uuidRegex.test(value)
}

const codeRegex = /^[a-zA-Z0-9-_]+$/
const codeRule = (value: string) => {
  return codeRegex.test(value)
}

defineRule('required', required)
defineRule('min', min)
defineRule('min_value', min_value)
defineRule('max', max)
defineRule('numeric', numeric)
defineRule('one_of', one_of)
defineRule('uuid', uuid)
defineRule('codeRule', codeRule)
defineRule('confirmed', confirmed)

export const initFormValidation = async (locale: string) => {
  await loadLocaleFromURL(window.__toCdnUrl(`assets/veevalidate/${locale}.json`))

  configure({
    generateMessage: localize(locale),
  })
}
