import type { SseEventType, SseMessageAttributes, SseEventsSchema } from '@/common/types/sse'
import type { LegacyEngageCase, CaseContext } from '@/engage/types/case'
import type { HelpRequest, HelpRequestAvailability } from '@/engage/types/helpRequest'
import type { LoggedUserDetails } from '@/agent/types/users'
import type { LegacyEngageInteraction } from '@/engage/types/interactions'

interface SseDisplayMessageConfiguration {
  color: string
}
type SseEngageActionMessageConfig = Partial<Record<SseEngageActions, SseDisplayMessageConfiguration>>
export type SseEngageMessageConfig = Partial<Record<SseEventType, SseEngageActionMessageConfig>>

export interface SseEngageEvents extends SseEventsSchema {
  [SseEventType.EG_NEW]: {
    action:
      | SseEngageActions.ASSIGN
      | SseEngageActions.CHAT_BOT_ESCALATE
      | SseEngageActions.CREATION
      | SseEngageActions.REASSIGN
      | SseEngageActions.REOPEN
      | SseEngageActions.TRANSFER
    data: LegacyEngageCase
    messageAttributes: SseMessageAttributes
  }
  [SseEventType.EG_UPDATE]: {
    action:
      | SseEngageActions.CHAT_CLOSE
      | SseEngageActions.CUSTOMER_UPDATE
      | SseEngageActions.NEW_MESSAGE
      | SseEngageActions.NEW_SURVEY
      | SseEngageActions.STACK
      | SseEngageActions.TAKENON
      | SseEngageActions.TICKET_POLARIZED
      | SseEngageActions.TYPOLOGY_CHANGED
      | SseEngageActions.WAKEUP
    data: LegacyEngageCase
    messageAttributes: SseMessageAttributes
  }
  [SseEventType.EG_REMOVE]: {
    action: SseEngageActions.COMPLETE | SseEngageActions.DELETE | SseEngageActions.PUTBACKINPOOL
    data: LegacyEngageCase
    messageAttributes: SseMessageAttributes
  }
  [SseEventType.EG_CHAT_CONTEXT_CHANGED]: {
    data: CaseContext
  }
  [SseEventType.EG_AGENT_UPDATE]: {
    action: SseEngageActions.AGENT_CHANNEL_STATE_UPDATE
    data: LoggedUserDetails
    messageAttributes: SseMessageAttributes
  }
  [SseEventType.EG_HELP_REQUEST_UPDATE]: {
    action: SseEngageActions.HELP_REQUEST_ABANDONED | SseEngageActions.HELP_REQUEST_HANDLED
    data: HelpRequest
    messageAttributes: SseMessageAttributes
  }
  [SseEventType.EG_HELP_REQUEST_REMOVE]: {
    action: SseEngageActions.HELP_REQUEST_CANCELLED | SseEngageActions.HELP_REQUEST_FINISHED
    data: HelpRequest
    messageAttributes: SseMessageAttributes
  }
  [SseEventType.EG_HELP_REQUEST_AVAILABILITY]: {
    data: HelpRequestAvailability
  }
  [SseEventType.EG_UPDATE_INTERACTION]: {
    data: LegacyEngageInteraction
  }
}

export interface SseCommandCenterEvents extends SseEventsSchema {
  [SseEventType.CC_NEW]: {
    data: LegacyEngageCase
  }
  [SseEventType.CC_UPDATE]: {
    data: LegacyEngageCase
  }
  [SseEventType.CC_REMOVE]: {
    data: LegacyEngageCase
  }
  [SseEventType.CC_HELP_REQUEST_NEW]: {
    data: HelpRequest
  }
  [SseEventType.CC_HELP_REQUEST_UPDATE]: {
    data: HelpRequest
  }
  [SseEventType.CC_HELP_REQUEST_REMOVE]: {
    action: SseEngageActions.HELP_REQUEST_CANCELLED
    data: HelpRequest
    messageAttributes: SseMessageAttributes
  }
}

export enum SseEngageActions {
  ASSIGN = 'ASSIGN',
  CHAT_BOT_ESCALATE = 'CHAT_BOT_ESCALATE',
  CREATION = 'CREATION',
  REASSIGN = 'REASSIGN',
  REOPEN = 'REOPEN',
  TRANSFER = 'TRANSFER',
  CHAT_CLOSE = 'CHAT_CLOSE',
  CUSTOMER_UPDATE = 'CUSTOMER_UPDATE',
  NEW_MESSAGE = 'NEW_MESSAGE',
  NEW_SURVEY = 'NEW_SURVEY',
  STACK = 'STACK',
  TAKENON = 'TAKENON',
  TICKET_POLARIZED = 'TICKET_POLARIZED',
  TYPOLOGY_CHANGED = 'TYPOLOGY_CHANGED',
  WAKEUP = 'WAKEUP',
  COMPLETE = 'COMPLETE',
  DELETE = 'DELETE',
  PUTBACKINPOOL = 'PUTBACKINPOOL',
  AGENT_CHANNEL_STATE_UPDATE = 'AGENT_CHANNEL_STATE_UPDATE',
  HELP_REQUEST_FINISHED = 'HELP_REQUEST_FINISHED',
  HELP_REQUEST_CANCELLED = 'HELP_REQUEST_CANCELLED',
  HELP_REQUEST_ABANDONED = 'HELP_REQUEST_ABANDONED',
  HELP_REQUEST_HANDLED = 'HELP_REQUEST_HANDLED',
}
