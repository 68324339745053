import { ref } from 'vue'
import { defineStore } from 'pinia'
import { type UserDTOV2 } from '@/common/types/users'
import { fetchUsers } from '@/common/services/UsersService'
import { removeEntry } from '@/common/services/LocalStorageService'
import { API_CACHE_KEYS } from '@/common/types/cacheConstants'

export const useUserStore = defineStore('users', () => {
  const users = ref<UserDTOV2[]>([])

  async function fetch(invalidate: boolean = false) {
    try {
      if (invalidate) {
        removeEntry(API_CACHE_KEYS.USERS)
      }
      users.value = await fetchUsers(API_CACHE_KEYS.USERS)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    users,
    fetch,
  }
})
