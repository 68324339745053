import { type Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import { type SearchLinkEventScopeEnum, SearchStoreIdEnum } from '@/search/types/search'

export const useContextualSearchStore = defineStore('contextualSearch', () => {
  const searchStoreId: Ref<SearchStoreIdEnum> = ref(SearchStoreIdEnum.MAIN)
  const lastSearchOrigin: Ref<string | null> = ref(null)
  const linkScope: Ref<SearchLinkEventScopeEnum | null> = ref(null)
  const reloadPageWhenDialogClosed: Ref<boolean> = ref(false)

  const resetContextualSearch = () => {
    lastSearchOrigin.value = null
    linkScope.value = null
  }

  return {
    searchStoreId,
    lastSearchOrigin,
    linkScope,
    reloadPageWhenDialogClosed,
    resetContextualSearch,
  }
})
