import { LegacyEngageInteractionTypeCode } from '@/engage/types/interactions'

export default Object.freeze({
  CHAT_IN_NEW_USER_MESSAGE: LegacyEngageInteractionTypeCode.CHAT_IN_NEW_USER_MESSAGE,
  CHAT_OUT_BOT_AUTO_RESPONSE: LegacyEngageInteractionTypeCode.CHAT_OUT_BOT_AUTO_RESPONSE,
  CHAT_OUT_BOT_RESPONSE: LegacyEngageInteractionTypeCode.CHAT_OUT_BOT_RESPONSE,
  CHAT_OUT_OPERATOR_RESPONSE: LegacyEngageInteractionTypeCode.CHAT_OUT_OPERATOR_RESPONSE,

  INSTAGRAM_DM_STORY_MENTION: LegacyEngageInteractionTypeCode.INSTAGRAM_DM_STORY_MENTION,
  INSTAGRAM_DM_STORY_REPLY: LegacyEngageInteractionTypeCode.INSTAGRAM_DM_STORY_REPLY,

  CALL_OUT: LegacyEngageInteractionTypeCode.CALL_OUT,
  MAIL_OUT_NOTIFICATION: LegacyEngageInteractionTypeCode.MAIL_OUT_NOTIFICATION,
  MAIL_OUT_USER_INITIATED: LegacyEngageInteractionTypeCode.MAIL_OUT_USER_INITIATED,
  SMS_OUT_NOTIFICATION: LegacyEngageInteractionTypeCode.SMS_OUT_NOTIFICATION,
})
