import { type DateFormatEnum } from '@/common/types/dateFormats'
import { type ContactDriverFormatEnum } from '@/common/types/typology'

/**
 * All export definition types
 */
export enum FileDefinitionTypeEnum {
  EXPORT_TICKET = 'EXPORT_TICKET',
  EXPORT_CUSTOMER = 'EXPORT_CUSTOMER',
  EXPORT_INTERACTION = 'EXPORT_INTERACTION',
  IMPORT_TICKET = 'IMPORT_TICKET',
  IMPORT_TICKET_SATISFACTION = 'IMPORT_TICKET_SATISFACTION',
  CUSTOMER_OPT_IN_OUT = 'CUSTOMER_OPT_IN_OUT',
}

export interface FileDefinitionBaseDTOV1 {
  code: UUID
  name: string
  type: FileDefinitionTypeEnum
  active: boolean
}

export enum ExportFileSeparatorEnum {
  SEMICOLON = ';',
  PIPE = '|',
  COMMA = ',',
}

export interface FileDefinitionDTOV1 extends FileDefinitionBaseDTOV1 {
  fileSeparator: ExportFileSeparatorEnum
  fields: FieldExport[]
}
export type FileDefinitionPayload = Omit<FileDefinitionDTOV1, 'code'>

export interface FieldExport {
  displayOrder: number
  code: string
  columnName: string
  dateFormat?: DateFormatEnum
  contactDriverFormat?: ContactDriverFormatEnum
}
