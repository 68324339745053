import HttpService from '@/common/services/HttpService'
import i18n from '@/plugins/i18n'
import {
  type VonageConfigurationDTOV1,
  type VonageConfigurationDTOV2,
  VonageProtectedData,
  type VonageSmsNumberV2,
  type VonageWhatsAppNumberV2,
} from '@/admin/types/vonage'
import { displayMessage } from '@/common/services/NotificationDisplayService'
import { useVonageStore } from '@/admin/stores/vonageStore'

const { t } = i18n.global

const vonageConfigurationPath = `/api/v1/vonage/configurations`

const vonageConfigurationV2Path = `/api/v2/vonage/configurations`

export const getAllVonageConfigurationsV2 = async () => {
  try {
    return await HttpService.getData<VonageConfigurationDTOV2[]>(vonageConfigurationV2Path)
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getVonageConfiguration = async (id: UUID) => {
  try {
    return await HttpService.getData<VonageConfigurationDTOV1>(`${vonageConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getVonageConfigurationV2 = async (id: UUID) => {
  try {
    return await HttpService.getData<VonageConfigurationDTOV2>(`${vonageConfigurationV2Path}/${id}`)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const saveNewVonageSMSNumberV2 = async (vonageSmsNumber: VonageSmsNumberV2) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const vonageConfiguration: VonageConfigurationDTOV2 | null = await getVonageConfigurationV2(vonageSmsNumber.vonageConfiguration?.id!)
  delete vonageSmsNumber.vonageConfiguration
  vonageConfiguration!.smsNumbers?.push(vonageSmsNumber)

  return updateVonageConfigurationV2(
    vonageConfiguration!,
    'assist.admin.sms.vonage.sms.number.created',
    'assist.admin.sms.vonage.sms.number.create.error',
  )
}

export const updateVonageSMSNumberV2 = async (vonageSmsNumber: VonageSmsNumberV2): Promise<VonageConfigurationDTOV2 | undefined> => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const vonageConfiguration: VonageConfigurationDTOV2 | null = await getVonageConfigurationV2(vonageSmsNumber.vonageConfiguration?.id!)
  delete vonageSmsNumber.vonageConfiguration
  const objectToReplace = vonageConfiguration?.smsNumbers?.find((arrayItem) => arrayItem.id === vonageSmsNumber.id)

  if (objectToReplace) {
    Object.assign(objectToReplace, vonageSmsNumber)
  } else {
    vonageConfiguration?.smsNumbers?.push(vonageSmsNumber)
  }

  return updateVonageConfigurationV2(
    vonageConfiguration!,
    'assist.admin.sms.vonage.sms.number.updated',
    'assist.admin.sms.vonage.sms.number.update.error',
  )
}

export const saveNewVonageWhatsAppNumberV2 = async (vonageNumber: VonageWhatsAppNumberV2) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const vonageConfiguration: VonageConfigurationDTOV2 | null = await getVonageConfigurationV2(vonageNumber.vonageConfiguration?.id!)
  delete vonageNumber.vonageConfiguration
  vonageConfiguration!.whatsappNumbers?.push(vonageNumber)

  return updateVonageConfigurationV2(vonageConfiguration!, 'assist.admin.whatsapp.vonage.created', 'assist.admin.whatsapp.vonage.create.error')
}

export const updateVonageWhatsAppNumberV2 = async (vonageNumber: VonageWhatsAppNumberV2, updateList = false) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const vonageConfiguration: VonageConfigurationDTOV2 | null = await getVonageConfigurationV2(vonageNumber.vonageConfiguration?.id!)
  delete vonageNumber.vonageConfiguration
  Object.values(VonageProtectedData).forEach((value) => {
    delete vonageNumber[value]
  })
  const objectToReplace = vonageConfiguration?.whatsappNumbers?.find((arrayItem) => arrayItem.id === vonageNumber.id)

  if (objectToReplace) {
    Object.assign(objectToReplace, vonageNumber)
  } else {
    vonageConfiguration?.whatsappNumbers?.push(vonageNumber)
  }

  return updateVonageConfigurationV2(vonageConfiguration!, 'assist.admin.whatsapp.vonage.updated', 'assist.admin.whatsapp.vonage.update.error').then(
    (res: VonageConfigurationDTOV2 | undefined) => {
      if (updateList && res) {
        const vonageStore = useVonageStore()
        vonageStore.passWhatsAppNumbers()
      }
    },
  )
}

const updateVonageConfigurationV2 = async (
  vonageConfiguration: VonageConfigurationDTOV2,
  successMessage: string,
  errorMessage: string,
): Promise<VonageConfigurationDTOV2 | undefined> => {
  try {
    const res = await HttpService.putData<VonageConfigurationDTOV2>(vonageConfigurationV2Path, vonageConfiguration)
    displayMessage(t(successMessage), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t(errorMessage), 'danger')
    return
  }
}

export const saveVonageConfiguration = async (vonageConfiguration: VonageConfigurationDTOV1): Promise<VonageConfigurationDTOV1 | undefined> => {
  try {
    const res = await HttpService.putData<VonageConfigurationDTOV1>(vonageConfigurationPath, vonageConfiguration)
    displayMessage(t('assist.admin.vonage.configuration.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.vonage.configuration.error'), 'danger')
    return
  }
}

export const saveNewVonageConfiguration = async (vonageConfiguration: VonageConfigurationDTOV1) => {
  try {
    const res = await HttpService.postData<VonageConfigurationDTOV1>(vonageConfigurationPath, vonageConfiguration)
    displayMessage(t('assist.admin.vonage.configuration.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.vonage.configuration.error'), 'danger')
    return
  }
}

export const getVonageConfigurationSignatureSecretHint = async (id: UUID): Promise<string | null> => {
  try {
    return await HttpService.getData<string>(`${vonageConfigurationPath}/${id}/signature-secret`)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const updateVonageConfigurationSignatureSecret = async (id: UUID, signatureSecret: string) => {
  try {
    const res = await HttpService.putData<string>(`${vonageConfigurationPath}/${id}/signature-secret`, signatureSecret, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    return res
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getVonageConfigurationPrivateKeyHint = async (id: UUID): Promise<string | null> => {
  try {
    return await HttpService.getData<string>(`${vonageConfigurationPath}/${id}/private-key`)
  } catch (error) {
    console.error(error)
    return null
  }
}

export const updateVonageConfigurationPrivateKey = async (id: UUID, privateKey: string) => {
  try {
    const res = await HttpService.putData<string>(`${vonageConfigurationPath}/${id}/private-key`, privateKey, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
    return res
  } catch (error) {
    console.error(error)
    return null
  }
}
