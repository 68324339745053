import { sanitizeUrl } from '@/plugins/sanitizer'

import { START_LOCATION } from 'vue-router'
import { type NavigationGuardWithoutNext } from '@/app/types/router'
import { APP_ROUTE_NAMES } from '@/app/types/router'

import { getUserLandingPage } from '@/common/services/UsersService'
import { keycloakAuthentication } from '@/common/services/AuthenticationService'
import { extractErrorMessage } from '@/common/helpers/errorUtils'

import { useSessionStore } from '@/common/stores/sessionStore'

export const authenticationBeforeEach: NavigationGuardWithoutNext = async (to, from) => {
  if (to?.meta?.requiresAuth === false) return true
  if (to?.meta?.requiresSession === false) {
    await keycloakAuthentication(false)
    return true
  }

  const sessionStore = useSessionStore()
  if (from === START_LOCATION && !sessionStore.id) {
    try {
      const keycloakInstance = await keycloakAuthentication()
      if (keycloakInstance) sessionStore.oidcEnabled = true
    } catch {
      return { name: APP_ROUTE_NAMES.ERROR_ROUTE_NAME }
    }
  }
}

export const landingPageBeforeEnter: NavigationGuardWithoutNext = async () => {
  const sessionStore = useSessionStore()
  if (!sessionStore.oidcEnabled) return { name: APP_ROUTE_NAMES.ERROR_ROUTE_NAME }

  try {
    const { landingPagePath } = await getUserLandingPage()
    const urlPattern = /^(http|https):/
    if (urlPattern.test(landingPagePath)) {
      window.location.href = sanitizeUrl(landingPagePath)
    } else {
      return { path: landingPagePath }
    }
  } catch (error) {
    return { name: APP_ROUTE_NAMES.ERROR_ROUTE_NAME, query: { errorMessage: extractErrorMessage(error) } }
  }
}
