import { type CaseEmergencyEnum } from '@/common/types/case'

/**
 * Possible bulk actions for cases
 */

export enum BulkActionEnum {
  TICKETS_BULK_CANCEL = 'TICKETS_BULK_CANCEL',
  TICKETS_BULK_UNASSIGN = 'TICKETS_BULK_UNASSIGN',
  TICKETS_BULK_COMPLETE = 'TICKETS_BULK_COMPLETE',
  TICKETS_BULK_REOPEN = 'TICKETS_BULK_REOPEN',
  TICKETS_BULK_ASSIGN = 'TICKETS_BULK_ASSIGN',
  TICKETS_BULK_REASSIGN = 'TICKETS_BULK_REASSIGN',
  TICKETS_BULK_REASSIGN_ON_VALIDATION = 'TICKETS_BULK_REASSIGN_ON_VALIDATION',
  TICKETS_BULK_TRANSFER = 'TICKETS_BULK_TRANSFER',
  TICKETS_BULK_TRANSFER_ON_VALIDATION = 'TICKETS_BULK_TRANSFER_ON_VALIDATION',
  TICKETS_BULK_HANDLE_INTERACTIONS = 'TICKETS_BULK_HANDLE_INTERACTIONS',
  TICKETS_BULK_ESCALATE = 'TICKETS_BULK_ESCALATE',
  TICKETS_BULK_CATEGORIZE = 'TICKETS_BULK_CATEGORIZE',
  TICKETS_BULK_ATTACHPARENT = 'TICKETS_BULK_ATTACHPARENT',
}

export interface BulkActionAPIPayload {
  ids: number[]
  comment?: string
  reasonCode?: string
}

export interface AssignBulkActionAPIPayload extends BulkActionAPIPayload {
  userLogin: string
}

export interface TransferBulkActionAPIPayload extends BulkActionAPIPayload {
  skillCode: string
  userLogin?: string
}

export interface HandleInteractionsBulkActionAPIPayload extends BulkActionAPIPayload {
  completeCases: boolean
}

export interface EscalateBulkActionAPIPayload extends BulkActionAPIPayload {
  emergencyCode: CaseEmergencyEnum
}
export interface CategorizeBulkActionAPIPayload {
  ids: number[]
  contactDriverCode: string
}
export interface AttachParentBulkActionAPIPayload {
  ids: number[]
  parentId: number
}
