import { useUserStore } from '@/engage/stores/user'
import { useChatStore } from '@/engage/stores/chat'

const getLoggedUser = async () => {
  const userStore = useUserStore()
  await userStore.getLoggedUserDetails()
  if (userStore.userIsChatAgent) {
    await useChatStore().connect()
  }
}

const getUserAccessRight = async () => {
  const userStore = useUserStore()
  await userStore.getUserAccessRight()
}

export default {
  getLoggedUser,
  getUserAccessRight,
}
