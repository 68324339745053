import HttpService from '@/common/services/HttpService'
import { get as getCachedData } from '@/common/services/LocalStorageService'
import type {
  CaseData,
  Case,
  Emergency,
  Priority,
  TicketActionTypeEnum,
  CaseIncludeEnum,
  CaseChannel,
  CaseExpandEnum,
  CaseDataV1,
} from '@/common/types/case'
import { loadEnumTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'

export const getCase = async (caseId: number): Promise<CaseDataV1> => {
  return HttpService.getData<CaseDataV1>(`/api/v1/cases/${caseId}`)
}

export const getCaseWorklog = async (caseId: number) => {
  return HttpService.post(`/api/v1/cases/${caseId}/worklogs`)
}

export const getCaseSkillExternalUrl = async (caseId: number): Promise<string> => {
  return HttpService.getData<string>(`/api/v3/cases/${caseId}/skill-external-url`)
}

interface ChannelsParams {
  'include-deactivated': boolean
}

export const getChannelsList = async (params: ChannelsParams): Promise<CaseChannel[]> => {
  const cacheKey = 'channelslist-' + params['include-deactivated']
  const [list] = await Promise.all([
    getCachedData<CaseChannel[]>(cacheKey, '/api/v1/channels/case', {
      params,
    }),
    loadEnumTranslations(PrefixTranslationsEnum.INTERACTION_CHANNEL),
  ])
  return list
}

interface EmergenciesParams {
  'include-deactivated': boolean
}

export const getEmergenciesList = async (params: EmergenciesParams): Promise<Emergency[]> => {
  const cacheKey = 'emergencieslist-' + params['include-deactivated']
  const [list] = await Promise.all([
    getCachedData<Emergency[]>(cacheKey, '/api/v1/emergencies', {
      params,
    }),
    loadEnumTranslations(PrefixTranslationsEnum.EMERGENCY),
  ])
  return list
}

interface PrioritiesParams {
  'include-deactivated': boolean
}

export const getPrioritiesList = async (params: PrioritiesParams): Promise<Priority[]> => {
  const cacheKey = 'prioritieslist-' + params['include-deactivated']
  const [list] = await Promise.all([
    getCachedData<Priority[]>(cacheKey, '/api/v1/priorities', {
      params,
    }),
    loadEnumTranslations(PrefixTranslationsEnum.PRIORITY),
  ])
  return list
}

export const getBulkCases = async (ids?: number[], include?: CaseIncludeEnum[], expand?: CaseExpandEnum[]): Promise<CaseData[]> => {
  const params: {
    include?: string
    expand?: string
  } = {
    include: include?.length ? include.join() : undefined,
    expand: expand?.length ? expand.join() : undefined,
  }
  return HttpService.postData<CaseData[]>(`/api/v1/bulk-cases/fetch`, { ids }, { params })
}

interface CaseActionTypesParams {
  onlySearchable?: boolean
}
export const getCaseActionTypes = async (params: CaseActionTypesParams): Promise<TicketActionTypeEnum[]> => {
  const cacheKey = 'caseActionTypes-' + params.onlySearchable
  return (
    (await getCachedData<TicketActionTypeEnum[]>(cacheKey, '/api/v1/cases/action-types', {
      params,
    })) || []
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isCase(entity: any): entity is Case {
  return (entity as Case).currentSkillCode !== undefined
}

export const completeSessionOnCurrentTicket = () => {
  return HttpService.get(`/ticket/completeSession`)
}
