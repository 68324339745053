import { onBeforeMount, onMounted, toRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import router from '@/router'
import useNavigation from '@/common/composables/useNavigation'
import { type getDictionaryPayload } from '@/common/types/event'

import { useVonageStore } from '@/admin/stores/vonageStore'
import { useSessionStore } from '@/common/stores/sessionStore'
import { useApplicationStore } from '@/common/stores/applicationStore'

import { subscribe, unsubscribe } from '@/common/services/eventBusService'
import { addTranslations } from '@/common/services/LocalizationService'

export function useGlobalEvents() {
  const applicationStore = useApplicationStore()
  const navigation = useNavigation()
  const i18n = useI18n()

  const getDictionary = async ({ prefixes, cb }: getDictionaryPayload) => {
    const messages = toRaw(i18n.getLocaleMessage(i18n.locale.value))

    for (let prefix = 0; prefix < prefixes.length; prefix++) {
      const isPrefixPresent = Object.keys(messages).some((key) => key.startsWith(prefixes[prefix]))

      if (!isPrefixPresent) {
        const sessionStore = useSessionStore()
        await addTranslations(sessionStore.locale, prefixes[prefix])
      }
    }

    cb(toRaw(i18n.getLocaleMessage(i18n.locale.value)))
  }

  const showTicketHandler = (caseId: string) => {
    navigation.openCase(caseId, { force: true })
  }

  const showCustomerHandler = (customerId: string) => {
    navigation.openCustomer(customerId, { force: true })
  }

  const disableNavigationHandler = () => {
    console.log('EMIT: innso:disable-navigation')
    applicationStore.disabled = true
  }

  const enableNavigationHandler = () => {
    console.log('EMIT: innso:enable-navigation')
    applicationStore.disabled = false
  }

  const redirectHandler = (url: string) => {
    router.push({ path: `/assist${url}`, force: true })
  }

  const loadWhatsappVonageNumbersHandler = () => {
    useVonageStore().passWhatsAppNumbers()
  }

  const toggleActivateWhatsappAccoundHandler = (id: number) => {
    useVonageStore().updateWhatsAppNumberActiveValue(id)
  }

  const bindGlobalEvents = () => {
    subscribe('innso:show-ticket', showTicketHandler)
    subscribe('innso:show-customer', showCustomerHandler)
    subscribe('innso:disable-navigation', disableNavigationHandler)
    subscribe('innso:enable-navigation', enableNavigationHandler)
    subscribe('innso:redirect', redirectHandler)
    subscribe('innso:load-whatsapp-vonage-numbers', loadWhatsappVonageNumbersHandler)
    subscribe('innso:toggle-activate-whatsapp-account', toggleActivateWhatsappAccoundHandler)
    subscribe('innso:get-dictionary', getDictionary)
  }

  const unbindGlobalEvents = () => {
    unsubscribe('innso:show-ticket', showTicketHandler)
    unsubscribe('innso:show-customer', showCustomerHandler)
    unsubscribe('innso:disable-navigation', disableNavigationHandler)
    unsubscribe('innso:enable-navigation', enableNavigationHandler)
    unsubscribe('innso:redirect', redirectHandler)
    unsubscribe('innso:load-whatsapp-vonage-numbers', loadWhatsappVonageNumbersHandler)
    unsubscribe('innso:toggle-activate-whatsapp-account', toggleActivateWhatsappAccoundHandler)
    unsubscribe('innso:get-dictionary', getDictionary)
  }

  onMounted(() => {
    bindGlobalEvents()
  })

  onBeforeMount(() => {
    unbindGlobalEvents()
  })
}
