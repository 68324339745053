import { FieldTypeEnum, EntityFieldCodeEnum, type Component } from '@/common/types/fields'
import { GroupedCriteriaParentEnum } from '@/search/types/search'

const FieldInput = () => import('@/search/components/fields/search/FieldInput.vue')
const CustomFieldSelection = () => import('@/search/components/fields/search/CustomFieldSelection.vue')
const FieldCheckbox = () => import('@/search/components/fields/search/FieldCheckbox.vue')
const FieldCheckboxSelect = () => import('@/search/components/fields/search/FieldCheckboxSelect.vue')
const FieldDateTime = () => import('@/search/components/fields/search/FieldDateTime.vue')
const CustomFieldTree = () => import('@/search/components/fields/search/CustomFieldTree.vue')

// specific
const FieldIdentifier = () => import('@/search/components/fields/search/specific/FieldIdentifier.vue')
const FieldChannel = () => import('@/search/components/fields/search/specific/FieldChannel.vue')
const FieldScreen = () => import('@/search/components/fields/search/specific/FieldScreen.vue')
const FieldTypology = () => import('@/search/components/fields/search/specific/FieldTypology.vue')
const FieldPriority = () => import('@/search/components/fields/search/specific/FieldPriority.vue')
const FieldEmergency = () => import('@/search/components/fields/search/specific/FieldEmergency.vue')
const FieldCaseStatus = () => import('@/search/components/fields/search/specific/FieldCaseStatus.vue')
const FieldSkill = () => import('@/search/components/fields/search/specific/FieldSkill.vue')
const FieldUser = () => import('@/search/components/fields/search/specific/FieldUser.vue')
const FieldUserGroup = () => import('@/search/components/fields/search/specific/FieldUserGroup.vue')
const FieldMailbox = () => import('@/search/components/fields/search/specific/FieldMailbox.vue')
const FieldImportActionType = () => import('@/search/components/fields/search/specific/FieldImportActionType.vue')
const FieldCaseActionType = () => import('@/search/components/fields/search/specific/FieldCaseActionType.vue')
const FieldInteractionType = () => import('@/search/components/fields/search/specific/FieldInteractionType.vue')
const FieldReason = () => import('@/search/components/fields/search/specific/FieldReason.vue')
const FieldInteractionStatus = () => import('@/search/components/fields/search/specific/FieldInteractionStatus.vue')
const FieldValidationStatus = () => import('@/search/components/fields/search/specific/FieldValidationStatus.vue')
const FieldTicketSLA = () => import('@/search/components/fields/search/specific/FieldTicketSLA.vue')
const FieldGroupedCriteria = () => import('@/search/components/fields/search/specific/FieldGroupedCriteria.vue')

const FieldsComponentsSearchMap = new Map<FieldTypeEnum, Component>([
  [FieldTypeEnum.TEXT, { source: FieldInput } as unknown as Component],
  [FieldTypeEnum.NUMBER, { source: FieldInput } as unknown as Component],
  [FieldTypeEnum.EMAIL, { source: FieldInput } as unknown as Component],
  [FieldTypeEnum.TEXTAREA, { source: FieldInput } as unknown as Component],
  [FieldTypeEnum.LOCAL_DATE, { source: FieldDateTime } as unknown as Component],
  [FieldTypeEnum.TIMESTAMP, { source: FieldDateTime } as unknown as Component],
  [FieldTypeEnum.SELECT_LIST_SINGLE, { source: CustomFieldSelection } as unknown as Component],
  [FieldTypeEnum.SELECT_LIST_MULTIPLE, { source: CustomFieldSelection } as unknown as Component],
  [FieldTypeEnum.CHECKBOX_SINGLE, { source: FieldCheckbox } as unknown as Component],
  [FieldTypeEnum.CHECKBOX_MULTIPLE, { source: CustomFieldSelection } as unknown as Component],
  [FieldTypeEnum.RADIO, { source: CustomFieldSelection } as unknown as Component],
  [FieldTypeEnum.TREE_SINGLE, { source: CustomFieldTree } as unknown as Component],
  [FieldTypeEnum.TREE_MULTIPLE, { source: CustomFieldTree } as unknown as Component],
  [FieldTypeEnum.TREE_SINGLE_ASCENDING, { source: CustomFieldTree } as unknown as Component],
])

const SpecificComponentsSearchMap = new Map<string, Component>([
  [EntityFieldCodeEnum.CREATION_CHANNEL, { source: FieldChannel } as unknown as Component],
  [EntityFieldCodeEnum.CURRENT_CHANNEL, { source: FieldChannel } as unknown as Component],
  [EntityFieldCodeEnum.CREATION_SCREEN, { source: FieldScreen } as unknown as Component],
  [EntityFieldCodeEnum.TYPOLOGY, { source: FieldTypology } as unknown as Component],
  [EntityFieldCodeEnum.PRIORITY, { source: FieldPriority } as unknown as Component],
  [EntityFieldCodeEnum.EMERGENCY, { source: FieldEmergency } as unknown as Component],
  [EntityFieldCodeEnum.TICKET_STATUS, { source: FieldCaseStatus } as unknown as Component],
  [EntityFieldCodeEnum.INITIAL_SKILL, { source: FieldSkill } as unknown as Component],
  [EntityFieldCodeEnum.CURRENT_SKILL, { source: FieldSkill } as unknown as Component],
  [EntityFieldCodeEnum.CREATION_USER, { source: FieldUser } as unknown as Component],
  [EntityFieldCodeEnum.ASSIGNEE, { source: FieldUser } as unknown as Component],
  [EntityFieldCodeEnum.TICKET_USERGROUP, { source: FieldUserGroup } as unknown as Component],
  [EntityFieldCodeEnum.IMPORT_USER, { source: FieldUser } as unknown as Component],
  [EntityFieldCodeEnum.TICKET_MAILBOX, { source: FieldMailbox } as unknown as Component],
  [EntityFieldCodeEnum.IMPORT_ACTION_TYPE, { source: FieldImportActionType } as unknown as Component],
  [EntityFieldCodeEnum.OTHER_ACTION_TYPE, { source: FieldCaseActionType } as unknown as Component],
  [EntityFieldCodeEnum.OTHER_ACTION_USER, { source: FieldUser } as unknown as Component],
  [EntityFieldCodeEnum.ACTION_REASON, { source: FieldReason } as unknown as Component],
  [EntityFieldCodeEnum.VALIDATION_STATUS, { source: FieldValidationStatus } as unknown as Component],
  [GroupedCriteriaParentEnum.TICKET_IMPORT, { source: FieldGroupedCriteria } as unknown as Component],
  [GroupedCriteriaParentEnum.OTHER_TICKET_ACTION, { source: FieldGroupedCriteria } as unknown as Component],
  [GroupedCriteriaParentEnum.TICKET_ACTION_REASON, { source: FieldGroupedCriteria } as unknown as Component],
  [GroupedCriteriaParentEnum.CUSTOMER_DELETED, { source: FieldGroupedCriteria } as unknown as Component],
  [EntityFieldCodeEnum.TICKET_ID, { source: FieldIdentifier } as unknown as Component],
  [EntityFieldCodeEnum.PARENT_TICKET_ID, { source: FieldIdentifier } as unknown as Component],
  [EntityFieldCodeEnum.EXTERNAL_REF, { source: FieldIdentifier } as unknown as Component],
  [EntityFieldCodeEnum.IMPORT_ID, { source: FieldIdentifier } as unknown as Component],
  [EntityFieldCodeEnum.CUSTOMER_ID, { source: FieldIdentifier } as unknown as Component],
  [EntityFieldCodeEnum.CUSTOMER_REF, { source: FieldIdentifier } as unknown as Component],
  [EntityFieldCodeEnum.INTERACTION_ID, { source: FieldIdentifier } as unknown as Component],
  [EntityFieldCodeEnum.HAS_ATTACHMENTS, { source: FieldCheckboxSelect } as unknown as Component],
  [EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS, { source: FieldCheckboxSelect } as unknown as Component],
  [EntityFieldCodeEnum.INTERACTION_SLA, { source: FieldCheckboxSelect } as unknown as Component],
  [EntityFieldCodeEnum.TICKET_SLA, { source: FieldTicketSLA } as unknown as Component],
  [EntityFieldCodeEnum.INTERACTION_TYPE, { source: FieldInteractionType } as unknown as Component],
  [EntityFieldCodeEnum.INTERACTION_STATUS, { source: FieldInteractionStatus } as unknown as Component],
  [EntityFieldCodeEnum.INTERACTION_USER, { source: FieldUser } as unknown as Component],
])

export function getComponent(code: string, type: FieldTypeEnum | null): Component | null {
  let component: Component | undefined = SpecificComponentsSearchMap.get(code)

  if (component) return component

  if (type === null) return null

  component = FieldsComponentsSearchMap.get(type)

  if (!component) {
    return null
  }

  return component
}
