import { defineStore } from 'pinia'
import { ref, type Ref, toRaw } from 'vue'
import { type VonageConfigurationDTOV2 } from '@/admin/types/vonage'
import { getAllVonageConfigurationsV2, updateVonageWhatsAppNumberV2 } from '@/admin/services/VonageService'
import { publish } from '@/common/services/eventBusService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import i18n from '@/plugins/i18n'
import { useSkillStore } from '@/common/stores/skillStore'
import { type SkillDTOV2 } from '@/common/types/skills'

const { t } = i18n.global

export const useVonageStore = defineStore('vonage', () => {
  const vonageConfigurations: Ref<Array<VonageConfigurationDTOV2>> = ref([])
  initVonage()

  async function initVonage() {
    vonageConfigurations.value = await getAllVonageConfigurationsV2()
  }

  async function findVonageSmsNumber(id: number) {
    await initVonage()
    for (const vonageConf of vonageConfigurations.value.values()) {
      for (const phoneNumber of vonageConf.smsNumbers ?? []) {
        if (id == phoneNumber.id) {
          phoneNumber.vonageConfiguration = vonageConf
          delete phoneNumber.vonageConfiguration.smsNumbers
          return phoneNumber
        }
      }
    }

    return null
  }

  async function findVonageWhatsAppNumber(id: number) {
    await initVonage()
    for (const vonageConf of vonageConfigurations.value.values()) {
      for (const phoneNumber of vonageConf.whatsappNumbers ?? []) {
        if (id == phoneNumber.id) {
          phoneNumber.vonageConfiguration = vonageConf
          delete phoneNumber.vonageConfiguration.whatsappNumbers
          return phoneNumber
        }
      }
    }

    return null
  }

  async function passWhatsAppNumbers() {
    const skillStore = useSkillStore()
    await skillStore.fetch(true)
    const skills: SkillDTOV2[] = skillStore.skills
    await initVonage()
    const whatsappNumbers = vonageConfigurations.value.map((configuration) => toRaw(configuration.whatsappNumbers) || []).flat()
    const whatsappNumbersWithSkillName = whatsappNumbers.map((number) => {
      const skillCode = number.defaultSkill ? skills.find((skill) => skill.code === number.defaultSkill)?.code : null
      return {
        ...number,
        defaultSkillName: skillCode ? t(PrefixTranslationsEnum.SKILL + skillCode) : '',
      }
    })
    publish('assist:load-vonage-whatsapp-numbers', whatsappNumbersWithSkillName)
  }

  async function updateWhatsAppNumberActiveValue(id: number) {
    const whatsAppNumber = await findVonageWhatsAppNumber(id)
    if (!whatsAppNumber) {
      throw new Error('WhatsApp number not found in vonageConfigurations')
    }
    const updatedWhatsAppNumber = { ...whatsAppNumber, active: !whatsAppNumber?.active }
    await updateVonageWhatsAppNumberV2(updatedWhatsAppNumber, true)
  }

  return {
    initVonage,
    findVonageSmsNumber,
    findVonageWhatsAppNumber,
    passWhatsAppNumbers,
    updateWhatsAppNumberActiveValue,
    vonageConfigurations,
  }
})
