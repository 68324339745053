import { type ReplaceFunction } from 'lodash'
import _replace from 'lodash/replace'
import type { LegacyEngageInteraction, LegacyEngageDocument } from '@/engage/types/interactions'

export const capitalize = (value: string) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const uppercase = (value: string | number) => {
  return !value || value === 0 ? value.toString().toUpperCase() : ''
}

export const truncate = (value: string, length: number = 15) => {
  if (!value || typeof value !== 'string') return ''
  return value.length <= length ? value : value.substring(0, length) + '...'
}

export const replace = (value: string, pattern: RegExp | string, replacement: ReplaceFunction | string) => {
  if (!value) return ''
  return _replace(value, pattern, replacement)
}

export const removeHtml = (value: string) => {
  if (!value) return ''
  return _replace(value, /(<([^>]+)>)/gi, '')
}

/**
 * removeDocumentUrl
 * @param {String} value
 * @param {Object} interaction
 * @return {String}
 */
export const removeDocumentUrl = (value: string, interaction: LegacyEngageInteraction) => {
  if (interaction.documents?.length) {
    interaction.documents.forEach((document: LegacyEngageDocument) => {
      value = value.replace(`[${document.fileName}]`, '')
    })
  }

  return value
}

export const getStringAfterPrefix = (value: string, prefix: string) => {
  if (value.indexOf(prefix) !== -1) return value.substring(prefix.length)
  return value
}
