import { type NavigationGuardReturn, type RouteLocationNormalizedLoaded, type RouteLocationNormalized, START_LOCATION } from 'vue-router'
import configResolver, { pathCombine } from '@/common/helpers/configResolver'
import { triggerOidcChangePassword } from '@/common/services/AuthenticationService'
import { type NavigationGuardWithoutNext } from '@/app/types/router'
import { PrefixTranslationsEnum } from '@/common/types/i18n'

import { useContextualSearchStore } from '@/search/stores/contextualSearchStore'
import { useSessionStore } from '@/common/stores/sessionStore'
import { useBreaksStore } from '@/agent/stores/breakStore'
import { useSearchStore } from '@/search/stores/searchStore'
import { usePreBreakStore } from '@/agent/stores/preBreakStore'

import { addTranslations } from '@/common/services/LocalizationService'
import { clear as clearLocalData } from '@/common/services/LocalStorageService'
import { checkNavigationConsistent, handleNavigation, NAVIGATION_HISTORY_KEY, resetNavigationHistory } from '@/common/services/NavigationService'

async function handleChangePasswordRoute(originPath: string, oidcEnabled: boolean) {
  if (!oidcEnabled) return

  await triggerOidcChangePassword(pathCombine([configResolver.root, originPath]))
}

export const contextualSearchBeforeEnter = async (): Promise<NavigationGuardReturn> => {
  useContextualSearchStore().resetContextualSearch()
}

export const changePasswordRouteBeforeEnter = async (
  _: RouteLocationNormalized,
  from: RouteLocationNormalizedLoaded,
): Promise<NavigationGuardReturn> => {
  const sessionStore = useSessionStore()
  await handleChangePasswordRoute(from.path, sessionStore.oidcEnabled)
}

export const agentBeforeEnter: NavigationGuardWithoutNext = async (_, from) => {
  if (from.meta.engage || from.meta.agent) return
  if (from !== START_LOCATION && !from.meta.admin) return

  clearLocalData(['session', 'parameters', NAVIGATION_HISTORY_KEY])

  const sessionStore = useSessionStore()
  if (from?.meta?.admin) {
    sessionStore.loadSessionSettings()

    const searchStore = useSearchStore()
    searchStore.$reset()
  }
  const breakStore = useBreaksStore()
  const preBreakStore = usePreBreakStore()

  const hydrationRequests = [
    breakStore.load(),
    preBreakStore.load(),
    addTranslations(sessionStore.locale, [
      PrefixTranslationsEnum.USERSTATE,
      PrefixTranslationsEnum.SCREEN,
      PrefixTranslationsEnum.SKILL,
      PrefixTranslationsEnum.SPACE,
      PrefixTranslationsEnum.REASON,
    ]),
  ]
  await Promise.all(hydrationRequests)
}

export const navigationBeforeResolve: NavigationGuardWithoutNext = async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
  if (from.meta?.admin || to.meta?.admin || from === START_LOCATION || from.fullPath === to.fullPath) return

  if (to.meta?.allowBackNavigation) {
    if (!from.meta?.skipHistory) {
      handleNavigation(from.fullPath)
    }
    checkNavigationConsistent(to.fullPath)
  } else {
    resetNavigationHistory()
  }
}
