import { computed, type ComputedRef, ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import {
  AssignOnTransferEnum,
  type AuthoritiesEnum,
  DistributionModeEnum,
  type EngageChannel,
  TransferScopeEnum,
  type UserSessionDTOV1,
} from '@/common/types/session'
import { BulkActionEnum } from '@/common/types/bulkAction'
import useLocaleUtils from '@/common/composables/useLocaleUtils'
import { type InstanceParameterDTOV1 } from '@/common/types/instanceParameter'
import { getParameterList } from '@/common/services/InstanceParameterService'
import { getUserMe } from '@/common/services/UsersService'
import { useActivitiesStore } from '@/agent/stores/activitiesStore'
import { setDayjsLocale } from '@/plugins/dayjs'
import { initFormValidation } from '@/plugins/veevalidate'
import { NULLUUID } from '@/common/types/general'

const localeUtils = useLocaleUtils()

export const useSessionStore = defineStore('session', () => {
  const id: Ref<number> = ref(0)
  const fullName: Ref<string> = ref('')
  const engage: Ref<boolean> = ref(false)
  const engageChannels: Ref<Array<EngageChannel>> = ref([])
  const engageChannelsAuthority: Ref<boolean> = ref(false)
  const authorities: Ref<AuthoritiesEnum[]> = ref([])
  const spaces: Ref<string[]> = ref([])
  const skills: Ref<string[]> = ref([])
  const distributionMode: Ref<DistributionModeEnum> = ref(DistributionModeEnum.PULL_EXPLICIT_ASSIGN)
  const pushTempo: Ref<number> = ref(0)
  const displayAvailableTicketCount: Ref<boolean> = ref(false)
  const timeZone: Ref<string> = ref(Intl.DateTimeFormat().resolvedOptions().timeZone) // navigator timezone by default
  const locale: Ref<string> = ref(localeUtils.getBrowserLocal())
  const defaultCountryPhoneCode: Ref<number> = ref(0)
  const state: Ref<string | null> = ref(null)
  const screenIdTicketList: Ref<number> = ref(0)
  const screenIdClientList: Ref<number> = ref(0)
  const screenIdInteractionList: Ref<number> = ref(0)
  const screenIdClientEdit: Ref<number> = ref(0)
  const searchConfigs: Ref<UUID[]> = ref([])
  const instanceParameters: Ref<InstanceParameterDTOV1[]> = ref([])
  const transferScope: Ref<TransferScopeEnum> = ref(TransferScopeEnum.SKILLED_ONLY)
  const trainedSkills: Ref<string[]> = ref([])
  const assignOnTransfer: Ref<AssignOnTransferEnum> = ref(AssignOnTransferEnum.NOT_ALLOWED)
  const login: Ref<string> = ref('')
  const oidcEnabled: Ref<boolean> = ref(false)
  const teamCode: Ref<UUID> = ref(NULLUUID)
  const teams: Ref<UUID[]> = ref([])

  const activities = useActivitiesStore()

  async function loadSessionSettings() {
    try {
      const [session, parameters] = await Promise.all([getUserMe(), getParameterList()])

      if (session) {
        populateSessionValues(session)
        handleOptionalSessionProperties(session)
        await updateLocaleSettings(session)
      }

      if (parameters.length) {
        instanceParameters.value = [...parameters]
      }

      await activities.fetchAgentActivities()
    } catch (error) {
      console.log(error)
    }
  }

  function populateSessionValues(session: UserSessionDTOV1) {
    id.value = session.id
    fullName.value = session.fullName
    authorities.value = [...session.authorities]
    spaces.value = [...session.spaces]
    skills.value = [...session.skills]
    distributionMode.value = session.distributionMode
    engage.value = session.engage
    engageChannelsAuthority.value = session.engageChannelsAuthority
    state.value = session.state
    timeZone.value = session.timeZone
    login.value = session.login
    teamCode.value = session.teamCode
    teams.value = session.teams
    screenIdTicketList.value = session.screenIdTicketList
    screenIdClientList.value = session.screenIdClientList
    screenIdInteractionList.value = session.screenIdInteractionList
    screenIdClientEdit.value = session.screenIdClientEdit
    searchConfigs.value = [...session.searchConfigs]
  }

  function handleOptionalSessionProperties(session: UserSessionDTOV1) {
    if (session.transferScope) transferScope.value = session.transferScope
    if (session.assignOnTransfer) assignOnTransfer.value = session.assignOnTransfer
    if (session.displayAvailableTicketCount) displayAvailableTicketCount.value = true
    if (session.trainedSkills) trainedSkills.value = [...session.trainedSkills]
    if (session.locale) locale.value = session.locale
    if (session.defaultCountryPhoneCode) defaultCountryPhoneCode.value = session.defaultCountryPhoneCode

    if (distributionMode.value === DistributionModeEnum.PUSH && session.pushTempo) {
      pushTempo.value = session.pushTempo
    }
  }

  async function updateLocaleSettings(session: UserSessionDTOV1) {
    localeUtils.changeQuasarLanguage(session.locale)
    initFormValidation(localeLanguage.value)
    await setDayjsLocale(localeLanguage.value)
  }

  const isPushAuto = computed(() => {
    return distributionMode.value === DistributionModeEnum.PUSH
  })

  const localeLanguage: ComputedRef<string> = computed(() => {
    return locale.value.slice(0, 2)
  })

  const getAuthoritySet = computed(() => {
    return new Set<string>(authorities.value)
  })

  const getParameterMap = computed(() => {
    return new Map<string, InstanceParameterDTOV1>(instanceParameters.value.map((p) => [p.code, p]))
  })

  const getAvailableCasesBulkActions: ComputedRef<string[]> = computed(() => {
    return Object.keys(BulkActionEnum).filter((action) => getAuthoritySet.value.has(action))
  })

  return {
    id,
    fullName,
    engage,
    engageChannels,
    engageChannelsAuthority,
    authorities,
    spaces,
    skills,
    defaultCountryPhoneCode,
    trainedSkills,
    distributionMode,
    pushTempo,
    displayAvailableTicketCount,
    timeZone,
    locale,
    state,
    screenIdTicketList,
    screenIdClientList,
    screenIdInteractionList,
    screenIdClientEdit,
    searchConfigs,
    instanceParameters,
    loadSessionSettings,
    localeLanguage,
    transferScope,
    assignOnTransfer,
    login,
    getAuthoritySet,
    getAvailableCasesBulkActions,
    getParameterMap,
    oidcEnabled,
    isPushAuto,
    teamCode,
    teams,
  }
})
