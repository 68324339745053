import { type PolaritySentiment } from '@/engage/types/polaritySentiment'

export interface LegacyEngageDocument {
  disposition: string
  documentId: number
  fileName: string
  mediaType: string
  token: UUID
}

export enum LegacyEngageInteractionTypeStatus {
  DRAFT = 'INTERACTION_STATUS.DRAFT',
  SENDERROR = 'INTERACTION_STATUS.SENDERROR',
  SENT = 'INTERACTION_STATUS.SENT',
  COMPLETED = 'INTERACTION_STATUS.COMPLETED',
}

export type LegacyEngageInteractionTypeStatusValues = `${LegacyEngageInteractionTypeStatus}`

export enum LegacyEngageInteractionTypeCode {
  CHAT_IN_NEW_USER_MESSAGE = 'CHAT_IN_NEW_USER_MESSAGE',
  CHAT_OUT_BOT_AUTO_RESPONSE = 'CHAT_OUT_BOT_AUTO_RESPONSE',
  CHAT_OUT_BOT_RESPONSE = 'CHAT_OUT_BOT_RESPONSE',
  CHAT_OUT_OPERATOR_RESPONSE = 'CHAT_OUT_OPERATOR_RESPONSE',

  INSTAGRAM_DM_STORY_MENTION = 'INSTAGRAM_DM_STORY_MENTION',
  INSTAGRAM_DM_STORY_REPLY = 'INSTAGRAM_DM_STORY_REPLY',

  CALL_OUT = 'CALL_OUT',
  MAIL_OUT_NOTIFICATION = 'MAIL_OUT_NOTIFICATION',
  MAIL_OUT_USER_INITIATED = 'MAIL_OUT_USER_INITIATED',
  SMS_OUT_NOTIFICATION = 'SMS_OUT_NOTIFICATION',
}

export type LegacyEngageInteractionTypeCodeValues = `${LegacyEngageInteractionTypeCode}`

export interface LegacyEngageInteractionType {
  channel: string
  code: LegacyEngageInteractionTypeCodeValues
  inOut: string
  status: LegacyEngageInteractionTypeStatusValues
}

export interface LegacyEngageInteractionCustomer {
  external: boolean
  id: number
  mobilePhone: string
  whatsAppPhoneNumber: string
}
export type InteractionFields = Record<`CUSTOMFIELD.${string}`, string>

export interface LegacyEngageInteractionUser {
  firstName: string
  id: number
  identity: string
  lastName: string
}

export enum LegacyEngageInteractionAttributesType {
  SURVEY = 'survey',
  MEDIA = 'media',
}
export interface LegacyEngageInteractionAttributes {
  type: `${LegacyEngageInteractionAttributesType}`
}

export interface LegacyEngageInteractionDraftMailAttributes {
  contactsBcc: string[]
  contactsCc: string[]
  contactsTo: string[]
  mailboxId: number
  mailObject: string
  rawContent: string
}

interface LegacyEngageBase {
  avatar: string
  canBeHidden: boolean
  content: string
  creationDate: string
  displayClientTab: boolean
  documents: LegacyEngageDocument[]
  fields: InteractionFields
  incoming: boolean
  qualified: boolean
  sentFrom: string
  sentTo: string
  ticketEditorEnabled: boolean
  ticketId: number
  type: LegacyEngageInteractionType
}

export interface LegacyEngageInteraction extends LegacyEngageBase {
  attributes: LegacyEngageInteractionAttributes
  customer: LegacyEngageInteractionCustomer
  id: number
  polaritySentiment?: PolaritySentiment
}

export interface LegacyEngageInteractionDraftMail extends LegacyEngageBase {
  aht: number
  attributes: LegacyEngageInteractionDraftMailAttributes
  user: LegacyEngageInteractionUser
  usernameTile: string
}
