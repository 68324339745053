export default Object.freeze({
  SENT: {
    icon: ['fa', 'check-circle'],
    color: 'text-neutral100',
  },
  INPROGRESS: {
    icon: ['fa', 'cog'],
    color: 'text-neutral100',
  },
  ACHIEVED: {
    icon: ['fa', 'check-circle'],
    color: 'text-primary30',
  },
})
