export type i18nRefDTOV1 = {
  locale: string
  key: string
  standard: string
  value: string
}

export type i18nTranslation = {
  [key: i18nRefDTOV1['key']]: i18nRefDTOV1['value']
}

export const enum PrefixTranslationsEnum {
  CUSTOMFIELD = 'CUSTOMFIELD.',
  CFOPTION = 'CFOPTION.',
  ENTITY_FIELD = 'ENTITY_FIELD.',
  IMPORT_ACTION_TYPE = 'assist.importActionTypeOption.',
  DUEDATE_SEARCH_MODE = 'assist.dueDateSearchMode.',
  ACTIVITY = 'ACTIVITY.',
  REASON = 'TICKETACTIONREASON.',
  SCHEDULE = 'SCHEDULE.',
  SCREEN = 'SCREEN.',
  SKILL = 'SKILL.',
  SPACE = 'SKILLGROUP.',
  TICKETACTIONTYPE = 'TICKETACTIONTYPE.',
  TYPOLOGY = 'TICKETTYPOLOGY.',
  USERGROUP = 'USERGROUP.',
  PRODUCT = 'PRODUCT.',
  INTERACTION = 'INTERACTION.',
  INTERACTION_CHANNEL = 'INTERACTION_CHANNEL.',
  INTERACTION_TYPE = 'INTERACTION_TYPE.',
  EXTERNAL = 'EXTERNAL.',
  INTERACTION_STATUS = 'INTERACTION_STATUS.',
  TICKETSTATUS = 'TICKETSTATUS.',
  USERSTATE = 'USERSTATE.',
  EMERGENCY = 'EMERGENCY.',
  PRIORITY = 'PRIORITY.',
  SEARCH_CONFIG = 'SEARCH_CONFIG.',
}

export const enum SuffixTranslationsEnum {
  SHORT = '.SHORT',
  LABEL = '.LABEL',
  DESCR = '.DESCR',
}

export type i18nMessageDTOV1 = {
  locale: string
  message: string
}

export type i18nMessagesDTOV2 = {
  locale: string
  key: string
  message: string
}
