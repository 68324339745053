import { createRouter, createWebHistory, START_LOCATION } from 'vue-router'
import configResolver from '@/common/helpers/configResolver'
import { setTranslations } from '@/common/services/LocalizationService'
import { useSessionStore } from '@/common/stores/sessionStore'
import { routes } from '@/router/routes'
import { engageBeforeEach, resetEngageStoreBeforeEach } from '@/engage/router'
import { authenticationBeforeEach } from '@/router/authentication'
import { useApplicationStore } from '@/common/stores/applicationStore'
import { navigationBeforeResolve } from '@/router/guards'

const router = createRouter({
  history: createWebHistory(configResolver.pathname),
  routes,
})

/**
 * WARNING: It could be helpful to factorize the guard implementation
 * into smaller peaces of code, but it's important to keep in mind that
 * the guard logic should be maintained. Be sure to fully understand
 * router guard mechanism and returned options.
 *
 * Read the doc: *
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#The-Full-Navigation-Resolution-Flow
 *
 * Internally, inside the router guard, if a helper method returns
 * "undefined", the guard execution continues otherwise the value
 * is returned.
 */
router.beforeEach(async (to, from) => {
  const authenticationValue = await authenticationBeforeEach(to, from)
  if (typeof authenticationValue !== 'undefined') return authenticationValue

  const sessionStore = useSessionStore()

  if (from === START_LOCATION && !sessionStore.id) {
    await sessionStore.loadSessionSettings()
    await setTranslations(sessionStore.locale, ['assist'])
  }

  const resetEngageStore = await resetEngageStoreBeforeEach(to, from)
  if (typeof resetEngageStore !== 'undefined') return resetEngageStore

  const engageValue = await engageBeforeEach(to, from)
  if (typeof engageValue !== 'undefined') return engageValue
})

router.beforeResolve(navigationBeforeResolve)

router.afterEach((to) => {
  if (!to.meta.legacy) {
    useApplicationStore().ready = true
  }
})

export default router
