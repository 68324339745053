import { type NavigationHistoryItem } from '@/app/types/router'

export const NAVIGATION_HISTORY_KEY = 'navigation-history'

export const handleNavigation = (from: string) => {
  const history = getNavigationHistory()
  if (history.length) {
    const previousPath = history[history.length - 1]
    // When we come into a previous path
    if (previousPath.backNavigation) {
      history.pop()
    }
    // When we move forward into a new path
    else if (previousPath.path !== from) {
      pushNewRoute(history, from)
    }
  }
  // When the navigation history is empty
  else {
    pushNewRoute(history, from)
  }
  storeNavigationHistory(history)
}

const pushNewRoute = (history: NavigationHistoryItem[], path: string) => {
  history.push({ path })
}

/**
 * After a conversion to whatsapp or sms, we come back to the same ticket,
 * so we should remove the last path who is the same than where we go to
 */
export const checkNavigationConsistent = (to: string) => {
  const history = getNavigationHistory()
  if (history.length) {
    const previousPath = history[history.length - 1]
    if (previousPath.path === to) {
      history.pop()
      storeNavigationHistory(history)
    }
  }
}

export const getNavigationHistory = (): NavigationHistoryItem[] => {
  const paths = localStorage.getItem(NAVIGATION_HISTORY_KEY)
  if (paths) {
    return JSON.parse(paths) as NavigationHistoryItem[]
  }
  return []
}

export const storeNavigationHistory = (paths: NavigationHistoryItem[]): void => {
  localStorage.setItem(NAVIGATION_HISTORY_KEY, JSON.stringify(paths))
}

export const resetNavigationHistory = (): void => {
  localStorage.removeItem(NAVIGATION_HISTORY_KEY)
}
