import { NewInteraction } from '@/engage/types/newInteractions'
import { LegacyEngageInteractionAttributesType } from '@/engage/types/interactions'

export default Object.freeze({
  CALL_IN: 8,
  CALL_OUT: 32,

  CALL_AND_IN: 'CALL_IN',
  CALL_AND_OUT: 'CALL_OUT',

  IN: 'IN',
  OUT: 'OUT',

  CALL: NewInteraction.CALL,
  EMAIL: NewInteraction.MAIL,
  SMS: NewInteraction.SMS,

  SURVEY: LegacyEngageInteractionAttributesType.SURVEY,
  MEDIA: LegacyEngageInteractionAttributesType.MEDIA,
})
